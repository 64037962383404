const COST_GET_INDIRECT_DATA = 'COST_GET_INDIRECT_DATA';
const COST_GET_INDIRECT_DATA_SUCCESSED = 'COST_GET_INDIRECT_DATA_SUCCESSED';
const COST_GET_PACKAGES_FAILED = 'COST_GET_PACKAGES_FAILED';
const COST_GET_COST_ESTIMATE_ROOTS_REQUEST = 'COST_GET_COST_ESTIMATE_ROOTS_REQUEST';
const COST_GET_COST_ESTIMATE_ROOTS_SUCCEEDED = 'COST_GET_COST_ESTIMATE_ROOTS_SUCCEEDED';
const COST_GET_COST_ESTIMATE_ROOTS_FAILED = 'COST_GET_COST_ESTIMATE_ROOTS_FAILED';
const COST_SWITCH_CHILDS_REQUEST = 'COST_SWITCH_CHILDS_REQUEST';
const COST_LOAD_CALCULATIONS_SUCCESSED = 'COST_LOAD_CALCULATIONS_SUCCESSED';
const COST_LOAD_CALCULATIONS_FAILED = 'COST_LOAD_CALCULATIONS_FAILED';
const COST_CHANGE_CALCULATION = 'COST_CHANGE_CALCULATION';
const COST_GET_COST_ESTIMATE_SUCCEEDED = 'COST_GET_COST_ESTIMATE_SUCCEEDED';
const COST_DROP_DATA = 'COST_DROP_DATA';
const COST_CHANGE_FIELD = 'COST_CHANGE_FIELD';
const COST_GET_SUBCONTRACTOR_PACKAGE_INFO_SUCCESSED =
  'COST_GET_SUBCONTRACTOR_PACKAGE_INFO_SUCCESSED';
const COST_COLLAPSE_EXPAND = 'COST_COLLAPSE_EXPAND';
const COST_COLLAPSE = 'COST_COLLAPSE';
const COST_EXPAND = 'COST_EXPAND';
const COST_ADD_CHILD = 'COST_ADD_CHILD';
const COST_CHANGE_FIELD_COMMIT = 'COST_CHANGE_FIELD_COMMIT';
const COST_GET_EXCEL_REPORT_TYPES_REQUEST = 'COST_GET_EXCEL_REPORT_TYPES_REQUEST';
const COST_GET_EXCEL_REPORT_TYPES_SUCCESSED = 'COST_GET_EXCEL_REPORT_TYPES_SUCCESSED';
const COST_EXPORT_EXCEL_REPORT = 'COST_EXPORT_EXCEL_REPORT';

export const CostsActionTypes = {
  COST_GET_EXCEL_REPORT_TYPES_REQUEST,
  COST_GET_EXCEL_REPORT_TYPES_SUCCESSED,
  COST_EXPORT_EXCEL_REPORT,
  COST_GET_INDIRECT_DATA,
  COST_GET_INDIRECT_DATA_SUCCESSED,
  COST_CHANGE_FIELD,
  COST_GET_COST_ESTIMATE_SUCCEEDED,
  COST_DROP_DATA,
  COST_GET_PACKAGES_FAILED,
  COST_GET_COST_ESTIMATE_ROOTS_REQUEST,
  COST_GET_COST_ESTIMATE_ROOTS_SUCCEEDED,
  COST_GET_COST_ESTIMATE_ROOTS_FAILED,
  COST_SWITCH_CHILDS_REQUEST,
  COST_LOAD_CALCULATIONS_SUCCESSED,
  COST_LOAD_CALCULATIONS_FAILED,
  COST_CHANGE_CALCULATION,
  COST_GET_SUBCONTRACTOR_PACKAGE_INFO_SUCCESSED,
  COST_COLLAPSE_EXPAND,
  COST_COLLAPSE,
  COST_EXPAND,
  COST_ADD_CHILD,
  COST_CHANGE_FIELD_COMMIT,
};
