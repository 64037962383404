import { Switcher, Text, Waves } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { ColorsName } from 'common/enums/kreo-colors';
import { Styled } from '../styled';

interface Props {
  name: string;
  onToggle: () => void;
  isChecked: boolean;
}

export const TwoDSwitcher: React.FC<Props> = ({ onToggle, name, isChecked }) => (
  <Styled.SwitchWithLabel onClick={onToggle}>
    <Text fontSize={14}>{name}</Text>
    <Switcher
      checked={isChecked}
      size='m'
    />
    <Waves color={ColorsName.turquoise} />
  </Styled.SwitchWithLabel>
);
