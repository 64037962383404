import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './kreo-confirmation-dialog.scss';

import { State } from 'common/interfaces/state';
import { Checkbox, KreoDialog, KreoDialogActions } from 'common/UIKit';
import { KreoDialogActionsPanel } from 'common/UIKit/dialogs/kreo-dialog-actions-panel';
import { StringUtils } from 'common/utils/string-utils';

interface OwnProps<T> {
  name: string;
  yesText?: string;
  noText?: string;
  title?: string;
  onOpen?: (data: T) => void;
  onYes: (data: T) => void;
  onNo?: (data: T) => void;
  onClose?: (data: T) => void;
  onDisableShowDialogChange?: (name: string) => void;
  children?: React.ReactNode;
}

interface ReduxProps<T> {
  disableShowDialogList: string[];
  data: T;
}

interface DispatchProps {
  close: (name: string) => void;
}

interface Props<T> extends OwnProps<T>, ReduxProps<T>, DispatchProps {
}


class KreoConfirmationDialogComponent<T> extends React.Component<Props<T>> {
  public static defaultProps: Partial<Props<any>> = {
    yesText: 'Yes',
    noText: 'No',
    title: 'Are you sure?',
  };

  public render(): React.ReactNode {
    const disableShowDialog = this.props.onDisableShowDialogChange
      && this.props.disableShowDialogList
      && this.props.disableShowDialogList.includes(this.props.name);
    return (
      <KreoDialog
        name={this.props.name}
        title={this.props.title}
        onOpen={this.onOpen}
        onClose={this.props.onClose}
      >
        <div className='sure-dialog__body'>
          {this.props.children}
          {this.props.onDisableShowDialogChange
            ? (
              <div className='sure-dialog__checkbox'>
                <Checkbox
                  label={`Don't show this message again`}
                  color='blue'
                  checked={disableShowDialog}
                  onChange={this.onChangeDisableShowDialog}
                />
              </div>
            ) : null}
        </div>
        <KreoDialogActionsPanel
          onCancel={this.onNo}
          cancelBtnName={this.props.noText}
          cancelBtnControlName={`${StringUtils.titleCaseToSnakeCase(this.props.title)}-${this.props.noText}-button`}
          onSubmit={this.onYes}
          submitBtnName={this.props.yesText}
          submitBtnControlName={`${StringUtils.titleCaseToSnakeCase(this.props.title)}-${this.props.yesText}-button`}
        />
      </KreoDialog>
    );
  }

  @autobind
  private onYes(): void {
    this.props.close(this.props.name);
    this.props.onYes(this.props.data);
  }

  @autobind
  private onOpen(): void {
    if (this.props.onOpen) {
      this.props.onOpen(this.props.data);
    }
  }

  @autobind
  private onNo(): void {
    this.props.close(this.props.name);
    if (this.props.onNo) {
      this.props.onNo(this.props.data);
    }
  }

  @autobind
  private onChangeDisableShowDialog(): void {
    this.props.onDisableShowDialogChange(this.props.name);
  }
}

function mapStateToProps<T>(state: State, ownProps: OwnProps<T>): ReduxProps<T> {
  return {
    disableShowDialogList: state.persistedStorage.disableShowDialogList,
    data: state.dialog[ownProps.name],
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    close: (name: string) => {
      dispatch(KreoDialogActions.closeDialog(name));
    },
  };
};

export const KreoConfirmationDialog = connect(mapStateToProps, mapDispatchToProps)(KreoConfirmationDialogComponent);
