import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import { Styled } from './styled';

interface Props {
  value: string;
  onBlur: (newValue: string) => void;
  pattern?: string;
  title?: string;
  size?: number;
  maxLength?: number;
  multiple?: boolean;
  placeholder?: string;
  disabled?: boolean;
  isAutoFocus?: boolean;
  inputId?: string;
  onChange?: (newValue: string) => void;
  onFocus?: (newValue: string) => void;
}

export const EmailInput: React.FC<Props> = (props) => {
  const {
    placeholder = 'Enter email',
    value,
    pattern,
    title,
    size,
    maxLength,
    multiple,
    onChange,
    onBlur,
    onFocus,
    disabled,
    isAutoFocus,
    inputId,
  } = props;

  const formRef = useRef<HTMLFormElement>();

  const [inputValue, setValue] = useState<string>(value);
  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
    setValue(e.target.value);
  }, [setValue, onChange]);

  const handleBlur = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onBlur(e.target.value);
    if (e.target.value) {
      formRef.current.requestSubmit();
    }
  }, [onBlur]);

  const handleFocus = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onFocus) {
      onFocus(e.target.value);
    }
  }, [onFocus]);

  return (
    <Styled.Form ref={formRef} onSubmit={ConstantFunctions.preventDefault}>
      <Styled.InputStyle
        id={inputId}
        type='email'
        required={true}
        placeholder={placeholder}
        value={inputValue}
        disabled={disabled}
        autoFocus={isAutoFocus}
        size={size}
        maxLength={maxLength}
        multiple={multiple}
        title={title}
        pattern={pattern}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </Styled.Form>
  );
};
