import autobind from 'autobind-decorator';
import React from 'react';
import { Field } from 'redux-form';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import {
  MaterialMenuItem,
  MaterialNumberInputField,
  MaterialNumberInputProps,
  MaterialSelectField,
  MaterialSelectProps,
} from 'common/UIKit';
import { LaborVariantModel } from '../../interfaces/resources-data';


interface Props extends I18nAwareProps {
  readonly: boolean;
  variant: LaborVariantModel;
  onChange: (root: LaborVariantModel) => void;
}

class DatabaseResourceLaborVariantComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <div className='database-resource-labor'>
        <div className='database-resource-labor__skill'>
          <Field<MaterialSelectProps>
            component={MaterialSelectField}
            name='name'
            value={this.props.variant.name}
            placeholder='Select skill'
            label='Skill'
            onChange={this.onNameChange}
            disabled={this.props.readonly}
            displayBottomInformation={true}
          >
            {this.getSkillVariants()}
          </Field>
        </div>
        <div className='database-resource-labor__cost-per-hour'>
          <Field<MaterialNumberInputProps>
            name='cost'
            component={MaterialNumberInputField}
            label={`Cost Per Hour (${this.props.i18n.currency.symbol})`}
            isFloatingLabel={false}
            value={this.props.variant.cost}
            onChange={this.onCostPerHourChange}
            placeholder='0.00'
            precision={2}
            valueType='float'
            disabled={this.props.readonly}
            displayBottomInformation={true}
          />
        </div>
      </div>
    );
  }

  @autobind
  private onNameChange(_: React.ChangeEvent, name: string): void {
    this.props.onChange({
      ...this.props.variant,
      name,
    });
  }

  @autobind
  private onCostPerHourChange(_: React.ChangeEvent, costPerHour: number): void {
    this.props.onChange({
      ...this.props.variant,
      cost: costPerHour,
    });
  }

  private getSkillVariants(): JSX.Element[] {
    const variants = [
      'Foreman',
      'General Operative Cijc Oper Rate',
      'General Operative Skill Rate 1',
      'General Operative Skill Rate 2',
      'General Operative Skill Rate 3',
      'General Operative Skill Rate 4',
    ];

    const variantsToRender = !this.props.variant.name || variants.includes(this.props.variant.name)
      ? variants
      : variants.concat(this.props.variant.name).sort();

    return variantsToRender.map(x => (<MaterialMenuItem key={x} value={x}>{x}</MaterialMenuItem>));
  }
}

export const DatabaseResourceLaborVariant = withI18n(DatabaseResourceLaborVariantComponent);
