import { Icons, HoverMenuInteractive, LinkComponent } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { RenderIf } from 'common/components/render-if';
import { DemoProjectType } from 'common/constants/project-type';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { DemoProject } from 'unit-projects/interfaces/demo-project';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { DatabaseExampleList } from './database-example-list';

const APPLY_DATABASE_ERROR_DIALOG = 'applyDatabaseErrorDialogName';

interface StateProps {
  databaseExamples: DemoProject[];
  applyDatabaseStatus: RequestStatus;
}

interface DispatchProps {
  openDialog: () => void;
  closeDialog: () => void;
  fetchExamples: () => void;
  applyExample: (id: number) => void;
  resetRequestStatus: () => void;
}
interface Props extends StateProps, DispatchProps, AnalyticsProps {}

export class DatabaseExamplesComponent extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.fetchExamples();
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.applyDatabaseStatus !== prevProps.applyDatabaseStatus
      && this.props.applyDatabaseStatus === RequestStatus.Failed) {
      this.props.openDialog();
    }
  }

  public render(): React.ReactNode {
    return (
      <RenderIf condition={!!this.props.databaseExamples.length}>
        <HoverMenuInteractive MenuComponent={this.renderDatabaseExamples} shift={5}>
          <LinkComponent
            text='Assemblies Base Samples'
            Icon={Icons.Internal}
          />
        </HoverMenuInteractive>
        <ConfirmationDialog
          name={APPLY_DATABASE_ERROR_DIALOG}
          title='Something wrong'
          text='Probably the sample contains the same names as in your assemblies database'
          cancelButtonText='Okay'
          onCancel={this.closeErrorDialog}
        />
      </RenderIf>
    );
  }

  @autobind
  private renderDatabaseExamples(): React.ReactNode {
    return (
      <DatabaseExampleList
        projects={this.props.databaseExamples}
        onApply={this.applyExample}
      />
    );
  }

  @autobind
  private applyExample(id: number): void {
    this.props.applyExample(id);
    const exampleName = this.props.databaseExamples.find(e => e.id === id);
    if (exampleName) {
      this.props.sendEvent(MetricNames.pia.applyItemDatabase, { name: exampleName.name });
    }
  }

  @autobind
  private closeErrorDialog(): void {
    this.props.closeDialog();
    this.props.resetRequestStatus();
  }
}

const mapStateToProps = (state: State): StateProps => {
  return {
    databaseExamples: state.projects.demoProjects[DemoProjectType.PiaDb],
    applyDatabaseStatus: state.projects.requests.applyDemoProject,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openDialog: () => dispatch(KreoDialogActions.openDialog(APPLY_DATABASE_ERROR_DIALOG)),
    closeDialog: () => dispatch(KreoDialogActions.closeDialog(APPLY_DATABASE_ERROR_DIALOG)),
    fetchExamples: () => dispatch(ProjectsActions.fetchDemoProjectsRequest(DemoProjectType.PiaDb)),
    applyExample: (id) => dispatch(ProjectsActions.patchCompanyFromDemoProject(id)),
    resetRequestStatus: () => dispatch(ProjectsActions.setApplyDemoProjectStatus(RequestStatus.NotRequested)),
  };
};

export const DatabaseExamples = withAnalyticsContext(
  connect(mapStateToProps, mapDispatchToProps)(DatabaseExamplesComponent));
