import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, StaticContext } from 'react-router';
import { Link } from 'react-router-dom';
import { Action, Dispatch } from 'redux';

import './page.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { MainLayout } from 'common/components/main-layout';
import { SubHeader } from 'common/components/sub-header/sub-header';
import { SubheaderBillingNotice } from 'common/components/subheader-billing-notice/subheader-billing-notice';
import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { RoleGroup } from 'common/enums/role-group';
import { CompanyRoles } from 'common/interfaces/account/company-roles';
import { State as ReduxState } from 'common/interfaces/state';
import { RoutingContextProps, withRoutingContext } from 'common/routing/with-routing-context';
import { KreoDialogActions, KreoIconCreateNew, KreoIconPeopleEmpty } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import {
  INVITE_TO_COMPANY_DIALOG_NAME,
  InvitePeopleToCompanyDialog,
} from '../../components/dialog';
import { KreoConfirmationDialog } from '../../components/dialog/kreo-confirmation-dialog';
import { getRootMenuItems } from '../../units/menu-items';
import { Company } from '../account/interfaces/company';
import { CompanySubscriptionModel } from '../account/interfaces/company-subscription-model';
import { AccountSelectors } from '../account/selectors';
import { SubscriptionActions } from '../subscription/actions/creators';
import { PeopleActions } from './actions/actions';
import { PeopleCategoriesMenu } from './components/people-categories-menu';
import { PersonTile } from './components/person-tile';
import { Person } from './interfaces/person';

interface ReduxProps {
  peopleRequestStatus: RequestStatus;
  people: Person[];
  company: Company;
  companyRoles: CompanyRoles;
  displayedRoleGroup: RoleGroup | null;
  isSelectedCompanyLicensesLimitExceeded: boolean;
  subscription: CompanySubscriptionModel;
}

interface ReduxActions {
  loadSubscriptionRoles: () => void;
  showDialog: (dialogName: string) => void;
  closeDialog: (dialogName: string) => void;
  submit: () => void;
  removePersonFromCompany: (userId: string) => void;
  cancelInvitation: (email: string) => void;
}

interface PageProps
  extends ReduxProps,
  ReduxActions,
  RouteComponentProps<any, StaticContext>,
  RoutingContextProps,
  AbilityAwareProps { }

interface State {
  personToBeDeleted: Person;
}

const personDeleteDialogName = 'person-delete-dialog-name';

class PeoplePageComponent extends React.Component<PageProps, State> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      personToBeDeleted: null,
    };
  }

  public componentDidMount(): void {
    this.props.loadSubscriptionRoles();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: PageProps): void {
    if (
      nextProps.company &&
      (!this.props.company || this.props.company.id !== nextProps.company.id)
    ) {
      nextProps.loadSubscriptionRoles();
    }
  }

  public render(): React.ReactNode {
    const {
      people,
      displayedRoleGroup,
      peopleRequestStatus,
      companyRoles,
      ability,
      urls,
      isSelectedCompanyLicensesLimitExceeded,
    } = this.props;

    const {
      employeesMaxCount: companyEmployeesMaxCount,
      isCollaborationBlocked,
    } = this.props.subscription;

    const peopleToDisplay =
      displayedRoleGroup && companyRoles && companyRoles.roles
        ? people.filter(person => {
          if (!person.isAccepted) {
            return false;
          }

          const role = companyRoles.roles.find(r => person.roleId === r.id);
          if (!role) {
            console.error(
              'Encountered person with role that is not present in the company',
              person,
              companyRoles,
            );
          }

          return role ? role.group === displayedRoleGroup : false;
        })
        : people;

    const subHeaderButtonRelatedProps =
      ability.can(Operation.Create, Subject.Employees) ||
        ability.can(Operation.Create, Subject.Guests) ||
        ability.can(Operation.Create, Subject.Subcontractors)
        ? {
          buttonIcon: <KreoIconCreateNew />,
          buttonText: 'Invite People',
          onButtonClick: this.openInvitePeopleDialog,
        }
        : {};

    const showBillingNotice = isSelectedCompanyLicensesLimitExceeded || isCollaborationBlocked;

    return (
      <MainLayout
        getMenuItems={getRootMenuItems}
        subHeader={
          <SubHeader
            title='People'
            {...subHeaderButtonRelatedProps}
            alert={
              showBillingNotice && (
                <SubheaderBillingNotice
                  text={
                    isSelectedCompanyLicensesLimitExceeded
                      ? (
                        <React.Fragment>
                          Your subscription does not support adding more than
                            <b> {companyEmployeesMaxCount} Employee{companyEmployeesMaxCount > 1 ? 's' : ''}</b>
                          . Remove unnecessary Employees or
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          Your subscription does not support collaboration features.
                            To upgrade to a <b>Pro</b> plan,
                        </React.Fragment>
                      )
                  }

                />
              )
            }
            centerSectionContent={<PeopleCategoriesMenu />}
            rightSectionContent={
              ability.can(Operation.Read, Subject.Role) && (
                <Link to={urls.people.permissions.url()} className='permissions-page-link'>
                  Permissions
                </Link>
              )
            }
          />
        }
        backUrl={AppUrls.products.url()}
        metaTitle='People'
      >
        <InvitePeopleToCompanyDialog onSubmit={this.onSubmit} />
        {this.renderPersonRemoveDialog()}
        <div className='content-wrapper'>
          {peopleRequestStatus === RequestStatus.Loading ? (
            <SvgSpinner size='large' />
          ) : (
              <div className='people-page'>
                <div className='people-page__tiles-wrapper'>
                  {peopleToDisplay.length > 0 ? (
                    peopleToDisplay.map(p => {
                      return (
                        <PersonTile
                          person={p}
                          key={p.email}
                          deletePerson={this.openDeletePersonDialog}
                          isLicensesLimitExceeded={isSelectedCompanyLicensesLimitExceeded}
                        />
                      );
                    })
                  ) : (
                      <div className='people-page__empty'>
                        <KreoIconPeopleEmpty />
                        <div className='people-page__empty-text'>Add your first people!</div>
                      </div>
                  )}
                </div>
              </div>
          )}
        </div>
      </MainLayout>
    );
  }

  @autobind
  private openInvitePeopleDialog(): void {
    this.props.showDialog(INVITE_TO_COMPANY_DIALOG_NAME);
  }

  @autobind
  private onSubmit(): void {
    this.props.submit();
    this.props.closeDialog(INVITE_TO_COMPANY_DIALOG_NAME);
  }

  @autobind
  private openDeletePersonDialog(person: Person): void {
    this.setState({ personToBeDeleted: person }, () =>
      this.props.showDialog(personDeleteDialogName),
    );
  }

  @autobind
  private deletePerson(): void {
    const { removePersonFromCompany, cancelInvitation } = this.props;
    const person = this.state.personToBeDeleted;
    if (person.isAccepted) {
      removePersonFromCompany(person.id);
    } else {
      cancelInvitation(person.email);
    }
  }

  private renderPersonRemoveDialog(): React.ReactNode {
    const person = this.state.personToBeDeleted;
    if (!person) {
      return null;
    }

    return (
      <KreoConfirmationDialog
        name={personDeleteDialogName}
        onYes={this.deletePerson}
        yesText='Delete'
        noText='Cancel'
        title='Delete Member'
      >
        {person.lastName ? `${person.firstName} ${person.lastName}` : person.email} will be deleted
        from your Company
      </KreoConfirmationDialog>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    peopleRequestStatus: state.people.requests.companiesUsers,
    people: state.people.companiesUsers || [],
    company: state.account.selectedCompany,
    displayedRoleGroup: state.people.displayedRoleGroup,
    companyRoles: state.account.subscriptionRoles,
    isSelectedCompanyLicensesLimitExceeded: AccountSelectors.isSelectedCompanyLicensesLimitExceeded(state),
    subscription: AccountSelectors.currentSubscription(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    loadSubscriptionRoles: () => dispatch(SubscriptionActions.loadSubscriptionRoles()),
    showDialog: dialogName => dispatch(KreoDialogActions.openDialog(dialogName)),
    closeDialog: dialogName => dispatch(KreoDialogActions.closeDialog(dialogName)),
    submit: () => {
      dispatch(PeopleActions.invitePeopleToCompany());
    },
    removePersonFromCompany: userId => dispatch(PeopleActions.deleteCompanyPerson(userId)),
    cancelInvitation: email => dispatch(PeopleActions.rejectInvitation(email)),
  };
};

export const PeoplePage = withRoutingContext(
  withAbilityContext(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(PeoplePageComponent),
  ),
);
