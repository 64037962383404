import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { Property } from 'common/components/property';
import { KreoDialogActions } from 'common/UIKit';
import { SelectValue } from '../values/select-value';

interface Props {
  values: Record<string, { name: string, instancesIds: string[] }>;
  canEdit: boolean;
}

const GroupPropertyComponent: React.FC<Props> = ({
  values,
  canEdit,
}) => {
  const dispatch = useDispatch();

  const startEdit = useCallback(() => {
    dispatch(KreoDialogActions.openDialog(DrawingDialogs.MOVE_TO_DIALOG));
  }, []);

  const getValueForFilter = useCallback((property: string) => {
    return values[property].name;
  }, [values]);

  const groupElements = useMemo(() => {
    return Object.entries(values)
      .reduce((acc, [key, value]) => {
        acc[key] = value.instancesIds;
        return acc;
      }, {});
  }, [values]);

  return (
    <Property title='Group' startEditing={canEdit ? startEdit : undefined}>
      <SelectValue
        defaultValue='Mixed'
        elements={groupElements}
        getElementLabel={getValueForFilter}
      />
    </Property>
  );
};

export const GroupProperty = React.memo(GroupPropertyComponent);
