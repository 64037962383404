import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import './classification-revit-tree-body.scss';

import { SvgSpinner } from 'common/components/svg-spinner';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { KreoScrollbars, VirtualList } from 'common/UIKit';
import { KreoScrollbarsApi } from 'common/UIKit/scrollbars/kreo-scrollbars';
import { RevitTreeLevel } from '../../enums/revit-tree-level';
import { ValidationStepMode } from '../../enums/validation-step-mode';
import {
  ClassificationEngineContextAwareProps,
} from '../../interfaces/classification/classification-engine-context-aware-props';
import { ClassificationUtils } from '../../utils/classification-utils';
import { ClassificationRevitTreeItemEdit } from '../classification-revit-tree-item-edit';
import { ClassificationRevitTreeItemView } from '../classification-revit-tree-item-view';
import { ClassificationRevitTreeSearchInput } from '../classification-revit-tree-search-input';
import { ClassificationSelectedPopup } from '../classification-selected-popup';
import {
  withClassificationEngineContext,
} from '../with-classification-engine-context/with-classification-engine-context';

interface OwnProps {
  mode: ValidationStepMode;
  isShowPredicted?: boolean;
}


interface BodyStateProps {
  tree: number[];
  isLoaded: boolean;
  centerElement: number;
  minimumLevel: RevitTreeLevel;
  treeIsIsolated: boolean;
  copyPastInProccess: boolean;
}

interface ComponentState {
  scrollRef: HTMLDivElement;
}

interface Props extends OwnProps, BodyStateProps, ClassificationEngineContextAwareProps {}

class ClassificationRevitTreeBodyComponent extends React.PureComponent<Props, ComponentState> {
  private readonly itemHeight: number = 50;
  private bodyContainerRef: HTMLDivElement;
  private needScrollTop: boolean;
  private scrollApi: KreoScrollbarsApi;

  constructor(props: Props) {
    super(props);

    this.state = {
      scrollRef: undefined,
    };
  }

  public componentDidMount(): void {
    if (this.props.centerElement !== null) {
      this.scrollTop();
      this.needScrollTop = true;
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.centerElement !== prevProps.centerElement || this.needScrollTop) {
      this.scrollTop();
      if (this.needScrollTop) {
        this.needScrollTop = false;
      }
    }
  }

  public render(): React.ReactNode {
    const className = classNames('classification-revit-tree-body', {
      'classification-revit-tree-body--predicted': this.props.isShowPredicted,
    });
    const itemsFactory = this.props.mode === ValidationStepMode.View ? this.viewItemFactory : this.editItemFactory;
    return (
      <div className={className} ref={this.saveBodyContainerRef}>
        <ClassificationRevitTreeSearchInput />
        <ClassificationSelectedPopup isolatedTree={this.props.treeIsIsolated} />
        <KreoScrollbars
          showShadowTop={true}
          sendApi={this.saveScrollApi}
          onSendRef={this.saveRef}
        >
          <div className='classification-revit-tree-body__wrapper'>
            {this.props.isLoaded ? (
              <VirtualList
                bufferSize={21}
                viewport={this.state.scrollRef}
                items={this.props.tree}
                itemHeight={50}
                itemFactory={itemsFactory}
              />
            ) : (
              <SvgSpinner size='middle' />
            )}
          </div>
        </KreoScrollbars>
        {this.props.copyPastInProccess && (
          <div className='classification-revit-tree-body__spinner'>
            <SvgSpinner size='middle' />
          </div>
        )}
      </div>
    );
  }

  @autobind
  private saveRef(ref: HTMLDivElement): void {
    this.setState({ scrollRef: ref });
  }


  @autobind
  private viewItemFactory(value: number): React.ReactNode {
    return (
      <ClassificationRevitTreeItemView
        minimumLevel={this.props.minimumLevel}
        key={value}
        index={value}
        buttonName={this.props.mode}
        isIsolatedTree={this.props.treeIsIsolated}
        onHover={this.props.setHighLighted}
        onFocus={this.props.onFocus}
      />
    );
  }

  @autobind
  private editItemFactory(value: number): React.ReactNode {
    return (
      <ClassificationRevitTreeItemEdit
        minimumLevel={this.props.minimumLevel}
        key={value}
        index={value}
        buttonName={this.props.mode}
        isIsolatedTree={this.props.treeIsIsolated}
        onHover={this.props.setHighLighted}
        onFocus={this.props.onFocus}
      />
    );
  }


  private scrollTop(): void {
    const containerHeight = this.bodyContainerRef.getBoundingClientRect().height;
    const topElement = this.props.centerElement;
    const topPosition = this.itemHeight * topElement - (this.itemHeight + containerHeight) / 2;
    this.scrollApi.setScrollTop(topPosition);
  }

  @autobind
  private saveBodyContainerRef(ref: HTMLDivElement): void {
    this.bodyContainerRef = ref;
  }

  @autobind
  private saveScrollApi(api: KreoScrollbarsApi): void {
    this.scrollApi = api;
  }

}

function mapStateToProps(state: State): BodyStateProps {
  const isIsolatedTree = ClassificationUtils.isTreeFiltered(state.classification.modelBrowserFilters);
  const first = state.classification.modelBrowserFiltered[0];
  return {
    minimumLevel: isIsolatedTree && first ? first.level : RevitTreeLevel.Category,
    tree: isIsolatedTree ?
      ClassificationUtils.getModelBrowserNodes(state.classification.modelBrowserFiltered)
      : ClassificationUtils.getModelBrowserNodes(state.classification.modelTree),
    isLoaded: state.classification.loadedStatistic,
    centerElement: state.classification.treeCenterElement,
    treeIsIsolated: isIsolatedTree,
    copyPastInProccess: state.classification.copyPastRequestState === RequestStatus.Loading,
  };
}


const connector = connect(mapStateToProps);
export const ClassificationRevitTreeBody =
withClassificationEngineContext(connector(ClassificationRevitTreeBodyComponent));
