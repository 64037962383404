import * as React from 'react';
import { Styled } from './styled';

interface Props<T> {
  items: T[];
  renderItem: (instanceId: T) => React.ReactNode;
}

export function FullPreview<T>(props: Props<T>): JSX.Element {
  return (
    <Styled.Container>
      {
        props.items.map(props.renderItem)
      }
    </Styled.Container>
  );
}
