import React from 'react';
import { useDownloadImage } from './use-download-image';


export function wrapComponentWithImageAuth<P>(
  Component: React.ComponentType<P>,
  imageFiled: keyof P,
): React.ComponentType<P> {
  return (props: P) => {
    const image = useDownloadImage(props[imageFiled] as any);
    return <Component {...props} {...{ [imageFiled]: image }} />;
  };
}
