import { DrawingNeededValueType } from 'common/components/drawings/enums/drawing-need-value-type';
import { TableSettings } from '../interfaces/table-presets';

export const ColumnKeys = {
  documentName: 'f_documentName',
  drawingName: 'f_drawingName',
  groupsBreakdown: 'g_groupsBreakdown',
  parentGroupName: 'g_parentGroupName',
  name: 'f_name',
  area: 'f_area',
  perimeter: 'f_perimeter',
  length: 'f_length',
  count: 'f_count',
  segmentsCount: 'f_segmentsCount',
  pointsCount: 'f_pointsCount',
  verticalArea: 'f_verticalArea',
  volume: 'f_volume',
  thickness: 'f_thickness',
  height: 'f_height',
};

export const ColumnNames: Record<string, string> = {
  [ColumnKeys.documentName]: 'File name',
  [ColumnKeys.drawingName]: 'Page name',
  [ColumnKeys.groupsBreakdown]: 'Group name',
  [ColumnKeys.parentGroupName]: 'Parent group',
  [ColumnKeys.name]: 'Measurement name',
  [ColumnKeys.count]: 'Count',
  [ColumnKeys.length]: 'Length',
  [ColumnKeys.height]: 'Height',
  [ColumnKeys.thickness]: 'Thickness',
  [ColumnKeys.perimeter]: 'Perimeter',
  [ColumnKeys.area]: 'Area',
  [ColumnKeys.verticalArea]: 'Vertical area',
  [ColumnKeys.volume]: 'Volume',
  [ColumnKeys.pointsCount]: 'Points Count',
  [ColumnKeys.segmentsCount]: 'Segments Count',
};

export const SettingsSorting: Record<string, number> = {
  [DrawingNeededValueType.Count]: 1,
  [DrawingNeededValueType.Length]: 2,
  [DrawingNeededValueType.Height]: 3,
  [DrawingNeededValueType.Width]: 4,
  [DrawingNeededValueType.Thickness]: 5,
  [DrawingNeededValueType.Perimeter]: 6,
  [DrawingNeededValueType.Area]: 7,
  [DrawingNeededValueType.VerticalArea]: 8,
  [DrawingNeededValueType.Volume]: 9,
  [DrawingNeededValueType.Diameter]: 10,
  [DrawingNeededValueType.Radius]: 11,
  [DrawingNeededValueType.Angle]: 12,
  [DrawingNeededValueType.PointsCount]: 13,
  [DrawingNeededValueType.PointsCountFr]: 13,
  [DrawingNeededValueType.SegmentsCount]: 14,
  [DrawingNeededValueType.SegmentsCountFr]: 14,
  [DrawingNeededValueType.SegmentLength]: 15,
  [DrawingNeededValueType.Name]: 16,
  [DrawingNeededValueType.DrawingName]: 17,
  [DrawingNeededValueType.GroupName]: 18,
  [DrawingNeededValueType.GroupNameFr]: 18,
  [DrawingNeededValueType.DocumentName]: 19,
  [DrawingNeededValueType.Property]: 20,
  [DrawingNeededValueType.Table]: 21,
  [DrawingNeededValueType.DynamicTable]: 22,
  [DrawingNeededValueType.UnKnown]: 23,
};

export const DefaultSettings: TableSettings = {
  columnKeys: [
    ColumnKeys.documentName,
    ColumnKeys.drawingName,
    ColumnKeys.groupsBreakdown,
    ColumnKeys.name,
    ColumnKeys.area,
    ColumnKeys.perimeter,
    ColumnKeys.length,
    ColumnKeys.count,
    ColumnKeys.pointsCount,
    ColumnKeys.segmentsCount,
    ColumnKeys.verticalArea,
    ColumnKeys.volume,
    ColumnKeys.thickness,
  ],
  showColumnHeaders: false,
  showTotal: false,
};

export const measure3dColumnKeys = new Set<string>([
  ColumnKeys.height,
  ColumnKeys.verticalArea,
  ColumnKeys.thickness,
  ColumnKeys.volume,
]);
