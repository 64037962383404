import autobind from 'autobind-decorator';
import * as React from 'react';

import { SubscriptionType } from 'common/constants/subscription';
import { KreoButton } from 'common/UIKit';
import { HeaderAddContainer, InformationItem, InformationRow } from '..';
import { AdminCompanyVm } from '../../interfaces/admin-company-vm';
import { ShortUserWithRole } from '../../interfaces/short-user';
import { ShortUserItem } from '../items/short-user-item';
import { CompanyMockSubscriptionControlItem } from './company-mock-subscription-control';


interface Props {
  company: AdminCompanyVm;
  subscriptionType: SubscriptionType;
  createEmptySubscription: (companyId: number, subscriptionType: SubscriptionType) => void;
  onEmployeesAttach?: (companyId: number, subscriptionType: SubscriptionType) => void;
  onEmployeeDetachClick?: (companyId: number, employeeGuid: string, subscriptionType: SubscriptionType) => void;
  onEmployeeClick?: (email: string, subscriptionType: SubscriptionType) => void;
}


export class CompanySubscriptionControl extends React.Component<Props> {
  public render(): React.ReactNode {
    const { company, subscriptionType } = this.props;
    const subscription = company.subscriptions[subscriptionType];

    if (!subscription) {
      return (
        <div className='company-subscription-control'>
          <div className='company-subscription-control__title'>
            {`Subscription: ${subscriptionType}`}
          </div>
          <div>
            <KreoButton
              size='medium'
              mode='action'
              caption={`Create ${subscriptionType} subscription`}
              className='company-subscription-control__save-changes-button'
              onClick={this.createEmptySubscription}
            />
          </div>
        </div>);
    }

    return (
      <div className='company-subscription-control'>
        <div className='company-subscription-control__title'>
          {`Subscription: ${subscriptionType}`}
        </div>
        <InformationRow>
          <InformationItem
            title={'Employees'}
            data={subscription.employees}
            renderItem={this.renderEmployee}
            renderHeader={this.renderHeader}
            action={this.onEmployeeAttach}
          />
        </InformationRow>
        <div>
          <div >
            Mock Subscription
          </div>
          <div>
            <CompanyMockSubscriptionControlItem companyId={company.id} subscriptionType={subscriptionType}/>
          </div>
        </div >
      </div>
    );
  }

  @autobind
  private renderHeader(): React.ReactNode {
    return (
      <HeaderAddContainer action={this.onEmployeeAttach} />
    );
  }

  @autobind
  private renderEmployee(user: ShortUserWithRole): React.ReactNode {
    return (
      <ShortUserItem
        key={`${this.props.company.id}_${user.guid}`}
        user={user}
        className='admin-item__chip clickable'
        onClick={this.onEmployeeClick}
        onDetachClick={this.onEmployeeDetachClick}
        companyEmail={this.props.company.email}
      />
    );
  }

  @autobind
  private onEmployeeDetachClick(item: ShortUserWithRole): void {
    if (this.props.onEmployeeDetachClick) {
      this.props.onEmployeeDetachClick(this.props.company.id, item.guid, this.props.subscriptionType);
    }
  }

  @autobind
  private onEmployeeAttach(): void {
    if (this.props.onEmployeesAttach) {
      this.props.onEmployeesAttach(this.props.company.id, this.props.subscriptionType);
    }
  }

  @autobind
  private onEmployeeClick(item: ShortUserWithRole): void {
    if (this.props.onEmployeeClick) {
      this.props.onEmployeeClick(item.email, this.props.subscriptionType);
    }
  }

  @autobind
  private createEmptySubscription(): void {
    const { createEmptySubscription, company, subscriptionType } = this.props;
    createEmptySubscription(company.id, subscriptionType);
  }
}

