import styled from 'styled-components';

import { ShapeSwitcherStyled } from 'common/components/drawings/drawings-controls/drawings-instances-styles';
import { PropertyStyled } from 'common/components/property';

const Container = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  ${ShapeSwitcherStyled.Container} {
    margin-right: 20px;
  }
`;

const ShapeSwitcherWrapper = styled.div`
  ${PropertyStyled.Container} {
    > p {
      height: 100%;
    }
  }
`;

export const Styled = {
  Container,
  ShapeSwitcherWrapper,
};
