import moment from 'moment';


export function getRemainingDays(nextBilling: string): string {
  let result = '';
  const currentDate = moment().format();
  const endDate = moment(nextBilling);
  const countRemainingDays = moment.duration(endDate.diff(currentDate));

  if (countRemainingDays.years() !== 0) {
    result = result.concat(countRemainingDays.years().toString(), ' years ');
  }
  if (countRemainingDays.months() !== 0) {
    result = result.concat(countRemainingDays.months().toString(), ' months ');
  }
  if (countRemainingDays.days() !== 0) {
    const bunch = result.length ? 'and ' : '';
    result = result.concat(bunch, countRemainingDays.days().toString(), ' days');
  }

  if (result.length) {
    return result;
  } else {
    return result.concat('0 days');
  }
}
