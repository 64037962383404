import * as React from 'react';

import { TextInput } from 'common/components/inputs';

interface Props {
  value: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
}

const InputApiWrapperComponent: React.FC<Props> = ({
  value,
  disabled = false,
  onChange,
  onBlur,
  onFocus,
}) => {
  return (
    <TextInput
      value={value}
      onChange={onChange}
      disabled={disabled}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

export const InputApiWrapper = React.memo(InputApiWrapperComponent);
