import { useCallback, useMemo } from 'react';
import { AssemblyForm } from '../../assembly-panel/item-form-updater';
import { ASSEMBLY_GROUP_FORM_KEY, ITEM_GROUP_FORM_KEY } from '../constants';
import { MeasureForm } from '../interfaces';

function* getAssemblyGroupKeysIterator(assemblyForm: MeasureForm): IterableIterator<string> {
  for (const [key, value] of Object.entries(assemblyForm.assemblyGroupForm)) {
    yield `${ASSEMBLY_GROUP_FORM_KEY}_${key}`;
    for (const [itemKey, itemValue] of Object.entries(value.assemblyGroups.itemGroupForm)) {
      yield `${ASSEMBLY_GROUP_FORM_KEY}_${key}_${itemKey}`;
      for (const propertyGroupKey of Object.keys(itemValue.itemForm.groupForm)) {
        yield `${ASSEMBLY_GROUP_FORM_KEY}_${key}_${itemKey}_${propertyGroupKey}`;
      }
    }
  }
}

function* getItemGroupKeysIterator(itemForm: AssemblyForm): IterableIterator<string> {
  for (const [key, value] of Object.entries(itemForm.itemGroupForm)) {
    yield `${ITEM_GROUP_FORM_KEY}_${key}`;
    for (const propertyGroupKey of Object.keys(value.itemForm.groupForm)) {
      yield `${ITEM_GROUP_FORM_KEY}_${key}_${propertyGroupKey}`;
    }
  }
}

export const useGroupKeysCallback = (assemblyForm: MeasureForm, itemForm: AssemblyForm): () => Set<string> => {
  const assemblyKeys = useMemo(() => new Set(getAssemblyGroupKeysIterator(assemblyForm)), [assemblyForm]);
  const itemKeys = useMemo(() => new Set(getItemGroupKeysIterator(itemForm)), [itemForm]);
  const keys = useMemo(() => new Set([...assemblyKeys, ...itemKeys]), [assemblyKeys, itemKeys]);
  return useCallback(() => keys, [keys]);
};
