import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import { HelpUserButton } from 'common/components/help-user-button';
import { Text } from 'common/components/text';
import { Styled } from './styled';

interface Props {
  formulaName: string;
}

const HeaderComponent: React.FC<Props> = ({ formulaName }) => {
  return (
    <Styled.FormulaTitle>
      <Styled.PropertyName>
        <Icons.Formula />
        <Text
          withEllipsis={true}
          fontSize={16}
          lineHeight={20}
        >
          {formulaName}
        </Text>
      </Styled.PropertyName>
      <a
        href='https://help-takeoff.kreo.net/en/articles/6204588-how-to-create-the-formula-property'
        target='_blank'
      >
        <HelpUserButton
          width={20}
          height={20}
          Icon={Icons.Question2D}
          tooltipText='How to create the "Formula" property'
        />
      </a>
    </Styled.FormulaTitle>
  );
};

export const Header = React.memo(HeaderComponent);
