import React from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { WizzardStatus } from '../../../enums/dropper-state';
import { PdfGeometry } from '../../../interfaces/api-responses/pdf-geometry-response';
import { LoadingPopup } from '../loading-popup';
import { EraserMenu } from './eraser-menu';
import { FinderResultOverview } from './finder-result-overview';
import { FinderSettings } from './finder-settings';


const FinderMenuComponent: React.FC = () => {
  const hasArea = useSelector<State, boolean>(x => !!x.drawings.wizzardTools.finderSelectionArea);
  const status = useSelector<State, WizzardStatus>(x => x.drawings.wizzardTools.wizzardState?.status);
  const selectedGeometries = useSelector<State, PdfGeometry[]>(x => x.drawings.wizzardTools.finderSelectedGeometries);
  if (!hasArea) return null;

  switch (status) {
    case WizzardStatus.Start:
      if (selectedGeometries) {
        return (
          <FinderSettings/>
        );
      } else {
        return (
          <LoadingPopup/>
        );
      }
    case WizzardStatus.Loading:
      return (
        <LoadingPopup/>
      );
    case WizzardStatus.Preview:
      return (
        <FinderResultOverview/>
      );
    case WizzardStatus.Eraser:
      return (
        <EraserMenu/>
      );
    default:
      return null;
  }
};


export const FinderMenu = React.memo(FinderMenuComponent);
