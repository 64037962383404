import * as Ag from 'ag-grid-community';

import { AgGridDataTransferStorage } from './ag-grid-data-transfer-storage';


export type OnImportCallback<T> = (data: T, api: Ag.GridApi, targetId: string) => void;
export type GetDropEffect<T> = (data: T, api: Ag.GridApi, targetId: string) => DropEffectValues;
export type DropEffectValues = 'move' | 'none' | 'link' | 'copy';

export class AgGridDataTransferImporter<T> {
  private prevTargetId: string;
  private transferKey: string;
  private onImportCallback: OnImportCallback<T>;
  private getDragEffect: GetDropEffect<T>;

  constructor(transferKey: string, onImport: OnImportCallback<T>, getDragEffect: GetDropEffect<T>) {
    this.transferKey = transferKey;
    this.onImportCallback = onImport;
    this.getDragEffect = getDragEffect;
  }

  public onImport(event: React.DragEvent, api: Ag.GridApi): void {
    const data = this.getImportData();
    this.onImportCallback(data, api, this.getTargetId(event));
    AgGridDataTransferStorage.remove(this.transferKey);
    event.preventDefault();
  }

  public setDropEffect(event: React.DragEvent, api: Ag.GridApi): void {
    if (AgGridDataTransferStorage.contain(this.transferKey)) {
      const data = AgGridDataTransferStorage.get(this.transferKey);
      const targetId = this.getTargetId(event);
      if (targetId !== this.prevTargetId) {
        event.dataTransfer.dropEffect = this.getDragEffect(data, api, targetId);
      }
    } else {
      event.dataTransfer.dropEffect = 'none';
    }

    event.preventDefault();
  }

  public resetDropEffect(): void {
    this.prevTargetId = undefined;
  }

  public unBlock(): void {
    AgGridDataTransferStorage.unBlock(this.transferKey);
  }

  private getImportData(): T {
    return AgGridDataTransferStorage.get(this.transferKey);
  }

  private getTargetId(event: React.DragEvent): string {
    const rowDiv = (event.target as HTMLElement).closest('div[role="row"]');

    return rowDiv
      ? rowDiv.getAttribute('row-id')
      : null;
  }
}
