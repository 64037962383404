import Ag from 'ag-grid-community';
import ReactDOMServer from 'react-dom/server';
import { getPlusButtonElement } from 'unit-2d-database/components/cell-renderer-plus-button';

export class PlusButtonCell implements Ag.ICellRendererComp {
  private eGui: HTMLElement;

  public refresh(): boolean {
    return false;
  }

  public getGui(): HTMLElement {
    this.eGui = document.createElement('div');
    this.addInfoToGui(this.eGui);
    return this.eGui;
  }

  public addInfoToGui(gui: HTMLElement): void {
    gui.innerHTML = ReactDOMServer.renderToString(getPlusButtonElement());
  }
}
