import { Avatar, IconButton, Icons, ElementTooltip, TinyText, Text } from '@kreo/kreo-ui-components';
import { isBoolean } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';

import { RenderIf } from 'common/components/render-if';
import { DateFormatConstants } from 'common/constants/date-formats';
import { PersonUtils } from 'common/utils/person-utils';
import { CommentaryTargetType } from 'unit-2d-comments/enums';
import { CommentaryUserInfo } from 'unit-2d-comments/interfaces';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { ConfirmationButtonsPanel } from '../../../../../common/components/button-sets/confirmation-buttons';
import { CommentMenu } from '../comment-menu';
import { CommentTextArea } from '../comment-text-area';
import { CommentTextWithMention } from '../comment-text-with-mention';
import { useInputText } from '../hooks/use-input-text';
import { Styled } from './styled';

interface Props {
  user: CommentaryUserInfo;
  isOwner: boolean;
  lastActionDate: string;
  text: string;
  resolved: boolean;
  resolveDiff?: boolean;
  edited?: boolean;
  isEdit: boolean;
  targetType?: CommentaryTargetType;
  onResolve?: () => void;
  onRemove: () => void;
  onStartEdit: () => void;
  onStopEdit: () => void;
  onEdit: (text: string) => void;
}

const ContentComponent: React.FC<Props> = ({
  user,
  text,
  onRemove,
  onEdit,
  onStartEdit,
  onStopEdit,
  onResolve,
  lastActionDate,
  resolveDiff,
  isOwner,
  edited,
  isEdit,
  resolved,
  targetType,
}) => {
  const { sendEvent } = useAnalytics();
  const { onEdit: onChangeText, isValueValid, value, cancelEdit } = useInputText(text);
  const startEdit = useCallback(() => onStartEdit(), []);
  const stopEdit = useCallback(() => {
    onStopEdit();
    cancelEdit();
  }, [cancelEdit]);

  const saveEditValueCallback = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => onChangeText(e.target.value),
    []);

  const saveChanges = useCallback(() => {
    if (!isValueValid) {
      return;
    }
    if (text !== value) {
      onEdit(value);
    }
    onStopEdit();
  }, [text, value, onChangeText]);

  const resolveCallback = useCallback(() => {
    onResolve();
    sendEvent(MetricNames.comments.resolveThread, { target: targetType });
  }, []);

  const reopenCallback = useCallback(() => {
    onResolve();
    sendEvent(MetricNames.comments.reopenThread, { target: targetType });
  }, []);

  const onBlurCallback = useCallback((e: React.FocusEvent<HTMLTextAreaElement>) => {
    onChangeText(e?.target.value);
  }, []);

  useEffect(() => {
    if (!isEdit) {
      cancelEdit();
    }
  }, [isEdit]);

  const { userName, avatarLink } = PersonUtils.getPersonVisualData(user);
  const isUsualComment = !isBoolean(resolveDiff);

  const textToShow = isUsualComment ? text : resolveDiff ? 'Marked as Resolved' : 'Marked as Opened';

  return (
    <Styled.Container>
      <Avatar
        name={userName}
        size={30}
        avatar={avatarLink}
      />
      <Styled.Content>
        <Styled.Header>
          <Styled.CreationInfo isApply={!!onResolve} isMenu={isUsualComment}>
            <TinyText withEllipsis={true}>{userName}</TinyText>
            <Text color={'gray'} fontSize={10}>
              {moment(lastActionDate).format(DateFormatConstants.FULL_DATE_TIME_FORMAT)}
              {edited ? ' (edited)' : ''}
            </Text>
          </Styled.CreationInfo>
          <RenderIf condition={isOwner}>
            <Styled.Menus>
              <RenderIf condition={!!onResolve}>
                {resolved ? (
                  <ElementTooltip
                    text={'Open'}
                    position='top'
                    speed='l'
                  >
                    <Styled.ReOpenButton>
                      <IconButton
                        Icon={Icons.ReOpen2D}
                        onClick={reopenCallback}
                        height={20}
                        width={20}
                      />
                    </Styled.ReOpenButton>
                  </ElementTooltip>
                ) : (
                  <ElementTooltip
                    text={'Resolve'}
                    position='top'
                    speed='l'
                  >
                    <Styled.ApplySButton>
                      <IconButton
                        Icon={Icons.ApplyS}
                        onClick={resolveCallback}
                        defaultColor={'green'}
                        iconHeight={10}
                        height={20}
                        width={20}
                      />
                    </Styled.ApplySButton>
                  </ElementTooltip>
                )}
              </RenderIf>
              <RenderIf condition={isUsualComment}>
                <CommentMenu onEdit={startEdit} onRemove={onRemove} />
              </RenderIf>
            </Styled.Menus>
          </RenderIf>
        </Styled.Header>
        {isEdit ? (
          <Styled.EditContainer>
            <CommentTextArea
              onChange={saveEditValueCallback}
              value={value}
              autofocus={true}
              onFinish={saveChanges}
              onBlur={onBlurCallback}
              resolved={resolved}
            />
            <ConfirmationButtonsPanel
              applyText='Save'
              cancel={stopEdit}
              apply={saveChanges}
              disabled={!isValueValid}
            />
          </Styled.EditContainer>
        ) : (
          <CommentTextWithMention text={textToShow} />
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export const Content = React.memo(ContentComponent);
