import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { LoadersList, ProgressBarState, ProgressState } from 'common/components/progress';
import { State } from 'common/interfaces/state';
import { numberUtils } from 'common/utils/number-utils';
import { ProgressUtils } from 'common/utils/progress-utils';
import { useCurrentProject } from 'unit-projects/hooks';
import { Styled } from './styled';

const MB = 1024 * 1024;
const KB = 1024;

const ExportProgressComponent: React.FC = () => {
  const currentProjectId = useCurrentProject().id;

  const getCustomText = useCallback((progressBar: ProgressBarState) => {
    const finished = progressBar.finished < MB
      ? `${numberUtils.round(progressBar.finished / KB, 2)} KB`
      : `${numberUtils.round(progressBar.finished / MB, 2)} MB`;
    const total = progressBar.total < MB
      ? `${numberUtils.round(progressBar.total / KB, 2)} KB`
      : `${numberUtils.round(progressBar.total / MB, 2)} MB`;
    return `${finished} / ${total}`;
  }, []);
  const key = ProgressUtils.createCurrentProjectExportKey(currentProjectId);
  const progress = useSelector<State, ProgressState>(x => x.progress[key]);
  if (!progress) {
    return null;
  }

  return (
    <Styled.ExportProgress>
      <LoadersList
        progressKey={key}
        customProgressTextGetter={getCustomText}
      />
    </Styled.ExportProgress>
  );
};

export const ExportProgress = React.memo(ExportProgressComponent);
