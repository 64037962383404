import { WizzardErrorTypes } from '../enums';
import { WizzardStatus } from '../enums/dropper-state';
import { PdfGeometry, PdfGeometryResults } from './api-responses/pdf-geometry-response';
import { ShortPointDescription } from './drawing-ai-annotation';
import { DrawingsSelectAggregationGroup } from './drawings-geometry';

export interface FinderSelectedVisiblity {
  area: boolean;
  line: boolean;
}

export enum AiRequestQuality {
  Auto = 'Auto',
  Big = 'Big Areas',
  Small = 'Small Areas',
}

export interface WizzardToolsState {
  connect: boolean;
  enclose: boolean;
  wizzardState: { status: WizzardStatus, foundedItemsCount?: number };
  dropperInstanceType: DrawingsSelectAggregationGroup;
  dropperSameGeometry: boolean;
  searchArea: ShortPointDescription[];
  allowRotation: boolean;
  allowFlipping: boolean;
  finderSelectionArea: ShortPointDescription[];
  finderSelectedGeometries: PdfGeometry[];
  finderSelectedVisibility: { area: boolean, line: boolean };
  selectedToRemove: number[];
  findText: boolean;
  result: PdfGeometryResults;
  isCount: boolean;
  error: WizzardErrorTypes;
  dropperSimilarity: number;
  finderSimilarity: number;
  boostWithAi: boolean;
  finderResultToHide: Record<number, boolean>;
  quality: AiRequestQuality;
}
