import { Avatar } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { ConfirmationButtonsPanel } from 'common/components/button-sets/confirmation-buttons';
import { PersonUtils } from 'common/utils/person-utils';
import { CommentaryUserInfo } from 'unit-2d-comments/interfaces';
import { CommentTextArea } from '../comment-text-area';
import { useInputText } from '../hooks/use-input-text';
import { Styled } from './styled';

interface Props {
  currentUser: CommentaryUserInfo;
  onAddSubComment: (text: string) => void;
  placeholder: string;
  resolved: boolean;
}

const SubCommentCreatorComponent: React.FC<Props> = ({
  currentUser,
  onAddSubComment,
  placeholder,
  resolved,
}) => {
  const { value, isValueValid, onEdit } = useInputText('');
  const onAddSubCommentCallback = useCallback(
    () => {
      if (isValueValid) {
        onAddSubComment(value);
        onEdit('');
      }
    },
    [value, onAddSubComment, isValueValid],
  );

  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onEdit(e.target.value);
    },
    [],
  );

  const cancel = useCallback(() => onEdit(''), []);
  const { userName, avatarLink } = PersonUtils.getPersonVisualData(currentUser);
  return (
    <Styled.Container showButtons={!!value.trim()}>
      <Avatar
        name={userName}
        size={30}
        avatar={avatarLink}
      />
      <Styled.Input>
        <CommentTextArea
          onChange={onChangeValue}
          value={value}
          onFinish={onAddSubCommentCallback}
          placeholder={placeholder}
          resolved={resolved}
        />
        {
          value.trim() ? (
            <ConfirmationButtonsPanel
              applyText='Reply'
              apply={onAddSubCommentCallback}
              cancel={cancel}
              disabled={!isValueValid}
            />
          ) : null
        }
      </Styled.Input>
    </Styled.Container>
  );
};

export const SubCommentCreator = React.memo(SubCommentCreatorComponent);
