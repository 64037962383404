import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { NumberDictionary } from 'common/interfaces/dictionary';
import { State } from 'common/interfaces/state';
import { EnhancedVirtualList } from 'common/UIKit';
import { KreoScrollbarsApi } from 'common/UIKit/scrollbars/kreo-scrollbars';
import { arrayUtils } from 'common/utils/array-utils';
import { CEMeasurementsExtractorEditorVisualRow } from 'unit-cost-estimate/interfaces';
import {
  withMeasurementEngineLayoutContext,
} from 'unit-projects/components/with-measurement-engine-layout-context';
import {
  MeasurementsExtractorEditorRowType,
} from 'unit-projects/enums/measurements-extractor-editor-row-type';
import {
  WithMeasurementsEngineContextAwareProps,
} from 'unit-projects/interfaces/measurements/with-measurements-engine-context-aware-props';
import { MeasurementsExtractorEditorGroup } from './measurements-extractor-editor-group';
import { MeasurementsExtractorItem } from './measurements-extractor-item';
import { MeasurementsExtractorLayoutContainer } from './measurements-extractor-layout-container';
import { MeasurementsGeneralInput } from './measurements-general-input';


interface OwnProps {
  disabled?: boolean;
}

interface StateProps {
  rows: CEMeasurementsExtractorEditorVisualRow[];
  elementsSelectStatuses: NumberDictionary<boolean>;
  showGroups: boolean;
  centralEditorRowPosition: number;
}

interface Props extends OwnProps, StateProps, WithMeasurementsEngineContextAwareProps {}


class MeasurementsExtractorLayoutComponent extends React.PureComponent<Props> {
  private readonly itemHeight: number = 36;
  private scrollbarsApi: KreoScrollbarsApi = null;
  private virtualListRef: EnhancedVirtualList<CEMeasurementsExtractorEditorVisualRow>;

  public render(): React.ReactNode {
    const { disabled, showGroups, rows } = this.props;
    const elements = showGroups ? rows : rows.filter(x => x.type !== MeasurementsExtractorEditorRowType.GroupHeader);
    return (
      <MeasurementsExtractorLayoutContainer cancelButtonEnabled={!disabled}>
        <MeasurementsGeneralInput disabledEdit={disabled} />
        <EnhancedVirtualList<CEMeasurementsExtractorEditorVisualRow>
          renderedItemsCount={30}
          ref={this.saveVirtualListRef}
          objects={elements}
          itemHeight={this.itemHeight}
          renderItem={this.renderItem}
          sendScrollBarApi={this.saveScrollbarsApi}
        />
      </MeasurementsExtractorLayoutContainer>
    );
  }

  public componentDidMount(): void {
    if (Number.isInteger(this.props.centralEditorRowPosition)) {
      this.scrollToCentralRow();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    const positionHasBeenChanged = this.props.centralEditorRowPosition !== prevProps.centralEditorRowPosition;
    if (positionHasBeenChanged && Number.isInteger(this.props.centralEditorRowPosition)) {
      this.scrollToCentralRow();
    }
  }

  private scrollToCentralRow(): void {
    const containerHeight = this.virtualListRef.getMainContainer().getBoundingClientRect().height;
    const centerElement = this.props.centralEditorRowPosition;
    const topPosition = this.itemHeight * centerElement - (this.itemHeight + containerHeight) / 2;
    this.scrollbarsApi.setScrollTop(topPosition);
  }

  @autobind
  private saveVirtualListRef(ref: EnhancedVirtualList<CEMeasurementsExtractorEditorVisualRow>): void {
    this.virtualListRef = ref;
  }

  @autobind
  private saveScrollbarsApi(api: KreoScrollbarsApi): void {
    this.scrollbarsApi = api;
  }

  @autobind
  private renderItem(item: CEMeasurementsExtractorEditorVisualRow): React.ReactNode {
    if (item.type === MeasurementsExtractorEditorRowType.Activity) {
      const { data: { values, engineId, isError, isManualChanged }, index, name, leafNodeId } = item;
      const selected = this.props.elementsSelectStatuses[index];
      return (
        <MeasurementsExtractorItem
          key={leafNodeId || 'undefined'}
          itemIndex={index}
          isDisabled={this.props.disabled}
          isSelected={selected}
          name={name}
          values={values}
          engineId={engineId}
          isError={isError}
          isManualChanged={isManualChanged}
        />
      );
    } else {
      const { index, name } = item;
      return (
        <MeasurementsExtractorEditorGroup
          isDisabled={this.props.disabled}
          name={name}
          index={index}
          onCheck={this.onGroupCheck}
        />
      );
    }
  }

  @autobind
  private onGroupCheck(index: number, value: boolean): void {
    const { rows } = this.props;
    let engineIds = new Array<number>();
    for (let i = index + 1; i < rows.length; i++) {
      const row = rows[i];
      if (row.type === MeasurementsExtractorEditorRowType.GroupHeader) {
        break;
      }
      engineIds.push(row.data.engineId);
    }
    const selected = this.props.getSelected();
    if (value) {
      arrayUtils.extendArray(engineIds, selected);
    } else {
      engineIds = selected.filter(x => !engineIds.includes(x));
    }
    this.props.onSelectElements(engineIds);
  }
}


function mapStateToProps({ ceMeasurements }: State): StateProps {
  return {
    rows: ceMeasurements.extractorEditorRows,
    elementsSelectStatuses: ceMeasurements.elementSelectStatuses,
    showGroups: ceMeasurements.areLevelsShown,
    centralEditorRowPosition: ceMeasurements.centralEditorRowPosition,
  };
}

const connector = connect(mapStateToProps);
export const MeasurementsExtractorLayout =
  withMeasurementEngineLayoutContext(connector(MeasurementsExtractorLayoutComponent));
