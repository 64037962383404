import * as React from 'react';
import { PageFiltersSet } from '../../drawings-pdf-browser-filter';
import { Item } from './item';
import { Styled } from './styled';

interface Props {
  filterCategoryName: string;
  filterSet: PageFiltersSet;
  selectedFilter: string;
  onFilterClick: (filterType: string, element: string) => void;
}

export const Menu: React.FC<Props> = ({ filterSet, filterCategoryName, selectedFilter, onFilterClick }) => {
  return (
    <Styled.Container key={filterSet.name}>
      <Styled.Title color={'turquoiseFont'}>{filterSet.name}:</Styled.Title>
      {
        filterSet.elements.map(optionFilter => (
          <Item
            key={optionFilter.name}
            filterCategoryName={filterCategoryName}
            selected={optionFilter.type === selectedFilter}
            filter={optionFilter}
            onClick={onFilterClick}
          />
        ))
      }
    </Styled.Container>
  );
};

