import { Text } from '@kreo/kreo-ui-components';
import Rive from '@rive-app/react-canvas';
import React from 'react';

import { RenderIf } from 'common/components/render-if';
import { Styled } from './styled';

interface Props {
  description: string;
  riveLink: string;
  animation: string;
}

const RiveTutorialTooltipComponent: React.FC<Props> = (
  {
    description,
    riveLink,
    animation,
  },
) => {
  return (
    <>
      <Styled.IframeContainer>
        <Rive
          src={riveLink}
          animations={decodeURIComponent(animation)}
          artboard={decodeURIComponent(animation)}
        />
      </Styled.IframeContainer>
      <RenderIf condition={!!description}>
        <Styled.TutorialDescription>
          <Text fontSize={12} lineHeight={18}>
            {description}
          </Text>
        </Styled.TutorialDescription>
      </RenderIf>
    </>
  );
};

export const TutorialTooltip = React.memo(RiveTutorialTooltipComponent);
