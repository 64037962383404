export enum MaterialComponentType {
  Native = 'native',
  Standard =  'standard',
}

export interface AdornmentProps {
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
}

export interface MaterialComponentProps<TValue> {
  id?: string;
  name?: string;
  value?: TValue;
  label?: string;
  error?: string;
  hint?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  displayedType?: MaterialComponentType;
  isFloatingLabel?: boolean;
  displayUnderline?: boolean;
  autocomplete?: string;
  title?: string;
  /**
   * @description Hide/display hint/error text
   * @default false
   */
  displayBottomInformation?: boolean;
  adornment?: React.ReactNode;
  adornmentPosition?: 'start' | 'end';
}
