import styled from 'styled-components';

const MenuContainer = styled.div<{ top: number, left: number }>`
  position: fixed;
  top: ${p => p.top}px;
  left: ${p => p.left}px;
  z-index: 1000;
`;

export const Styled = {
  MenuContainer,
};
