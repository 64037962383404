import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: ${p => p.theme.color.backgroundRush};
  padding-left: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Switcher = styled.div`
  flex-shrink: 0;
  height: 45px;
  padding: 10px 10px 5px;
  box-sizing: border-box;
  min-height: 45px;
`;

export const Styled = {
  Container,
  Switcher,
};

