import { CursorHintType } from './cursor-hint-type';

export const CursorHintByType: Record<CursorHintType, string> = {
  [CursorHintType.Continue]: 'Select the point you want to continue from',
  [CursorHintType.WizzardPolylineInProgress]: 'Searching for segment',
  [CursorHintType.WizzardPolylineNotFound]: 'No segment found, please try click to another place',
  [CursorHintType.NoGeometryFoundByClick]: 'No geometry found, please try click to another place',
  [CursorHintType.BucketFillInProgress]: 'Searching for area',
  [CursorHintType.BucketFillNotFound]: 'No area found, please try click to another place',
  [CursorHintType.NoGeometriesInArea]: 'No geometries in area, please type another area',
  [CursorHintType.ReachedLimitInArea]: 'Reached limit of selected geometries, please try another area',
  [CursorHintType.SelectionAreaCrashed]: 'Something went wrong, please try another area',
  [CursorHintType.BucketAIOutOffArea]: 'To complete current area, please click closer to it',
};
