import * as React from 'react';

import { UndoRedoActionMethods } from 'common/undo-redo/undo-redo-context-provider';
import { AssignedPia, TablePreset } from '../../../../../units/2d/interfaces';
import {
  CreateDrawingGroup,
  CreateDrawingGroupsTree,
  UpdateDrawingGroup,
  UpdateDrawingGroupTree,
} from '../../actions/payloads/drawings-annotation-legend';
import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { DrawingsGeometryGroup } from '../../interfaces/drawings-geometry-group';
import { DrawingsGeometryInstanceWithId } from '../../interfaces/drawings-geometry-instance';
import { DrawingsInstanceMeasureHelper } from '../../utils/measures/get-drawings-instance-measure-helper';

export interface AddInstancesPayload {
  instances: DrawingsGeometryInstanceWithId[];
  points: Record<string, ShortPointDescription>;
  ignoreSaveMeasuresOnCreate?: boolean;
  forceSave?: boolean;
  moveToSelectedGroup?: boolean;
  pia?: Record<string, AssignedPia>;
  initiator?: string;
  analyticsParams?: Record<string, string | number>;
}

export interface GroupPayloadForInstanceCreate {
  selectedGroups?: string[];
  useGroupNameForNewGeometry: boolean;
  groupForInstanceCreation: DrawingsGeometryGroup;
}

export type DrawingsAddInstanceFunctionType = (
  payload: AddInstancesPayload,
  groups?: GroupPayloadForInstanceCreate
) => void;

export interface AddInstancesWithUndoRedoPayload extends AddInstancesPayload {
  groups?: CreateDrawingGroup[];
}

export type DrawingsAddInstanceUndoRedoFunctionType = (payload: AddInstancesWithUndoRedoPayload) => void;

export type DrawingsRemoveInstanceFunctionType = (
  instancesIds: Record<string, string[]>,
  groupIds?: string[],
) => void;

export interface DrawingsElementAndGroupOperationsContextProps {
  duplicateSelectedInstances: () => void;
  addInstancesWithUndo: DrawingsAddInstanceUndoRedoFunctionType;
  addInstances: DrawingsAddInstanceFunctionType;
  removeInstancesWithUndo: DrawingsRemoveInstanceFunctionType;
  removeInstances: DrawingsRemoveInstanceFunctionType;
  onMoveToCell: (instancesIds: string[], type: string) => void;
  onMoveTableToCell: (instancesIds: string[], preset: TablePreset) => void;
  onDynamicMoveToCell: (preset: TablePreset) => void;
  removeSelectedElementsFromGroups: () => void;
  showSelectedInstances: () => void;
  hideSelectedInstances: () => void;
  isolateSelectedInstances: () => void;
  groupMeasurementsWithUndoRedo: () => void;
  createNewGroupsWithUndoRedo: (data: CreateDrawingGroupsTree) => void;
  removeSelectedInstancesHandler: (onlyInstances?: boolean, preRemoveAction?: () => void) => void;
  removeDrawingsFromGroupsWithUndoRedo: (instanceIds: string[]) => void;
  onTraceLink: (instanceId: string) => void;
  canTraceLink: (instanceId: string) => boolean;
  updateDrawingsGroup: (data: UpdateDrawingGroupTree) => void;
  moveToGroupWithUndoRedo: (groupId: string, groupIds: string[], measurementIds: string[], orderIndex?: number) => void;
  getInstanceMeasureHelper: () => DrawingsInstanceMeasureHelper;
  setDrawingsGroups: (
    prevDrawingsGroup: DrawingsGeometryGroup[],
    pinnedGroupIds: string[],
    groupIds: string[],
    measurementIds: string[],
  ) => void;
  onFolderToCell: (type: string) => void;
  changeColorUndoRedo: (measurementIds: string[], color: string, group: UpdateDrawingGroup) => UndoRedoActionMethods;
  changeShowInstances: (instancesIds: string[]) => void;
  getGroupPayloadForInstanceCreate: () => GroupPayloadForInstanceCreate;
}

export const DrawingsElementAndGroupOperationsContext =
  React.createContext<DrawingsElementAndGroupOperationsContextProps>(null);
