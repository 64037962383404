import styled from 'styled-components';


const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;
  a {
    color: inherit;
    text-decoration: none;
  }
  &, * {
    box-sizing: border-box;
    outline: none;
  }
`;

const Container = styled.div<{ error: boolean, isInaccessibleProject: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 60px;
  width: 100%;
  box-shadow: 0 0 0 1px ${p => p.theme.color.background};
  background: ${p => p.theme.color.backgroundRush} 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: ${p => p.isInaccessibleProject ? 0.5 : 1};

  &:hover {
    box-shadow: 0 0 0 1px ${p => p.error ? p.theme.color.background : p.theme.color.turquoise};
  }
`;

const RowContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr auto 30px;
  grid-auto-flow: row;
  align-content: center;
  align-items: center;
  gap: 20px;
  transition: all 175ms linear;
  padding: 10px 20px 10px 10px;
`;

export const Styled = {
  Wrapper,
  Container,
  RowContainer,
};
