import { CommonApi, CompanyResourcesApi } from '../../../api/server';
import { ExtractorFunctionModel, MeasurementType, MeasurementTypeModel, UnitModel } from '../interfaces/data';


function getUnits(databaseId: number): Promise<UnitModel[]> {
  return CompanyResourcesApi.get(
    `/databases/${databaseId}/units`,
    `api get units in database(${databaseId}) error`,
  );
}

function getMeasurements(): Promise<ExtractorFunctionModel[]> {
  return CommonApi.get(
    `/cmtdb/measurements`,
    `api get measurements functions error`,
  );
}

function getMeasurementTypes(): Promise<Record<MeasurementType, MeasurementTypeModel>> {
  return CommonApi.get(
    `/cmtdb/measurement-types`,
    `api get measurement types functions error`,
  );
}

function getExtractorFunctions(): Promise<ExtractorFunctionModel[]> {
  return CommonApi.get(
    `/cmtdb/extractor-functions`,
    `api get extractor functions error`,
  );
}

export const UnitsApi = {
  getUnits,
  getMeasurements,
  getMeasurementTypes,
  getExtractorFunctions,
};
