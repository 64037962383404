import { MovableContextMenu } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { FilterSet } from '../drawings-pdf-browser-filter';
import { filtersSet } from '../pdf-filters';
import { Menu } from './menu';
import { Styled } from './styled';

interface Props {
  x: number;
  y: number;
  onClose: () => void;
  selected: string;
  handleFilterChange: (filterType: string, element: string) => void;
}

export class DrawingsPdfBrowserFilterMenuComponent extends React.PureComponent<Props> {

  public render(): React.ReactNode {
    const { x, y, onClose } = this.props;

    return (
      <MovableContextMenu
        x={x}
        y={y}
        onClose={onClose}
        zIndex={1003}
      >
        <Styled.Container>
          {filtersSet.map(this.renderMenu)}
        </Styled.Container>
      </MovableContextMenu>
    );
  }

  @autobind
  private renderMenu(set: FilterSet): React.ReactNode {
    return Object.entries(set).map(([filterName, value]) => (
      <Menu
        key={filterName}
        filterCategoryName={filterName}
        filterSet={value}
        selectedFilter={this.props.selected}
        onFilterClick={this.props.handleFilterChange}
      />
    ));
  }
}

export const DrawingsPdfBrowserFilterMenu = DrawingsPdfBrowserFilterMenuComponent;
