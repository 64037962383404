import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionWith } from 'common/interfaces/action-with';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { StringUtils } from 'common/utils/string-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { TwoDDatabaseApi } from '../api';
import { Constants } from '../constants';
import {
  CreatePropertyPayload,
  Group,
  Property,
  Item,
  UpdateGroupNamePayload,
  UpdateItemRequest,
  UpdateParentPayload,
  CreateAssemblyPayload,
  UpdateAssemblyPayload,
  Assembly,
  UpdateItemRequestPayload,
  ResetAssemblyOverridePayload,
  ItemOverride,
} from '../interfaces';
import { TwoDDatabaseActions } from '../store-slice';
import { Selectors } from './selectors';

function* fetchDatabase({ payload }: ActionWith<string>): SagaIterator {
  try {
    const data = yield call(TwoDDatabaseApi.fetchDataBase);
    yield put(TwoDDatabaseActions.setDatabase(data));
    yield put(TwoDDatabaseActions.updateItemOverride());
  } catch (e) {
    console.error('2d database cannot fetch database: ', payload, e);
  }
}

function* createItemGroup(): SagaIterator {
  try {
    const itemsGroups: Group[] = yield selectWrapper(Selectors.selectItemGroups);
    const newGroup = {
      id: UuidUtil.generateUuid(),
      name: `New Group ${itemsGroups.length}`,
    };
    yield call(TwoDDatabaseApi.createItemGroup, newGroup);
    yield put(TwoDDatabaseActions.createItemGroup(newGroup));
  } catch (e) {
    console.error('2d database cannot create item', e);
  }
}

function* deleteItemGroup({ payload }: ActionWith<string>): SagaIterator {
  try {
    yield call(TwoDDatabaseApi.deleteItemGroup, [payload]);
    yield put(TwoDDatabaseActions.deleteItemGroup(payload));
  } catch (e) {
    console.error(e);
  }
}

function* applyItemGroupUpdate(group: Group): SagaIterator {
  yield call(TwoDDatabaseApi.updateItemGroup, group);
  yield put(TwoDDatabaseActions.updateItemGroup(group));
}

function* updateItemGroupName({ payload }: ActionWith<UpdateGroupNamePayload>): SagaIterator {
  try {
    const itemsGroups: Group[] = yield select(Selectors.selectItemGroups);
    const group = itemsGroups.find(g => g.id === payload.id);
    if (group) {
      yield call(applyItemGroupUpdate, { ...group, name: payload.name });
    }
  } catch (e) {
    console.error(e);
  }
}

function* updateItemGroupParent({ payload }: ActionWith<UpdateParentPayload>): SagaIterator {
  try {
    const groups: Map<string, Group> = yield select(Selectors.selectItemsGroupsMap);
    const group = groups.get(payload.sourceId);
    if (!payload.targetId) {
      yield call(applyItemGroupUpdate, { ...group, parentFolderId: undefined });
    }
    if (!containsGroup(groups, payload.targetId, payload.sourceId)) {
      yield call(applyItemGroupUpdate, { ...group, parentFolderId: payload.targetId });
    } else {
      yield put(TwoDDatabaseActions.updateItemGroup({ ...group }));
    }
  } catch (e) {
    console.error(e);
  }
}

function* createProperty({ payload }: ActionWith<CreatePropertyPayload>): SagaIterator {
  try {
    const { userId, appliedAt } = yield call(TwoDDatabaseApi.createProperty, { ...payload });
    const property: Property = {
      ...payload,
      createdAt: appliedAt,
      creatorId: userId,
    };
    yield put(TwoDDatabaseActions.createProperty(property));
  } catch (e) {
    console.error(e);
  }
}

function* duplicateProperty({ payload }: ActionWith<string>): SagaIterator {
  try {
    const properties: Property[] = yield select(Selectors.selectProperties);
    const property = properties.find(x => x.id === payload);
    if (property) {
      yield put(TwoDDatabaseActions.createPropertyRequest({
        id: UuidUtil.generateUuid(),
        name: StringUtils.iterateName(properties.map(x => x.name), property.name),
        groupName: property.groupName,
        value: property.value,
      }));
    }
  } catch (e) {
    console.error(e);
  }
}

function* updateProperty({ payload }: ActionWith<Property>): SagaIterator {
  try {
    const { userId, appliedAt } = yield call(TwoDDatabaseApi.updateProperty, payload);
    payload.editorId = userId;
    payload.editedAt = appliedAt;
    yield put(TwoDDatabaseActions.updateProperty(payload));
  } catch (e) {
    console.error(e);
  }
}

function* deleteProperty({ payload }: ActionWith<string[]>): SagaIterator {
  try {
    yield call(TwoDDatabaseApi.deleteProperties, payload);
    yield put(TwoDDatabaseActions.deleteProperties(payload));
  } catch (e) {
    console.error(e);
  }
}

function* createItem({ payload }: ActionWith<{
  properties: Property[],
  name: string,
  iconType: string,
}>): SagaIterator {
  try {
    const id = UuidUtil.generateUuid();
    const {
      userId,
      appliedAt,
    } = yield call(
      TwoDDatabaseApi.createItem,
      {
        name: payload.name,
        id,
        properties: payload.properties,
        iconType: payload.iconType,
      },
    );
    const item: Item = {
      ...payload,
      id,
      createdAt: appliedAt,
      creatorId: userId,
    };
    yield put(TwoDDatabaseActions.createItem(item));
  } catch (e) {
    console.error(e);
  }
}

function* deleteItem({ payload }: ActionWith<string>): SagaIterator {
  try {
    yield call(TwoDDatabaseApi.deleteItems, [payload]);
    yield put(TwoDDatabaseActions.deleteItem(payload));
  } catch (e) {
    console.error(e);
  }
}

function duplicateProperties(properties: Property[]): Property[] {
  return properties.map(property => ({ ...property, id: UuidUtil.generateUuid() }));
}

function* duplicateItem({ payload }: ActionWith<string>): SagaIterator {
  try {
    const items: Item[] = yield select(Selectors.selectItems);
    const item = items.find(i => i.id === payload);
    if (item) {
      yield put(TwoDDatabaseActions.createItemRequest({
        name: StringUtils.iterateName(items.map(i => i.name), item.name),
        properties: duplicateProperties(item.properties),
        iconType: item.iconType,
      }));
    }
  } catch (e) {
    console.error(e);
  }
}

function* applyUpdateItem(item: Item, payload: UpdateItemRequestPayload): SagaIterator {
  const { userId, appliedAt } = yield call(TwoDDatabaseApi.updateItem, payload);
  item.editorId = userId;
  item.editedAt = appliedAt;
  yield put(TwoDDatabaseActions.updateItem(item));
}

function* updateItem({ payload }: ActionWith<UpdateItemRequest>): SagaIterator {
  try {
    const { id, addedProperties, deletedProperties, updatedProperties, name, iconType } = payload;
    const items: Item[] = yield select(Selectors.selectItems);
    const item = items.find(i => i.id === id);
    if (item) {
      const itemProperties = arrayUtils.toDictionary(item.properties, i => i.id, i => i);
      for (const property of updatedProperties) {
        itemProperties[property.id] = property;
      }
      for (const propId of deletedProperties) {
        delete itemProperties[propId];
      }
      const properties = Object.values(itemProperties);
      arrayUtils.extendArray(properties, addedProperties);
      const updatedItem: Item = {
        ...item,
        properties,
        name,
        iconType,
      };
      yield call(applyUpdateItem, updatedItem, { ...payload, folderId: item.folderId });
      if (item.name !== updatedItem.name || item.iconType !== updatedItem.iconType) {
        yield call(updateAssembliesOverrideItem, updatedItem);
      }
    }
  } catch (e) {
    console.error(e);
  }
}

function* updateAssembliesOverrideItem(updatedItem: Item): SagaIterator {
  const assemblies: Assembly[] = yield select(Selectors.selectAssemblies);
  const updateAssemblyPayloads = new Array<UpdateAssemblyPayload>();
  const updatedAssemblies = new Array<Assembly>();
  for (const assembly of assemblies) {
    const itemIndex = assembly.items.findIndex(x => x.baseItemId === updatedItem.id);
    if (itemIndex !== -1) {
      const items = assembly.items.slice();
      const item = items[itemIndex];
      updateAssemblyPayloads.push({
        name: assembly.name,
        id: assembly.id,
        folderId: assembly.folderId,
        addedItems: [],
        deletedItems: [],
        updatedItems: [{
          id: item.id,
          name: updatedItem.name,
          iconType: updatedItem.iconType,
          baseItemId: item.baseItemId,
          addedProperties: [],
          deletedProperties: [],
          updatedProperties: [],
        }],
      });

      items[itemIndex] = { ...item, ...updatedItem, properties: item.properties, id: item.id };
      updatedAssemblies.push({ ...assembly, items });
    }
  }
  if (updatedAssemblies.length) {
    yield call(applyAssemblyChanges, updatedAssemblies, updateAssemblyPayloads);
  }
}

function* sendUpdateItem({ payload }: ActionWith<UpdateItemRequest>): SagaIterator {
  const override: Record<string, ItemOverride[]> = yield select(Selectors.selectItemOverride);
  const resetAssemblyPayload = getResetAssemblyPayload(payload, override[payload.id]);
  if (resetAssemblyPayload.length) {
    yield put(KreoDialogActions.openDialog(Constants.RESET_ASSEMBLY_OVERRIDE_DIALOG, [payload, resetAssemblyPayload]));
  } else {
    yield put(TwoDDatabaseActions.updateItemRequest(payload));
  }
}

function getResetAssemblyPayload(item: UpdateItemRequest, overrides: ItemOverride[]): ResetAssemblyOverridePayload[] {
  const payload: ResetAssemblyOverridePayload[] = [];
  if (overrides && overrides.length) {
    const updatePropertyNames = getUpdatePropertyNames(item);

    overrides.forEach(override => {
      const propertyIds = arrayUtils.filterMap(
        override.item.properties,
        p => updatePropertyNames.has(p.name),
        p => p.id,
      );
      if (propertyIds.length) {
        payload.push({
          assemblyId: override.assembly.id,
          assemblyName: override.assembly.name,
          itemId: override.item.id,
          itemName: override.item.name,
          itemIcon: override.item.iconType,
          propertyIds,
          folderId: override.assembly.folderId,
        });
      }
    });
  }

  return payload;
}

function getUpdatePropertyNames(item: UpdateItemRequest): Set<string> {
  const updatePropertyNames = new Set(item.addedProperties.map(p => p.name));
  item.deletedProperties.forEach(p => {
    updatePropertyNames.add(p);
  });
  item.updatedProperties.forEach(p => {
    updatePropertyNames.add(p.name);
  });
  return updatePropertyNames;
}

function* sendResetAssemblyOverride({ payload }: ActionWith<ResetAssemblyOverridePayload[]>): SagaIterator {
  try {
    const updatePayload: UpdateAssemblyPayload[] = payload.map(p => ({
      id: p.assemblyId,
      name: p.assemblyName,
      addedItems: [],
      deletedItems: [],
      updatedItems: [
        {
          id: p.itemId,
          name: p.itemName,
          iconType: p.itemIcon,
          deletedProperties: p.propertyIds,
          addedProperties: [],
          updatedProperties: [],
        },
      ],
      folderId: p.folderId,
    }));
    yield call(TwoDDatabaseApi.updateAssemblies, updatePayload);
  } catch (e) {
    console.error(`TwoD data base: Failed to reset assembly override`);
  }
}

function* updateItemParent({ payload }: ActionWith<UpdateParentPayload>): SagaIterator {
  try {
    const { sourceId, targetId } = payload;
    const items: Item[] = yield select(Selectors.selectItems);
    const item = items.find(i => i.id === sourceId);
    if (item) {
      const updatedItem: Item = {
        ...item,
        folderId: targetId,
      };
      yield call(
        applyUpdateItem,
        updatedItem,
        {
          folderId: targetId,
          id: item.id,
          name: item.name,
          iconType: item.iconType,
          addedProperties: [],
          updatedProperties: [],
          deletedProperties: [],
        });
    }
  } catch (e) {
    console.error(e);
  }
}

function* createAssembly({ payload }: ActionWith<CreateAssemblyPayload>): SagaIterator {
  try {
    const assembly: CreateAssemblyPayload = {
      items: payload.items,
      name: payload.name,
      id: UuidUtil.generateUuid(),
    };
    const { userId, appliedAt } = yield call(TwoDDatabaseApi.createAssembly, assembly);
    yield put(TwoDDatabaseActions.createAssembly(
      {
        items: payload.items,
        name: payload.name,
        id: assembly.id,
        createdAt: appliedAt,
        creatorId: userId,
      },
    ));
  } catch (e) {
    console.error(e);
  }
}

function* duplicateAssembly({ payload }: ActionWith<string>): SagaIterator {
  try {
    const assemblies: Assembly[] = yield select(Selectors.selectAssemblies);
    const assembly = assemblies.find(x => x.id === payload);
    if (assembly) {
      const duplicatedItems = assembly.items.map(item => ({
        ...item,
        id: UuidUtil.generateUuid(),
        properties: duplicateProperties(item.properties),
      }));
      yield put(TwoDDatabaseActions.createAssemblyRequest({
        name: StringUtils.iterateName(assemblies.map(x => x.name), assembly.name),
        items: duplicatedItems,
      }));
    }
  } catch (e) {
    console.error(e);
  }
}

function* applyAssemblyChanges(updatedAssemblies: Assembly[], payload: UpdateAssemblyPayload[]): SagaIterator {
  const { userId, appliedAt } = yield call(TwoDDatabaseApi.updateAssemblies, payload);
  for (const assembly of updatedAssemblies) {
    assembly.editorId = userId;
    assembly.editedAt = appliedAt;
  }
  yield put(TwoDDatabaseActions.updateAssemblies(updatedAssemblies));
}

function* updateAssembly({ payload }: ActionWith<UpdateAssemblyPayload>): SagaIterator {
  try {
    const { id, addedItems, deletedItems, updatedItems, name } = payload;
    const assemblies: Assembly[] = yield select(Selectors.selectAssemblies);
    const assembly = assemblies.find(i => i.id === id);

    const assemblyItems = arrayUtils.toDictionary(assembly.items, x => x.id, x => x);
    for (const updatedItem of updatedItems) {
      const { id: itemId, name: itemName, addedProperties, deletedProperties, updatedProperties } = updatedItem;
      const item = assemblyItems[itemId];
      const itemProperties = arrayUtils.toDictionary(item.properties, p => p.id, p => p);
      for (const updatedProperty of updatedProperties) {
        itemProperties[updatedProperty.id] = updatedProperty;
      }
      for (const deletedPropertyId of deletedProperties) {
        delete itemProperties[deletedPropertyId];
      }

      const properties = Object.values(itemProperties);
      arrayUtils.extendArray(properties, addedProperties);
      assemblyItems[itemId] = { ...item, properties, name: itemName };
    }

    for (const deletedItemId of deletedItems) {
      delete assemblyItems[deletedItemId];
    }
    const items = Object.values(assemblyItems);
    arrayUtils.extendArray(items, addedItems);
    const updatedAssembly: UpdateAssemblyPayload = {
      ...payload,
      folderId: assembly.folderId,
    };
    yield call(applyAssemblyChanges, [{ ...assembly, items, name }], [updatedAssembly]);
  } catch (e) {
    console.error(e);
  }
}

function* updateAssemblyParent({ payload }: ActionWith<UpdateParentPayload>): SagaIterator {
  try {
    const { sourceId, targetId: folderId } = payload;
    const assemblies: Assembly[] = yield select(Selectors.selectAssemblies);
    const assembly = assemblies.find(i => i.id === sourceId);
    yield call(
      applyAssemblyChanges,
      [{ ...assembly, folderId }],
      [{ id: assembly.id, folderId, name: assembly.name, addedItems: [], deletedItems: [], updatedItems: [] }]);
  } catch (e) {
    console.error(e);
  }
}

function* deleteAssembly({ payload }: ActionWith<string>): SagaIterator {
  try {
    yield call(TwoDDatabaseApi.deleteAssembly, [payload]);
    yield put(TwoDDatabaseActions.deleteAssembly(payload));
  } catch (e) {
    console.error(e);
  }
}

function* createAssemblyGroup(): SagaIterator {
  try {
    const assemblyGroups: Group[] = yield select(Selectors.selectAssemblyGroups);
    const newGroups = {
      id: UuidUtil.generateUuid(),
      name: `New Group ${assemblyGroups.length}`,
    };
    yield call(TwoDDatabaseApi.createAssemblyGroup, newGroups);
    yield put(TwoDDatabaseActions.createAssemblyGroup(newGroups));
  } catch (e) {
    console.error('2d database cannot create assembly group');
  }
}

function* deleteAssemblyGroup({ payload: groupId }: ActionWith<string>): SagaIterator {
  try {
    yield call(TwoDDatabaseApi.deleteAssemblyGroup, [groupId]);
    yield put(TwoDDatabaseActions.deleteAssemblyGroup(groupId));
  } catch (e) {
    console.error('2d database cannot create assembly group');
  }
}

function* updateAssemblyGroupName({ payload }: ActionWith<UpdateGroupNamePayload>): SagaIterator {
  try {
    const assemblyGroups: Group[] = yield selectWrapper(Selectors.selectAssemblyGroups);
    const group = assemblyGroups.find(g => g.id === payload.id);
    const updatedGroup = { ...group, name: payload.name };
    yield call(applyAssemblyGroupChanges, updatedGroup);
  } catch (e) {
    console.error(e);
  }
}

function* applyAssemblyGroupChanges(updatedGroup: Group): SagaIterator {
  yield call(TwoDDatabaseApi.updateAssemblyGroup, updatedGroup);
  yield put(TwoDDatabaseActions.updateAssemblyGroup(updatedGroup));
}

function* updateAssemblyGroupParent({ payload }: ActionWith<UpdateParentPayload>): SagaIterator {
  try {
    const groups: Map<string, Group> = yield select(Selectors.selectAssemblyGroupsMap);
    const group = groups.get(payload.sourceId);
    if (!payload.targetId) {
      yield call(applyAssemblyGroupChanges, { ...group, parentFolderId: undefined });
    }
    if (!containsGroup(groups, payload.targetId, payload.sourceId)) {
      yield call(applyAssemblyGroupChanges, { ...group, parentFolderId: payload.targetId });
    } else {
      yield put(TwoDDatabaseActions.updateAssemblyGroup({ ...group }));
    }
  } catch (e) {
    console.error(e);
  }
}

function* dumpDatabase(): SagaIterator {
  try {
    yield call(TwoDDatabaseApi.dumpDatabase);
    yield put(TwoDDatabaseActions.setLoadedStatus());
  } catch (e) {
    console.error(e);
  }
}

function containsGroup(map: Map<string, Group>, parentId: string, childId: string): boolean {
  const group = map.get(parentId);
  if (!group?.parentFolderId) {
    return false;
  } else {
    return group.parentFolderId === childId || containsGroup(map, group.parentFolderId, childId);
  }
}

export function* twoDDataBaseSagas(): SagaIterator {
  yield takeLatest(TwoDDatabaseActions.fetchDatabaseRequest.type, fetchDatabase);

  yield takeLatest(TwoDDatabaseActions.createPropertyRequest.type, createProperty);
  yield takeLatest(TwoDDatabaseActions.updatePropertyRequest.type, updateProperty);
  yield takeLatest(TwoDDatabaseActions.deletePropertiesRequest.type, deleteProperty);
  yield takeLatest(TwoDDatabaseActions.duplicateProperty.type, duplicateProperty);

  yield takeLatest(TwoDDatabaseActions.createItemRequest.type, createItem);
  yield takeLatest(TwoDDatabaseActions.deleteItemRequest.type, deleteItem);
  yield takeLatest(TwoDDatabaseActions.duplicateItem.type, duplicateItem);
  yield takeLatest(TwoDDatabaseActions.updateItemRequest.type, updateItem);
  yield takeLatest(TwoDDatabaseActions.updateItemParentRequest.type, updateItemParent);
  yield takeLatest(TwoDDatabaseActions.sendUpdateItem.type, sendUpdateItem);

  yield takeLatest(TwoDDatabaseActions.createItemGroupRequest.type, createItemGroup);
  yield takeLatest(TwoDDatabaseActions.deleteItemGroupRequest.type, deleteItemGroup);
  yield takeLatest(TwoDDatabaseActions.updateItemGroupNameRequest.type, updateItemGroupName);
  yield takeLatest(TwoDDatabaseActions.updateItemGroupParentRequest.type, updateItemGroupParent);

  yield takeLatest(TwoDDatabaseActions.createAssemblyGroupRequest.type, createAssemblyGroup);
  yield takeLatest(TwoDDatabaseActions.deleteAssemblyGroupRequest.type, deleteAssemblyGroup);
  yield takeLatest(TwoDDatabaseActions.updateAssemblyGroupNameRequest.type, updateAssemblyGroupName);
  yield takeLatest(TwoDDatabaseActions.updateAssemblyParentRequest.type, updateAssemblyParent);
  yield takeLatest(TwoDDatabaseActions.resetAssemblyOverride.type, sendResetAssemblyOverride);

  yield takeLatest(TwoDDatabaseActions.updateAssemblyRequest.type, updateAssembly);
  yield takeLatest(TwoDDatabaseActions.deleteAssemblyRequest.type, deleteAssembly);
  yield takeLatest(TwoDDatabaseActions.createAssemblyRequest.type, createAssembly);
  yield takeLatest(TwoDDatabaseActions.duplicateAssembly.type, duplicateAssembly);
  yield takeLatest(TwoDDatabaseActions.updateAssemblyGroupParentRequest.type, updateAssemblyGroupParent);

  yield takeLatest(TwoDDatabaseActions.dumpDatabase.type, dumpDatabase);
}
