import autobind from 'autobind-decorator';
import * as React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

import './doughnut.scss';

import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';

export interface DoughnutItem {
  name: string;
  value: number;
  color: string;
}

interface OwnProps {
  type: any;
  data: DoughnutItem[];
}

interface Props extends OwnProps, I18nAwareProps {}

interface ComponentState {
  activeIndex: number;
}

const PASSIVE_OPACITY = 0.5;
const CURRENCY_FORMAT = '0.[0]a';

class DoughnutComponent extends React.Component<Props, ComponentState> {
  private colors: string[] = this.props.data.map((x) => x.color);
  private totalValue: number = 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      activeIndex: null,
    };

    this.props.data.map((x) => (this.totalValue += x.value));
  }

  public render(): JSX.Element {
    const { data, i18n } = this.props;
    const activeItem =
      this.state.activeIndex < data.length
        ? data[this.state.activeIndex]
        : null;

    return (
      <div className='doughnut-component' onMouseLeave={this.onMouseOutComponent}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              dataKey='value'
              innerRadius='65%'
              outerRadius='95%'
              paddingAngle={0}
              fill='#8884d8'
              onMouseOver={this.onMouseOver}
              onMouseOut={this.onMouseOut}
            >
              {this.colors.map((x, index) => {
                const activeIndex = this.state.activeIndex;
                let opacity = 1;
                if (activeIndex != null) {
                  if (activeIndex !== index) {
                    opacity = PASSIVE_OPACITY;
                  }
                }
                return <Cell key={index} fill={x} opacity={opacity} />;
              })}
            </Pie>
            <Legend content={this.legendRenderer} />
          </PieChart>
        </ResponsiveContainer>
        {activeItem == null ? (
          <div className='doughnut-component__active'>
            <b>{i18n.c(this.totalValue, CURRENCY_FORMAT)}</b>
            Total
          </div>
        ) : (
          <div className='doughnut-component__active'>
            <b>{i18n.c(activeItem.value, CURRENCY_FORMAT)}</b>
            {activeItem.name}
          </div>
        )}
      </div>
    );
  }

  @autobind
  private onMouseOver(_: DoughnutItem, index: number): void {
    this.setState({
      activeIndex: index,
    });
  }

  @autobind
  private onMouseOut(): void {
    this.setState({
      activeIndex: null,
    });
  }

  @autobind
  private onMouseOutComponent(): void {
    this.setState({
      activeIndex: null,
    });
  }

  @autobind
  private legendRenderer(data: any): JSX.Element {
    return (
      <div className='doughnut-component__legend'>
        {data.payload.map((x, index) => {
          const activeIndex = this.state.activeIndex;
          let opacity = 1;
          if (activeIndex != null) {
            if (activeIndex !== index) {
              opacity = PASSIVE_OPACITY;
            }
          }
          return (
            <div
              key={index}
              style={{ opacity }}
              onMouseOver={this.onMouseOver.bind(this, null, index)} // eslint-disable-line react/jsx-no-bind
              onMouseOut={this.onMouseOut.bind(this, null, index)} // eslint-disable-line react/jsx-no-bind
            >
              <i style={{ backgroundColor: x.color }} />
              <span>{x.value}</span>
            </div>
          );
        })}
      </div>
    );
  }
}

export const Doughnut = withI18n(DoughnutComponent);
