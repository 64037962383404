import styled from 'styled-components';

const CONTAINER_PADDING = 10;
const GAP = 20;

const Container = styled.div`
  position: relative;
  padding: ${CONTAINER_PADDING}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${p => p.theme.color.backgroundRush};
  height: 100%;
  width: 60px;
  border-radius: 0 15px 15px 0;
  gap: ${GAP}px;
  
  &, * {
    box-sizing: border-box;
  }
  button {
    :hover {
      background-color: ${p => p.theme.color.pale};
    }
  }
`;

const Border = styled.div<{ top: number }>`
  position: absolute;
  background: ${p => p.theme.color.turquoise};
  border-radius: 1px 4px 4px 1px;
  width: 4px;
  height: 50px;
  left: 0;
  top: ${p => p.top + 5}px;
`;


export const Styled = {
  Container,
  Border,
  CONTAINER_PADDING,
  GAP,
};
