import styled from 'styled-components';

const Container = styled.div<{ isNotification: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${p => p.isNotification ? 'auto' : 'inherit'};
`;

const HeaderContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${p => p.theme.color.background};
  padding-right: 15px;
`;

const IconContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 15px;

  > svg {
    height: 20px;
    width: 20px;
    fill: ${p => p.theme.color.gray};
  }
`;

const EmptyNotificationWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Styled = {
  Container,
  HeaderContainer,
  IconContainer,
  EmptyNotificationWrapper,
};
