import * as t from 'io-ts';

import { QtoTreeColumnC } from './quantity-take-off-report-column';

export const QtoReportColumnsModelC = t.type({
  firstLevelColumns: t.array(t.string),
  columns: t.dictionary(t.string, QtoTreeColumnC),
});


export type QtoReportColumnsModel = t.TypeOf<typeof QtoReportColumnsModelC>;
