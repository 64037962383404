import classNames from 'classnames';
import * as React from 'react';

import './color-circle-dialog.scss';

import { IconButton, KreoIconAssign, KreoIconCancel, KreoIconReset } from 'common/UIKit';
import { KreoToolbarButton } from '../kreo-toolbar/kreo-toolbar-button';
import { SvgColorPicker } from '../svg-color-picker';

interface Props {
  color: string;
  className?: string;
  onAssignColor?: () => void;
  onDefaultColor?: () => void;
  onChangeColor: (color: string) => void;
  onExitClick: () => void;
}


export const ColorCircleDialog: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames('color-circle-dialog', props.className)}
    >
      <div className='color-circle-dialog__header'>
        <span className='color-circle-dialog__title'>SELECT COLOUR</span>
        <IconButton
          size='small'
          onClick={props.onExitClick}
          data-control-name='selection-color-button'
        >
          <KreoIconCancel />
        </IconButton>
      </div>
      <div className='color-circle-dialog__content'>
        <SvgColorPicker color={props.color} onChange={props.onChangeColor} />
        {props.onAssignColor && props.onDefaultColor &&
          <div className='color-circle-dialog__controls'>
            <KreoToolbarButton
              onClick={props.onDefaultColor}
              controlName='default-color-button'
              defaultTooltipText='Default Color'
            >
              <KreoIconReset />
            </KreoToolbarButton>
            <KreoToolbarButton
              onClick={props.onAssignColor}
              controlName='assign-color-button'
              defaultTooltipText='Assign Color'
            >
              <KreoIconAssign />
            </KreoToolbarButton>
          </div>
        }
      </div>
    </div>
  );
};

