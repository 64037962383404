import { Text } from '@kreo/kreo-ui-components';
import React from 'react';
import { Styled } from './styled';

interface Props {
  percentage: boolean;
  finished: number;
  total: number;
  progressTitle: string;
  customProgressText: string;
}

const ProgressLoaderComponent: React.FC<Props> = (
  {
    percentage,
    finished: loaded,
    total,
    progressTitle,
    customProgressText,
  }: Props,
) => {
  const progress = total ? Math.round((loaded / total) * 100) : 0;
  const text = customProgressText || (percentage ? `${progress}%` : (loaded || 0).toString());

  return (
    <Styled.Container progress={progress}>
      <Styled.TextContainer>
        <Text
          fontSize={12}
        >
          {progressTitle}
        </Text>
        <Text
          fontSize={12}
        >
          {text}
        </Text>
      </Styled.TextContainer>
    </Styled.Container>
  );
};

export const ProgressLoader = React.memo(ProgressLoaderComponent);
