import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { WrappedFieldArrayProps } from 'redux-form';

import { KreoDialogActions } from 'common/UIKit';
import { ActivityVariantResource, UnitModel } from '../../interfaces/data';
import { MaterialModel } from '../../interfaces/resources-data';
import { MaterialForm } from '../../interfaces/rest-data';
import { DatabaseEntityNamedHeader } from '../database-entity-named-header';
import { ResourceFieldHelper } from '../resource-selector/resource-field-helper';
import { DatabaseActivityVariantMaterialItem } from './database-activity-variant-material-item';
import {
  DatabaseActivityVariantMaterialSelector,
  RESOURCE_SELECTOR_DIALOG_MATERIAL } from './database-activity-variant-material-selector';

export interface MaterialsProps {
  readonly: boolean;
  materials: Array<ActivityVariantResource<MaterialModel>>;
  unitMap: Record<number, UnitModel>;
  onChange: (materials: Array<ActivityVariantResource<MaterialModel>>) => void;
}

interface DispatchProps {
  close: (name: string) => void;
  open: (name: string) => void;
}

interface Props extends MaterialsProps, DispatchProps {}

class DatabaseActivityVariantMaterialsComponent
  extends React.Component<Props & WrappedFieldArrayProps<MaterialForm>> {

  public render(): React.ReactNode {
    return (
      <div className='materials-right-panel'>
        <DatabaseEntityNamedHeader
          name='Materials'
          onAddButtonClick={this.props.readonly ? null : this.openMaterialSelector}
        />
        <div className='materials-right-panel__content'>
        {
          this.props.fields.map((fieldKey, index) => {
            return this.props.materials[index] ? (
              <DatabaseActivityVariantMaterialItem
                unitMap={this.props.unitMap}
                fieldKey={fieldKey}
                material={this.props.materials[index]}
                key={fieldKey}
                index={index}
                onQuantityChange={this.onQuantityChange}
                onDelete={this.onDelete}
                readonly={this.props.readonly}
              />
            ) : null;
          })
        }
        </div>
        {
          !this.props.readonly ? (
            <DatabaseActivityVariantMaterialSelector
              selectedMaterials={this.props.materials}
              onCancel={this.closeMaterialSelector}
              onSave={this.onChange}
            />
          ) : null
        }
      </div>
    );
  }

  @autobind
  private onChange(materials: Array<ActivityVariantResource<MaterialModel>>): void {
    ResourceFieldHelper.changeResourcesField(
      this.props.materials,
      materials,
      this.props.fields,
      this.props.onChange,
    );
  }

  @autobind
  private openMaterialSelector(): void {
    this.props.open(RESOURCE_SELECTOR_DIALOG_MATERIAL);
  }

  @autobind
  private closeMaterialSelector(): void {
    this.props.close(RESOURCE_SELECTOR_DIALOG_MATERIAL);
  }

  @autobind
  private onQuantityChange(index: number, amount: number): void {
    const result = this.props.materials.slice();
    result[index] = { ...result[index], amount };
    this.props.onChange(result);
  }

  @autobind
  private onDelete(index: number): void {
    const materials = this.props.materials.slice();
    materials.splice(index, 1);
    this.props.fields.remove(index);
    this.props.onChange(materials);
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    close: (name: string) => dispatch(KreoDialogActions.closeDialog(name)),
    open: (name: string) => dispatch(KreoDialogActions.openDialog(name)),
  };
};

export const DatabaseActivityVariantMaterials =
  connect(null, mapDispatchToProps)(DatabaseActivityVariantMaterialsComponent);
