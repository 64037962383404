import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.color.background};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 40px;
  max-width: 260px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  font-size: 14px;

  ${Text} {
    max-width: 260px;
  }
`;

const PriceAndDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Price = styled.div`
  padding-right: 15px;

  > p {
    font-size: 12px;
  }
`;

const Data = styled.div`
  > p {
    font-size: 12px;
  }
`;

const Download = styled.div`
  cursor: pointer;

  > p {
    font-size: 14px;
    color: ${p => p.theme.color.turquoise};
  }
`;


export const Styled = {
  Container,
  ContentContainer,
  Title,
  PriceAndDataContainer,
  Price,
  Data,
  Download,
};
