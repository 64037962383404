import autobind from 'autobind-decorator';
import * as React from 'react';

import { CustomElementFilterNumberOperation } from 'common/enums/custom-element-filter-number-operation';
import { MaterialNumberInput } from 'common/UIKit';
import { CustomElementFilterBuilderOperationSelect } from './custom-element-filter-builder-operation-select';
import { InnerCustomElementFilterNumber } from './inner-interfaces';


interface Props {
  filterItem: InnerCustomElementFilterNumber;
  onChange: (item: InnerCustomElementFilterNumber) => void;
}


export class CustomElementFilterBuilderNumberItem extends React.Component<Props> {
  public render(): JSX.Element {
    const { filterItem } = this.props;

    return (
      <div className='custom-element-filter-builder-number-item'>
        <CustomElementFilterBuilderOperationSelect
          value={filterItem.operation}
          onChange={this.onOperationChange}
          values={CustomElementFilterNumberOperation}
        />
        <MaterialNumberInput
          value={filterItem.operationArg}
          onChange={this.onOperationArgChange}
        />
      </div>
    );
  }

  @autobind
  private onOperationChange(operation: CustomElementFilterNumberOperation): void {
    const { filterItem, onChange } = this.props;
    onChange({
      ...filterItem,
      operation,
    });
  }

  @autobind
  private onOperationArgChange(_: React.ChangeEvent, operationArg: number): void {
    const { filterItem, onChange } = this.props;
    onChange({
      ...filterItem,
      operationArg,
    });
  }
}
