import { CodecUtils } from 'common/utils/codec-utils';
import { EnumUtils } from 'common/utils/enum-utils';
import { ValidationStepName } from './validation-step-name';

export enum ValidationStep {
  ModelCheck = 'ModelCheck',
  Classification = 'Classification',
  ActivityAssignment = 'ActivityAssignment',
  Measurements = 'Measurements',
  Results = 'Results',
}

export const ValidationStepC = CodecUtils.createEnumCodec<ValidationStep>(ValidationStep, 'ValidationStep');

const stepsSequence = [
  ValidationStep.ModelCheck,
  ValidationStep.Classification,
  ValidationStep.ActivityAssignment,
  ValidationStep.Measurements,
  ValidationStep.Results,
];

export function getNextStep(step: ValidationStep): ValidationStep | null {
  const stepIndex = stepsSequence.findIndex(x => x === step);
  return stepIndex >= 0 && stepIndex < stepsSequence.length ? stepsSequence[stepIndex + 1] : null;
}

export function getPreviousStep(step: ValidationStep): ValidationStep | null {
  const stepIndex = stepsSequence.findIndex(x => x === step);
  return stepIndex >= 1 ? stepsSequence[stepIndex - 1] : null;
}

export function getValidationStep(stepName: ValidationStepName): ValidationStep {
  switch (stepName) {
    case ValidationStepName.ModelCheck:
      return ValidationStep.ModelCheck;
    case ValidationStepName.Classification:
      return ValidationStep.Classification;
    case ValidationStepName.ActivityAssignment:
      return ValidationStep.ActivityAssignment;
    case ValidationStepName.Measurements:
      return ValidationStep.Measurements;
    case ValidationStepName.Results:
      return ValidationStep.Results;
    default:
      console.error('Unexpected stepName value, returning null');
      return null;
  }
}

export function isValidationStepGreaterThan(
  current: ValidationStep,
  target: ValidationStep,
  equal: boolean = false,
): boolean {
  return EnumUtils.isGreaterThan(stepsSequence, current, target, equal);
}

export function isValidationStepLessThan(
  current: ValidationStep,
  target: ValidationStep,
  equal: boolean = false,
): boolean {
  return EnumUtils.isLessThan(stepsSequence, current, target, equal);
}
