import { Icons, SvgComponent, Switcher, VideoPopUp } from '@kreo/kreo-ui-components';
import ColoredScrollbars from '@kreo/kreo-ui-components/components/scrollbars';
import React, { useCallback, useMemo, useState } from 'react';

import { H5, Text } from '../text';
import { Product } from './product';
import { Styled } from './styled';


export enum ProductConfigId {
  Takeoff2d = 'twodtakeoff',
  Takeoff3d = 'threedtakeoff',
  CostEstimate = 'costestimate',
  KreoPlan = 'plan',
}

export interface ProductConfig {
  name: string;
  id: ProductConfigId;
  url: string;
  color: string;
  image: string;
  Logo: SvgComponent;
  videoId?: string;
  additionalInfo?: string;
  descriptionText?: string;
}

export interface Props {
  products: ProductConfig[];
  defaultImage: string;
  RouterLink: any;
  nightVersion?: boolean;
  switchTheme?: (mode: boolean) => void;
}

export const ProductsPage: React.FC<Props> = ({ products, defaultImage, nightVersion, switchTheme, RouterLink }) => {
  const [backGroundImage, setBackGroundImage] = useState<string>(null);
  const [videoId, setVideoId] = useState<string>(null);
  const [productId, setProductId] = useState<ProductConfigId>(null);
  const isSelectedTakeoff2d = useMemo(() => productId === ProductConfigId.Takeoff2d, [productId]);

  const toggleSwitcher = useCallback(() => {
    switchTheme(nightVersion);
  }, [nightVersion, switchTheme]);

  const closeVideoPopUp = useCallback(() => {
    setVideoId(null);
  }, []);

  return (
    <ColoredScrollbars>
      <Styled.Container url={backGroundImage || defaultImage}>
        <Styled.Header>
          <Styled.KreoLogoContainer>
            <Icons.KreoLogo/>
            <H5 color={isSelectedTakeoff2d && 'white'}>KREO</H5>
          </Styled.KreoLogoContainer>
          {switchTheme && <Styled.SwitcherContainer>
            <Switcher
              onChange={toggleSwitcher}
              name='Night mode'
              checked={nightVersion === false}
              themeSwitcher={true}
            />
          </Styled.SwitcherContainer>}
        </Styled.Header>
        <Styled.Content>
          <Styled.TextContainer>
            <Text
              fontSize={60}
              color={isSelectedTakeoff2d && 'white'}
            >
              Our products
            </Text>
            <Text
              fontSize={12}
              color={isSelectedTakeoff2d && 'white'}
            >
              Select the product you want to use:
            </Text>
          </Styled.TextContainer>
          <Styled.Frames products={products}>
            {products.map((el) => (
              <Product
                key={el.id}
                id={el.id}
                name={el.name}
                Logo={el.Logo}
                url={el.url}
                color={el.color}
                image={el.image}
                setVideoId={setVideoId}
                setBackGroundImage={setBackGroundImage}
                setProductId={setProductId}
                additionalInfo={el.additionalInfo}
                descriptionText={el.descriptionText}
                RouterLink={RouterLink}
              />
            ))}
          </Styled.Frames>
        </Styled.Content>
      </Styled.Container>
      <VideoPopUp videoId={videoId} close={closeVideoPopUp}/>
    </ColoredScrollbars>
  );
};
