import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { AnyAction, Dispatch } from 'redux';

import './classification-ontology-vertex.scss';

import { MultiLevelDropDownMenu } from 'common/components/multi-level-drop-down-menu';
import { TooltipContainer } from 'common/components/tooltip-container';
import { StringDictionary } from 'common/interfaces/dictionary';
import { IconButton, KreoIconAiMaterial, KreoIconDelBasket } from 'common/UIKit';
import { PreparedSearchQuery, StringUtils } from 'common/utils/string-utils';
import { ClassificationActions } from '../../actions/creators/classification';
import {
  ClassificationOntologyVariantTree,
} from '../../interfaces/classification/ontology-data';
import { ClassificationUtils } from '../../utils/classification-utils';
import {
  ClassificationOntologyTreeElementStateIndicator,
} from '../classification-ontology-tree-element-state-indicator';

interface OwnProps {
  x: number;
  y: number;
  id: string;
  graphId: number;
  name: string;
  propertyKey: string;
  variantsTree: ClassificationOntologyVariantTree[];
  isError: boolean;
  isEdit: boolean;
  augmentation: boolean;
}

interface DispatchProps {
  selectVariant: (variant: ClassificationOntologyVariantTree) => void;
  onDeleteClick: () => void;
}

interface Props extends OwnProps, DispatchProps {

}

interface State {
  variantValueToInfo: StringDictionary<ClassificationOntologyVariantTree>;
  dropDownStyle: React.CSSProperties;
  showTooltip: boolean;
}

class ClassificationOntologyVertexComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      variantValueToInfo: {},
      dropDownStyle: {},
      showTooltip: false,
    };
  }

  public static getDerivedStateFromProps(props: Props): Partial<State> {

    return {
      variantValueToInfo: ClassificationUtils.mapValueVariantTree(props.variantsTree),
    };
  }

  public componentWillUnmount(): void {
    document.removeEventListener('click', this.windowClick);
  }


  public render(): React.ReactNode {
    const { x, y, id, isError, isEdit, name, variantsTree, augmentation } = this.props;
    const level = id.split('/').length;
    const vertexClassName = classNames('classification-ontology-vertex', {
      'classification-ontology-vertex--with-variants': variantsTree && !! variantsTree.length,
      'classification-ontology-vertex--error': isError,
      'classification-ontology-vertex--right': level > 2,
      'classification-ontology-vertex--view': !isEdit,
    });
    return (
      <MultiLevelDropDownMenu<ClassificationOntologyVariantTree>
        value={name}
        options={variantsTree}
        compare={this.searchComparer}
        onSelect={this.selectValue}
        className={vertexClassName}
        style={{ left: x, top: y }}
        optionContentRenderer={this.renderDropdownOption}
        onClick={this.onClick}
      >
        {this.renderContent()}
        <ClassificationOntologyTreeElementStateIndicator
          hasError={isError}
          className='classification-ontology-vertex__indicator'
        />
        {!isError && isEdit && (
          <IconButton
            size='small'
            className='classification-ontology-vertex__remove'
            onClick={this.onRemoveClick}
            controlName='remove-node'
          >
            <KreoIconDelBasket />
          </IconButton>
        )}
        {augmentation && (
          <div className='classification-ontology-vertex__badge'>
            <KreoIconAiMaterial />
            <TooltipContainer className='classification-ontology-vertex__badge-tooltip'>
              <b>Classified.</b> Material is not specified for the object, but Kreo picked the most likely one.
            </TooltipContainer>
          </div>
        )}
        <CSSTransition
          timeout={200}
          in={this.state.showTooltip}
          className='classification-ontology-vertex__tooltip'
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <TooltipContainer
            className='classification-ontology-vertex__tooltip'
          >
            You are in view-only mode. To edit the graph, press «Remove approval» on the Validation page.
          </TooltipContainer>
        </CSSTransition>
      </MultiLevelDropDownMenu>
    );
  }

  @autobind
  private onClick(): void {
    if (!this.state.showTooltip && !this.props.isEdit) {
      this.setState({ showTooltip: true });
      document.addEventListener('click', this.windowClick);
    }
  }

  @autobind
  private windowClick(): void {
    this.setState({ showTooltip: false });
    document.removeEventListener('click', this.windowClick);
  }

  @autobind
  private searchComparer(option: ClassificationOntologyVariantTree, query: PreparedSearchQuery): boolean {
    return StringUtils.compareStringWithSearchQuery(option.name as string, query)
      || (option.synonyms && option.synonyms.some(x => StringUtils.compareStringWithSearchQuery(x, query)));
  }

  @autobind
  private renderDropdownOption(
    option: ClassificationOntologyVariantTree,
    query: PreparedSearchQuery,
  ): React.ReactNode {
    const name = MultiLevelDropDownMenu.defaultSearchQueryHighlighter(option.name, query);
    return <div className='classification-ontology-vertex__option-name' dangerouslySetInnerHTML={{ __html: name }}/>;
  }

  @autobind
  private selectValue(value: string): void {
    const currentValue = this.state.variantValueToInfo[value];
    this.props.selectVariant(currentValue);
  }

  @autobind
  private onRemoveClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    this.props.onDeleteClick();
  }

  private renderContent(): React.ReactNode {
    const { propertyKey, name, isError } = this.props;
    if (isError) {
      return (
        <div className='classification-ontology-vertex__value' title={propertyKey}>
          Set {propertyKey}
        </div>
      );
    }

    return [
      (
        <div className='classification-ontology-vertex__key' key='key' title={propertyKey}>
          {propertyKey}
        </div>
      ),
      (
        <div className='classification-ontology-vertex__value' key='value' title={name}>
          {name}
        </div>
      ),
    ];
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { id, graphId }: OwnProps): DispatchProps {
  return {
    onDeleteClick: () => dispatch(ClassificationActions.removeNode(graphId, id)),
    selectVariant: variant => dispatch(ClassificationActions.selectNodeVariant(graphId, id, variant)),
  };
}

const connector = connect(null, mapDispatchToProps);
export const ClassificationOntologyVertex  = connector(ClassificationOntologyVertexComponent);
