import { Icons, SvgComponent } from '@kreo/kreo-ui-components';
import { RequestStatus } from 'common/enums/request-status';

interface StatusData {
  mood: string;
  icon?: SvgComponent;
  text: string;
}

export function getStatusData(status: RequestStatus): StatusData {
  switch (status) {
    case RequestStatus.Loaded:
      return {
        mood: 'secondary',
        text: 'Load More',
      };
    case RequestStatus.Failed:
      return {
        mood: 'negative',
        text: 'Try Again',
        icon: Icons.Error,
      };
    default:
      return {
        mood: 'secondary',
        text: 'Load more...',
        icon: Icons.Loader,
      };
  }
}
