import React from 'react';

import { ShapeToIconMap, StrokeStyleToIconMap } from 'common/components/drawings/constants/drawing-styles';
import { DrawingsGeometryInstance } from 'common/components/drawings/interfaces';
import { DrawingsGeometryUtils } from 'common/components/drawings/utils/drawings-geometry-utils';
import { InformationLine, SimpleInformationLine } from '../information-line';
import { Styled } from '../styled';

interface Props {
  instance: DrawingsGeometryInstance;
}

const InstanceStylesComponent: React.FC<Props> = ({ instance }: Props) => {
  if (DrawingsGeometryUtils.isPolyGeometry(instance.type, instance.geometry)) {
    const Icon = StrokeStyleToIconMap[instance.geometry.strokeStyle];
    return (
      <>
        <SimpleInformationLine title={'Stroke'} value={`${instance.geometry.strokeWidth} px`}/>
        <InformationLine title={'Style'}>
          <Styled.LineStyleIcon color={instance.geometry.color}>
            <Icon />
          </Styled.LineStyleIcon>
        </InformationLine>
      </>
    );
  } else if (DrawingsGeometryUtils.isCount(instance.type, instance.geometry)) {
    const Icon = ShapeToIconMap[instance.geometry.shape];
    return (
      <InformationLine title={'Shape'}>
        <Styled.StyleIcon color={instance.geometry.color}>
          <Icon />
        </Styled.StyleIcon>
      </InformationLine>
    );
  }
};

export const InstanceStyles = React.memo(InstanceStylesComponent);
