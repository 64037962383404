import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { State } from 'common/interfaces/state';
import { AppUrls } from 'routes/app-urls';
import { ClassificationChart } from '../../../../../components/charts';
import { ObjectStatisticType } from '../../../../../components/charts/interfaces/object-statistic-type';
import { ModuleStatus, ScenarioModule, ScenarioState } from '../../../../../units/projects/interfaces/scenario-state';
import { ScenarioStateHelper } from '../../../../../units/projects/utils/scenario-state-helper';
import { MeasurementsActions } from '../../../actions/creators/measurements';
import { ValidationStep } from '../../../enums/validation-step';
import { ValidationStepState } from '../../../enums/validation-step-state';
import { ProjectValidationState } from '../../../interfaces/project-validation-state';
import { StepProps, ValidationStepWrapper } from '../components/validation-stepper';

interface DispatchProps {
  dropStatistic: () => void;
  loadStatistic: (id: number) => void;
}

interface StateProps {
  statistic: ObjectStatisticType[];
  scenarioId: number;
  loaded: boolean;
  errors: number[];
  validationState: ProjectValidationState | null;
  isApprovable: boolean;
  projectId: number;
  scenarioState: ScenarioState;
}

interface Props extends StepProps, StateProps, DispatchProps, AbilityAwareProps { }

class Measurement extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public componentDidUpdate(prevProps: Props): void {
    const { stepperRef, errors, loaded, statistic, scenarioState } = this.props;
    if (loaded) {
      const hasError = errors.length > 0;
      if (stepperRef && hasError) {
        stepperRef.paintEngine([], errors);
      }
    }

    if (stepperRef && (!statistic || statistic.length === 0)) {
      stepperRef.clearEngine();
    }
    const readyToLoad = ScenarioStateHelper
      .shouldLoadModel(scenarioState, prevProps.scenarioState, ScenarioModule.Measurements);
    if (readyToLoad) {
      this.props.loadStatistic(this.props.scenarioId);
    }
  }

  public componentDidMount(): void {
    const { scenarioId, scenarioState } = this.props;

    if (scenarioId && scenarioState &&
      scenarioState.moduleStatuses[ScenarioModule.Measurements] === ModuleStatus.Ready
    ) {
      this.props.loadStatistic(scenarioId);
    }
  }

  public render(): React.ReactNode {
    const { ability } = this.props;
    const urlParams = { projectId: this.props.projectId.toString() };

    return (
      <ValidationStepWrapper>
        <h3>
          {this.props.title}
        </h3>
        <div className='validation-step-content__inner-wrap'>
          <ClassificationChart
            viewLink={AppUrls.plan.project.validation.viewMeasurements.url(urlParams)}
            fixLink={AppUrls.plan.project.validation.editMeasurements.url(urlParams)}
            isFixable={this.props.isApprovable && ability.can(Operation.Update, Subject.ValidationMeasurements)}
            isLoading={!this.props.loaded || !this.props.statistic || this.props.statistic.length === 0}
            data={this.props.statistic}
            onSelectIds={this.isolate}
            noDataMessage='No calculation yet'
            onDeselect={this.showAll}
            chartName={ValidationStep.Measurements}
            attentionStatus={this.hasUncertainStatistic()}
          />
        </div>
      </ValidationStepWrapper>
    );
  }

  @autobind
  private hasUncertainStatistic(): boolean {
    const uncertainStatistic = this.props.statistic.find(s => s.name === 'Uncertain');
    return uncertainStatistic && uncertainStatistic.amount > 0;
  }

  @autobind
  private isolate(ids: number[]): void {
    if (this.props.stepperRef) {
      this.props.stepperRef.isolate(ids);
    }
  }

  @autobind
  private showAll(): void {
    if (this.props.stepperRef) {
      this.props.stepperRef.showAll();
    }
  }
}

const mapStateToProps = (state: State): StateProps => {
  const currentProject = state.projects.currentProject;
  const validationState = currentProject ? currentProject.validationState : null;

  const isApprovable = validationState &&
    validationState.validationStep === ValidationStep.Measurements &&
    validationState.stepState === ValidationStepState.Approvable;
  const scenario = state.scenarios.active_scenario;
  const scenarioState = scenario
    ? state.projects.scenarioStates[scenario.scenarioGuid]
    : null;
  return {
    validationState,
    statistic: state.measurements.statistic,
    scenarioId: scenario && scenario.id,
    loaded: state.measurements.statisticLoaded,
    errors: state.measurements.badIds,
    isApprovable,
    projectId: currentProject ? currentProject.id : null,
    scenarioState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    loadStatistic: (id: number) => {
      dispatch(MeasurementsActions.getStatisticRequest(id));
    },
    dropStatistic: () => {
      dispatch(MeasurementsActions.dropStatistic());
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const MeasurementStep = withAbilityContext(connector(Measurement));
