import * as t from 'io-ts';

export const CurrencyModelC = t.strict({
  code: t.string,
  name: t.string,
  symbol: t.string,
});

export const CurrencyModelsArrayC = t.array(CurrencyModelC);

export type CurrencyModel = t.TypeOf<typeof CurrencyModelC>;
