import autobind from 'autobind-decorator';
import * as React from 'react';

import { DrawingsGeometryGroup, DrawingsInstanceMeasure, DrawingsLayoutApi } from 'common/components/drawings';
import { DrawingsCommonUtils } from 'common/components/drawings/utils/drawings-common-utils';
import { DrawingsGroupUtils } from 'common/components/drawings/utils/drawings-group-utils';
import { ExcelFormulaHelper, ExcelTableApi } from 'common/components/excel-table';
import { ReferenceHelper } from 'common/components/excel-table/utils';
import { FormulaBarElementType, FormulaToolbar, FormulaToolbarApi } from 'common/components/formula-toolbar';
import { TwoDRegex, TwoDRegexGetter } from '../../units/2d-regex';
import { replaceDrawingRef } from '../../units/replace-drawing-ref';
import { SegmentLength } from '../../units/segment-length-helper';
import { DispatchProps, reduxConnector, StateToProps } from './redux-connector';
import { ElementColorEditorProps, withElementColorEditor } from './updates-helper';

export interface OwnProps extends ElementColorEditorProps {
  drawingsLayoutApi: DrawingsLayoutApi;
  updateData: (formulaValue: string, continueEditing: boolean) => void;
  getSelectedGridRef: () => ExcelTableApi;
  referenceReader: ReferenceHelper;
  saveApi: (api: FormulaToolbarApi) => void;
  onEscapeDown: () => void;
  openDialog: (name: string, data?: () => void) => void;
  canEditReport: boolean;
  canViewReport: boolean;
  getSheetSize: (sheetId: string) => { rowsLength: number, columnsLength: number };
}

export type Props = StateToProps & OwnProps & DispatchProps;

class TwoDReportFormulaBarComponent extends React.PureComponent<Props> {
  private formulaBarApi: FormulaToolbarApi;

  public render(): JSX.Element {
    const {
      focusedCell,
      isFormulaBarFocused,
      drawingsLayoutApi,
    } = this.props;
    const value = this.getValue();
    return (
      <FormulaToolbar
        value={value}
        updateData={this.props.updateData}
        onFocus={this.onFocused}
        onBlur={this.onBlur}
        onInput={this.onInput}
        onSelectClick={this.onSelectMeasuresClick}
        isSelect={focusedCell.isCanWrite}
        isFocused={isFormulaBarFocused}
        isImperial={this.props.isImperial}
        onPuponClick={this.onPuponClick}
        getColor={this.getColorPupon}
        getValue={this.valueGetter}
        onMouseEnterPupon={this.onEnterPupon}
        onMouseLeavePupon={this.onLeavePupon}
        saveApi={this.saveFormulaBarApi}
        onEscapeDown={this.onEscapeDown}
        focusedCell={this.props.focusedCell}
        changeEntitiesColor={this.changeEntitiesColor}
        canEditReport={this.props.canEditReport}
        canViewReport={this.props.canViewReport}
        reportPages={this.props.reportPages}
        getMaxCellIdentificationSizes={this.getMaxCellIdentificationSizes}
        getInstancesMeasures={drawingsLayoutApi.getInstancesMeasures}
      />
    );
  }

  @autobind
  private changeEntitiesColor(ids: string[], color: string, isGroup: boolean): void {
    if (isGroup) {
      this.props.drawingsLayoutApi.changeGroupColor(color, ids[0]);
    } else {
      this.props.editColor(ids, color);
    }
  }

  @autobind
  private getInstancesMeasures(instancesIds: string[]): DrawingsInstanceMeasure[] {
    const { drawings, aiAnnotation, elementMeasurement } = this.props;
    return this.props.drawingsLayoutApi.getInstancesMeasures(
      instancesIds,
      drawings,
      aiAnnotation,
      elementMeasurement,
    );
  }

  @autobind
  private getSegmentLength(id: string): DrawingsInstanceMeasure | null {
    const { drawings, aiAnnotation, elementMeasurement } = this.props;
    return this.props.drawingsLayoutApi.getSegmentMeasures(
      SegmentLength.splitId(id),
      drawings,
      aiAnnotation,
      elementMeasurement,
    );
  }

  @autobind
  private saveFormulaBarApi(api: FormulaToolbarApi): void {
    this.formulaBarApi = api;
    const ref = { ...api };
    ref.refresh = this.refresh;
    this.props.saveApi(ref);
  }

  @autobind
  private refresh(): void {
    const value = this.getValue();
    this.formulaBarApi.refresh(value);
  }

  @autobind
  private onEscapeDown(): void {
    this.props.onEscapeDown();
  }

  @autobind
  private getValue(): string {
    return this.props.canViewReport
      ? ExcelFormulaHelper.getFormattingFormulaValue(
        this.props.formulaBarValue,
        this.props.selectedSheetId,
        this.props.reportPages,
      )
      : '';
  }

  @autobind
  private valueGetter(id: string, type: FormulaBarElementType): string {
    if (type === FormulaBarElementType.DrawingElement) {
      const value = this.replaceDrawingRef(id);
      return value.replace('=', '');
    }
  }

  @autobind
  private replaceDrawingRef(value: string): string {
    return replaceDrawingRef(
      value,
      this.props.drawingsLayoutApi,
      this.props.isImperial,
      this.props.geometry,
      this.props.drawingsInfo,
      this.props.drawingsGroups,
      this.props.entities,
      this.getInstancesMeasures,
      this.getSegmentLength,
    );
  }

  private selectGroupChildren(group: DrawingsGeometryGroup): void {
    const { setSelectedInstances, setSelectGeometryGroup, drawingsGroups } = this.props;
    if (this.canSelectMeasurements()) {
      const groupsMap = DrawingsGroupUtils.getParentToChildMap(drawingsGroups);
      const measurements = DrawingsGroupUtils.getAllInnerMeasurements(group, groupsMap, () => true);
      const groups = DrawingsGroupUtils.getAllInnerGroups(group.id, groupsMap);
      setSelectedInstances(measurements);
      setSelectGeometryGroup(Object.keys(groups));
    } else {
      setSelectGeometryGroup([group.id]);
    }
  }

  @autobind
  private onPuponClick(value: string, type: FormulaBarElementType): void {
    if (type === FormulaBarElementType.DrawingElement) {
      if (this.canSelectMeasurements()) {
        if (SegmentLength.isSegmentId(value)) {
          this.props.drawingsLayoutApi.scrollToDrawingInstances([SegmentLength.splitId(value)]);
        } else {
          const instance = this.getInstancesMeasures([value])[0];
          if (instance) {
            this.props.drawingsLayoutApi.scrollToDrawingInstances([value]);
            this.props.setSelectedInstances([value]);
          } else {
            const group = this.props.drawingsGroups.find(gr => gr.id === value);
            if (group) {
              this.selectGroupChildren(group);
            }
          }
        }
      } else {
        const group = this.props.drawingsGroups.find(gr => gr.id === value);
        if (group) {
          this.props.setSelectGeometryGroup([value]);
        }
      }
    }
  }

  @autobind
  private onEnterPupon(value: string, type: FormulaBarElementType): void {
    if (type === FormulaBarElementType.Cell) {
      const { setCellWithBoarder, getSelectedGridRef } = this.props;
      const { sheetName, columnId, rowId } = TwoDRegexGetter.getFullCellField(value);
      setCellWithBoarder(ExcelFormulaHelper.getCellLink(sheetName, columnId, rowId));
      getSelectedGridRef().api.refreshCells();
    }
  }

  @autobind
  private onLeavePupon(_value: string, type: FormulaBarElementType): void {
    if (type === FormulaBarElementType.Cell) {
      const { setCellWithBoarder, getSelectedGridRef } = this.props;
      setCellWithBoarder('');
      getSelectedGridRef().api.refreshCells();
    }
  }

  @autobind
  private getColorPupon(id: string, type: FormulaBarElementType): string {
    if (type === FormulaBarElementType.Cell) {
      return '';
    }

    if (SegmentLength.isSegmentId(id)) {
      const { drawings, aiAnnotation, elementMeasurement } = this.props;
      return SegmentLength.getColor(
        id,
        this.props.drawingsLayoutApi,
        drawings,
        aiAnnotation,
        elementMeasurement,
      );
    }

    const measure = this.getInstancesMeasures([id])[0];
    if (measure) {
      return measure.color;
    }
    const group = this.props.drawingsGroups.find(x => x.id === id);
    return group ? group.color : '';
  }

  @autobind
  private onSelectMeasuresClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    this.props.setWrite(!this.props.focusedCell.isCanWrite);
  }

  @autobind
  private onInput(value: string): void {
    this.props.setFormulaBar({ value });
  }

  @autobind
  private onFocused(): void {
    const { setRead, referenceReader } = this.props;
    setRead(true);
    this.props.setFormulaBar({ isFocused: true });
    referenceReader.setConfig(this.update, this.isUpdate);
  }

  private getEditSheetData(): { sheetId: string, rowId: string, columnId: string } {
    const match = TwoDRegex.fullCellId.exec(this.props.editFullCellId);
    const groups = match.groups;
    return { sheetId: groups.sheetId, rowId: groups.rowId, columnId: groups.columnId };
  }

  @autobind
  private update(value: string): void {
    const { sheetId } = this.props.editFullCellId ? this.getEditSheetData() : { sheetId: this.props.selectedSheetId };
    if (TwoDRegex.fullCellId.test(value)) {
      const cell = ExcelFormulaHelper.narrowCellIdBySheetId(
        value,
        sheetId,
      );
      const formulaWithName = ExcelFormulaHelper.replaceSheetIdToSheetName(cell, this.props.reportPages);
      this.formulaBarApi.insertCellValue(formulaWithName);
    } else {
      this.formulaBarApi.insertFunctionValue(value);
    }
  }

  @autobind
  private isUpdate(): boolean {
    const { formulaBarValue, isFormulaBarFocused } = this.props;
    return isFormulaBarFocused && (!formulaBarValue || ExcelFormulaHelper.isFormula(formulaBarValue));
  }

  @autobind
  private onBlur(): void {
    this.props.setRead(false);
    this.props.setFormulaBar({ isFocused: false });
  }

  @autobind
  private getMaxCellIdentificationSizes(sheetId: string): { maxColumn: number, maxRow: number } {
    const sheetDataId = sheetId || this.props.selectedSheetId;
    const { rowsLength, columnsLength } = this.props.getSheetSize(sheetDataId);

    return { maxRow: rowsLength, maxColumn: columnsLength };
  }

  private canSelectMeasurements(): boolean {
    const drawMode = this.props.drawingsLayoutApi.getDrawMode();
    return DrawingsCommonUtils.isSelectionEnabled(drawMode);
  }
}


export const TwoDReportFormulaBar = withElementColorEditor(reduxConnector(TwoDReportFormulaBarComponent));
