import { EndType, IntPoint, JoinType } from 'js-angusj-clipper';
import * as paper from 'paper';

import { ClipperLoaderWrapper } from 'common/components/drawings/helpers/geometry/clipper';
import {
  DrawingsAllowedPathType,
  DrawingsPaperPolygonPath,
} from 'common/components/drawings/interfaces/drawings-geometry';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { arrayUtils } from 'common/utils/array-utils';
import { mathUtils } from 'common/utils/math-utils';


const OFFSET_AREA_THRESHOLD = 0.1;

enum HandleType {
  handleIn = 'handleIn',
  handleOut = 'handleOut',
}

function sortChildren(path: DrawingsAllowedPathType): void {
  if (DrawingsPaperUtils.isCompoundPolygonPath(path)) {
    path.children.sort((a, b) => b.area - a.area);
  }
}

function offsetSegment(
  segment: paper.Segment,
  curve: paper.Curve,
  handleNormal: paper.Point,
  offset: number,
): paper.Segment {
  const isFirst = segment.curve === curve;
  const offsetVector = (curve.getNormalAtTime(isFirst ? 0 : 1)).multiply(offset);
  const point = segment.point.add(offsetVector);
  const newSegment = new paper.Segment(point);
  const handle = isFirst ? HandleType.handleOut : HandleType.handleIn;
  newSegment[handle] = segment[handle].add(handleNormal.subtract(offsetVector).divide(2));
  return newSegment;
}

function adaptiveOffsetEdge(curve: paper.Curve, offset: number): paper.Segment[] {
  const hNormal = (new paper.Curve(
    curve.segment1.handleOut.add(curve.segment1.point),
    new paper.Point(0, 0),
    new paper.Point(0, 0),
    curve.segment2.handleIn.add(curve.segment2.point),
  )).getNormalAtTime(0.5).multiply(offset);
  const segment1 = offsetSegment(curve.segment1, curve, hNormal, offset);
  const segment2 = offsetSegment(curve.segment2, curve, hNormal, offset);
  const offsetCurve = new paper.Curve(segment1, segment2);
  if (offsetCurve.getIntersections(offsetCurve).length === 0) {
    const threshold = Math.min(Math.abs(offset) / 10, 1);
    const midOffset = offsetCurve.getPointAtTime(0.5).getDistance(curve.getPointAtTime(0.5));
    if (Math.abs(midOffset - Math.abs(offset)) > threshold) {
      const subCurve = curve.divideAtTime(0.5);
      if (subCurve != null) {
        return [...adaptiveOffsetEdge(curve, offset), ...adaptiveOffsetEdge(subCurve, offset)];
      }
    }
  }
  return [segment1, segment2];
}


function det(p1: paper.Point, p2: paper.Point): number {
  return p1.x * p2.y - p1.y * p2.x;
}

function getPointLineIntersections(
  p1: paper.Point,
  p2: paper.Point,
  p3: paper.Point,
  p4: paper.Point,
): paper.Point {
  const l1 = p1.subtract(p2);
  const l2 = p3.subtract(p4);
  const dl1 = det(p1, p2);
  const dl2 = det(p3, p4);
  return new paper.Point(dl1 * l2.x - l1.x * dl2, dl1 * l2.y - l1.y * dl2).divide(det(l1, l2));
}

function connectAdjacentSegments(
  segments1: paper.Segment[],
  segments2: paper.Segment[],
  origin: paper.Segment,
  offset: number,
  limit: number,
): void {
  const curve1 = new paper.Curve(segments1[0], segments1[1]);
  const curve2 = new paper.Curve(segments2[0], segments2[1]);
  const intersection = curve1.getIntersections(curve2);
  const distance = segments1[1].point.getDistance(segments2[0].point);
  if (origin.isSmooth()) {
    segments2[0].handleOut = segments2[0].handleOut.project(origin.handleOut);
    segments2[0].handleIn = segments1[1].handleIn.project(origin.handleIn);
    segments2[0].point = segments1[1].point.add(segments2[0].point).divide(2);
    segments1.pop();
  } else {
    if (intersection.length === 0) {
      if (distance > Math.abs(offset) * OFFSET_AREA_THRESHOLD) {
        const join = getPointLineIntersections(
          curve1.point2, curve1.point2.add(curve1.getTangentAtTime(1)),
          curve2.point1, curve2.point1.add(curve2.getTangentAtTime(0)),
        );
        const joinOffset = Math.max(join.getDistance(curve1.point2), join.getDistance(curve2.point1));
        if (joinOffset < mathUtils.clamp(Math.abs(offset) * limit, 1, Infinity)) {
          segments1.push(new paper.Segment(join));
        }
      } else {
        segments2[0].handleIn = segments1[1].handleIn;
        segments1.pop();
      }
    } else {
      const second1 = curve1.divideAt(intersection[0]);
      if (second1) {
        const join = second1.segment1;
        const second2 = curve2.divideAt(curve2.getIntersections(curve1)[0]);
        join.handleOut = second2 ? second2.segment1.handleOut : segments2[0].handleOut;
        segments1.pop();
        segments2[0] = join;
      } else {
        segments2[0].handleIn = segments1[1].handleIn;
        segments1.pop();
      }
    }
  }
}

function connectSegments(
  rawSegments: paper.Segment[][],
  source: paper.Path,
  offset: number,
  limit: number,
): paper.Segment[][] {
  const originSegments = source.segments;
  const first = rawSegments[0].slice();
  for (let i = 0; i < rawSegments.length - 1; ++i) {
    connectAdjacentSegments(rawSegments[i], rawSegments[i + 1], originSegments[i + 1], offset, limit);
  }
  if (source.closed) {
    connectAdjacentSegments(rawSegments[rawSegments.length - 1], first, originSegments[0], offset, limit);
    rawSegments[0][0] = first[0];
  }
  return rawSegments;
}

function reduceSingleChildCompoundPath(path: DrawingsAllowedPathType): DrawingsAllowedPathType {
  if (path.children.length === 1) {
    path = path.children[0] as paper.Path;
    path.remove();
  }
  return path;
}

function normalize(path: DrawingsAllowedPathType, areaThreshold: number = 0.01): DrawingsAllowedPathType {
  if (path.closed) {
    const ignoreArea = Math.abs(path.area * areaThreshold);
    if (!path.clockwise) {
      path.reverse();
    }
    path = path.unite(path, { insert: false }) as DrawingsAllowedPathType;
    if (path instanceof paper.CompoundPath) {
      for (const c of path.children) {
        if (Math.abs(c.area) < ignoreArea) {
          c.remove();
        }
      }
      if (path.children.length === 1) {
        return reduceSingleChildCompoundPath(path);
      }
    }
  }
  return path;
}

function isSameDirection(partialPath: paper.Path, fullPath: DrawingsAllowedPathType): boolean {
  const offset1 = partialPath.segments[0].location.offset;
  const offset2 = partialPath.segments[Math.max(1, Math.floor(partialPath.segments.length / 2))].location.offset;
  const sampleOffset = (offset1 + offset2) / 3;
  const originOffset1 = fullPath.getNearestLocation(partialPath.getPointAt(sampleOffset)).offset;
  const originOffset2 = fullPath.getNearestLocation(partialPath.getPointAt(2 * sampleOffset)).offset;
  return originOffset1 < originOffset2;
}

function removeIntersection(path: DrawingsAllowedPathType): DrawingsAllowedPathType {
  if (path.closed) {
    const newPath = path.unite(path, { insert: false }) as DrawingsAllowedPathType;
    if (newPath instanceof paper.CompoundPath) {
      for (const child of newPath.children) {
        if (child.segments.length < 2 || !isSameDirection(child, path)) {
          child.remove();
        }
      }
      return reduceSingleChildCompoundPath(newPath);
    }
  }
  return path;
}

function removeOutsiders(newPath: DrawingsAllowedPathType, path: DrawingsAllowedPathType): void {
  for (const segment of DrawingsPaperUtils.iterateSegments(newPath)) {
    if (!path.contains(segment.point)) {
      segment.remove();
    }
  }
}

function preparePath(path: paper.Path, offset: number): [paper.Path, number] {
  const source = path.clone({ insert: false });
  source.reduce({});
  if (!path.clockwise) {
    source.reverse();
    offset = -offset;
  }
  return [source, offset];
}

function offsetShape(
  path: paper.Path,
  offset: number,
  limit: number,
  allowIntersections: boolean = false,
): DrawingsAllowedPathType {
  let source: paper.Path;
  [source, offset] = preparePath(path, offset);
  const curves = source.curves.slice();
  const offsetEdges = [];
  for (const curve of curves) {
    arrayUtils.extendArray(offsetEdges, adaptiveOffsetEdge(curve, offset));
  }
  const raws: paper.Segment[][] = [];
  for (let i = 0; i < offsetEdges.length; i += 2) {
    raws.push(offsetEdges.slice(i, i + 2));
  }
  const segments = arrayUtils.flatArray(connectSegments(raws, source, offset, limit));
  let newPath: DrawingsAllowedPathType = new paper.Path({ segments, insert: false, closed: path.closed });
  if (!allowIntersections) {
    newPath = removeIntersection(newPath);
  }
  newPath.reduce({});
  if (source.closed && ((source.clockwise && offset < 0) || (!source.clockwise && offset > 0))) {
    removeOutsiders(newPath, path);
  }
  if (source.clockwise !== path.clockwise) {
    newPath.reverse();
  }
  return normalize(newPath);
}


function connectSide(outer: DrawingsAllowedPathType, inner: paper.Path, oneWay: boolean): paper.Path {
  if (outer instanceof paper.CompoundPath) {
    outer = outer.children.slice().sort((c1, c2) => Math.abs(c2.area) - Math.abs(c1.area))[0];
  }
  const oSegments = outer.segments.slice();
  const iSegments = inner.segments.slice();
  const outerPoint = oSegments[0].point;
  if (!oneWay && outerPoint.subtract(inner.lastSegment.point).length > outerPoint.subtract(iSegments[0].point).length) {
    iSegments.reverse();
  }
  return new paper.Path({ segments: [...oSegments, ...iSegments], closed: true, insert: false });
}

function getNonSelfIntersectionPath(path: DrawingsAllowedPathType): DrawingsAllowedPathType {
  if (path.closed) {
    return path.unite(path, { insert: false }) as DrawingsAllowedPathType;
  }
  return path;
}

function offsetLineWithoutMerge(path: paper.Path, offset: number): DrawingsAllowedPathType {
  const positiveOffset = offsetShape(path, offset / 2, offset, true);
  const negativeOffset = offsetShape(path, -offset / 2, offset, true) as paper.Path;
  negativeOffset.reverse();


  const final = connectSide(positiveOffset, negativeOffset, true) as DrawingsAllowedPathType;
  final.closed = true;
  return final;
}

function offsetSimpleStroke(
  path: paper.Path,
  offset: number,
  limit: number,
  oneWay: boolean,
): DrawingsAllowedPathType {
  const positiveOffset = offsetShape(path, offset, limit);
  const negativeOffset = oneWay ? offsetShape(path, -offset, limit) : path;
  let result: DrawingsAllowedPathType;
  if (path.closed) {
    result = positiveOffset.subtract(negativeOffset, { insert: false }) as DrawingsAllowedPathType;
  } else {
    let inner = negativeOffset;
    let holes = new Array<paper.Path>();
    if (negativeOffset instanceof paper.CompoundPath) {
      holes = negativeOffset.children.filter((c) => c.closed);
      holes.forEach((h) => h.remove());
      inner = negativeOffset.children[0];
    }
    inner.reverse();
    let final = connectSide(positiveOffset, inner as paper.Path, oneWay) as DrawingsAllowedPathType;
    if (holes.length > 0) {
      for (const hole of holes) {
        final = final.subtract(hole, { insert: false }) as DrawingsAllowedPathType;
      }
    }
    final.closed = true;
    result = getNonSelfIntersectionPath(final);
  }
  sortChildren(result);
  return result;
}


const LENGTH_THRESHOLD = 0.000001;

function findEdgeOfPoint(
  sourcePath: DrawingsAllowedPathType,
  pointInPath: paper.Point,
): [paper.Point, paper.Point] {
  for (const [p1, p2] of DrawingsPaperUtils.iterateEdges(sourcePath)) {
    const line = new paper.Path.Line(p1, p2);
    if (line.getNearestPoint(pointInPath).subtract(pointInPath).length < LENGTH_THRESHOLD) {
      line.remove();
      return [p1, p2];
    }
    line.remove();
  }
  return null;
}

function calculateMaxChildOffset({ bounds }: paper.Path): number {
  return Math.min(bounds.width, bounds.height) / 2;
}

function addPathToResult(
  child: paper.Path,
  offset: number,
  limit: number,
  path: DrawingsAllowedPathType,
  offsetParts: DrawingsAllowedPathType[],
): void {
  if (!isSameDirection(child, path)) {
    child.reverse();
  }
  let offsetedPath = offsetShape(child, offset, limit);
  offsetedPath = normalize(offsetedPath);
  if (offsetedPath.clockwise !== child.clockwise) {
    offsetedPath.reverse();
  }
  if (offsetedPath instanceof paper.CompoundPath) {
    offsetedPath.applyMatrix = true;
    arrayUtils.extendArray(offsetParts, offsetedPath.children);
  } else {
    offsetParts.push(offsetedPath);
  }
}

function offsetClosedPath(
  path: DrawingsAllowedPathType,
  offset: number,
  limit: number,
): DrawingsAllowedPathType {
  let result = path;
  if (DrawingsPaperUtils.isCompoundPolygonPath(path)) {
    const offsetParts = [];
    addPathToResult(path.firstChild, offset, limit, path, offsetParts);
    for (let i = 1; i < path.children.length; i++) {
      const child = path.children[i];
      if (child.segments.length > 1 && calculateMaxChildOffset(child) >= limit) {
        addPathToResult(child, offset, limit, path, offsetParts);
      }
    }
    result = new paper.CompoundPath({ children: offsetParts, insert: false }) as DrawingsPaperPolygonPath;
  } else {
    result = offsetShape(path, offset, limit);
  }
  sortChildren(result);
  result.remove();
  return result;
}


function checkNeedToMerge(
  pathForCheck: DrawingsAllowedPathType,
  sourcePath: DrawingsAllowedPathType,
): boolean {
  if (DrawingsPaperUtils.isCompoundPolygonPath(sourcePath)) {
    if (
      DrawingsPaperUtils.isCompoundPolygonPath(pathForCheck)
      && DrawingsPaperUtils.hasPointInside(pathForCheck, sourcePath)
    ) {
      for (let i = 1; i < sourcePath.children.length; i++) {
        if (DrawingsPaperUtils.hasPointInside(sourcePath.children[i], pathForCheck)) {
          return true;
        }
      }
    }
  } else {
    if (DrawingsPaperUtils.hasPointInside(pathForCheck, sourcePath)) {
      return true;
    }
  }
  return false;
}

function needToMerge(
  pathForCheck: DrawingsAllowedPathType,
  sourcePath: DrawingsAllowedPathType,
): boolean {
  if (pathForCheck.intersects(sourcePath)) {
    return true;
  }

  const isReversedCheck = DrawingsPaperUtils.hasPointInside(sourcePath, pathForCheck);
  return isReversedCheck
    ? checkNeedToMerge(sourcePath, pathForCheck)
    : checkNeedToMerge(pathForCheck, sourcePath);
}

function offsetClosedPathStroke(
  path: DrawingsAllowedPathType,
  offset: number,
  limit: number,
): DrawingsAllowedPathType[] {
  let result = new Array<DrawingsAllowedPathType>();
  if (DrawingsPaperUtils.isCompoundPolygonPath(path)) {
    const absOffset = Math.abs(offset);
    result.push(offsetSimpleStroke(path.firstChild, absOffset, limit, true));
    for (let i = 1; i < path.children.length; i++) {
      let element = offsetSimpleStroke(path.children[i], -absOffset, limit, true);
      for (let j = 0; j < result.length; j++) {
        if (!result[j]) {
          continue;
        }
        if (needToMerge(element, result[j])) {
          element = element.unite(result[j]) as DrawingsAllowedPathType;
          result[j].remove();
          result[j] = null;
        }
      }
      result.push(element);
    }
    result = result.filter(x => x);
  } else {
    result = [offsetSimpleStroke(path, offset, limit, true)];
  }
  return result;
}
async function polylineAsyncOffset<T, R = IntPoint>(
  polyline: T[],
  getPoint: (point: T) => { x: number, y: number },
  offsetDistance: number,
  convertToResult: (point: IntPoint) => R = p => p as unknown as R,
): Promise<R[][]> {
  const clipper = await ClipperLoaderWrapper.getInstanceAsync();
  const paths: IntPoint[][] = clipper.offsetToPaths({
    offsetInputs: [{
      joinType: JoinType.Miter,
      endType: EndType.OpenButt,
      data: polyline.map(p => {
        const point = getPoint(p);
        return { x: point.x * 1e6, y: point.y * 1e6 };
      }),
    }],
    delta: offsetDistance * 1e6,
    miterLimit: Math.round(this._windowSize),
  });
  return paths.map(p => p.map(point => convertToResult({ x: point.x / 1e6, y: point.y / 1e6 })));
}

export const DrawingsOffsetUtils = {
  offsetSimpleStroke,
  findEdgeOfPoint,
  offsetClosedPath,
  offsetShape,
  offsetLineWithoutMerge,
  offsetClosedPathStroke,
  polylineAsyncOffset,
};
