import { arrayUtils } from 'common/utils/array-utils';
import { GroupFolders } from '../../folder-container';

export function createPath(
  groups: GroupFolders[],
  groupId: string,
): GroupFolders[] {
  const groupsMap = arrayUtils.toDictionary(groups, x => x.id, x => x);
  let id = groupId;
  const path = [];
  while (groupsMap[id]) {
    path.push(groupsMap[id]);
    id = groupsMap[id].parentId;
  }
  return path.reverse();
}
