import { Text } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { Badge } from 'common/components/badge';
import { ElementTooltip } from '../../tooltip';
import { Styled } from './styled';

interface Props {
  id: string;
  onClick: (id: string) => void;
  isActive: boolean;
  isFocus: boolean;
  content: string;
  showBadge: boolean;
}

export const DropDownTextItem: React.FC<Props> = (props) => {
  const {
    onClick,
    id,
    content,
    isActive,
    isFocus,
    showBadge,
  } = props;
  const onItemClick = useCallback(
    (e) => {
      e.stopPropagation();
      onClick(id);
    },
    [id],
  );

  const showElementTooltip = content?.length >= 20;
  return (
    <ElementTooltip
      text={content}
      position={'left'}
      speed={'s'}
      wordBreakAll={true}
      disabled={!showElementTooltip}
    >
      <Styled.ItemText isFocus={isFocus} isActive={isActive} onClick={onItemClick}>
        {showBadge && <Badge badgeText='Create' />}
        <Text
          fontSize={14}
          nowrap={true}
          withEllipsis={true}
        >{content}</Text>
      </Styled.ItemText>
    </ElementTooltip>
  );
};
