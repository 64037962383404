import { objectUtils } from 'common/utils/object-utils';
import { DrawingProcessingState } from '../enums';
import { DrawingsShortInfo } from '../interfaces';
import { DrawingSnapping } from '../interfaces/drawing';
import { DrawingsFile } from '../interfaces/drawings-file-info';
import { DocsLoader } from '../interfaces/util';
import { getCurrentDocWithSnapping } from './pdf-current-doc-getter';
import { DrawingInfo } from './viewport/interfaces';

interface ViewportHelper {
  drawingInfo: DrawingInfo;
  resetZoom: (zoom: number) => void;
  renderMainPdfLayout: (rotation: number) => Promise<boolean>;
}


interface DrawingOpenerConfig {
  loader: DocsLoader;
  viewportHelper: ViewportHelper;
  isSnappingEnabled: () => boolean;
  setSnapping: (drawingId: string, snapping: DrawingSnapping) => void;
  getCurrentRotation: () => number;
  getCurrentDrawing: () => DrawingsShortInfo;
  onProcessingChange: (state: DrawingProcessingState, drawingId: string) => void;
}

export class DrawingsSelector {
  private _config: DrawingOpenerConfig;

  private _loadingCanceler: () => void;

  constructor(config: DrawingOpenerConfig) {
    this._config = config;
  }

  public cancel(): void {
    if (this._loadingCanceler) {
      this._loadingCanceler();
    }
  }

  public async selectDrawing(
    drawing: DrawingsShortInfo,
    drawings: Record<string, DrawingsShortInfo>,
    files: Record<string, DrawingsFile>,
  ): Promise<boolean> {
    this.cancel();
    const { promise, cancel } = getCurrentDocWithSnapping(
      {
        loader: this._config.loader,
        drawings,
        currentDrawingInfo: drawing,
        files,
        isSnappingEnabled: this._config.isSnappingEnabled,
      },
      (state) => this._config.onProcessingChange(state, drawing.drawingId),
    );
    this._loadingCanceler = cancel;
    const result = await promise;
    this._loadingCanceler = null;
    if (!drawing.isOptimized && this._config.isSnappingEnabled()) {
      this._config.setSnapping(drawing.drawingId, result.snapping);
    }
    if (this.isActualLoaded(drawing)) {
      this._config.viewportHelper.drawingInfo = result.docInfo;
      this._config.viewportHelper.resetZoom(1);
      const rotation = this._config.getCurrentRotation();
      return this._config.viewportHelper.renderMainPdfLayout(rotation);
    }
  }


  protected isActualLoaded(drawing: DrawingsShortInfo): boolean {
    const current = this._config.getCurrentDrawing();
    if (!current) {
      return false;
    }
    return objectUtils.areEqualByFields(
      drawing,
      current,
      ['drawingId', 'pagesCompareSettings', 'pageToCompareId'],
    );
  }
}

