import styled from 'styled-components';

const Container = styled.div<{ backgroundColor: string }>`
  box-shadow: 0 0 0 1px ${p => p.theme.color.pale};
  background-color: ${p => p.theme.color[p.backgroundColor] || p.backgroundColor};
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  min-width: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    box-shadow: 0 0 0 2px ${p => p.theme.color.pale};
  }
`;

export const Styled = {
  Container,
};
