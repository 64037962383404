import { RectangleButton, Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { MixedPropertyContextProvider } from 'common/components/form/mixed-property-context-provider';
import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { ConstantFunctions } from 'common/constants/functions';
import { Mood } from 'common/enums/mood';
import { SelectEvents } from 'unit-2d-info-panel/content/selection-view/assign-form/constants';
import { Action } from 'unit-2d-info-panel/content/selection-view/assign-form/helpers';
import { AnalyticsProps, withAnalyticsContext } from 'utils/posthog';
import { AssemblyGroupForm } from '../assembly-group-form/assembly-group-form';
import { FormulaDialog } from '../formula-dialog';
import { ItemGroupForm } from '../group-item-form';
import { OverrideLabel } from '../override-label';
import { VisibilityPanel } from '../visibility-panel';
import { ASSEMBLY_GROUP_FORM_KEY, ITEM_GROUP_FORM_KEY } from './constants';
import {
  useAssemblyPanel,
  useGroupKeysCallback,
  useIsShowNotification,
  useItemPanel,
  useOpenAssemblyPanelCallback,
  useOpenItemPanelCallback,
  usePiaData,
  useSetFormEffects,
  useToggleGroupsCallBack,
  useUnits,
} from './hooks';
import { MeasurePanelAssemblyAfterEffects, MeasurePanelItemAfterEffects } from './interfaces';
import { connectRedux, ReduxProps } from './redux-connector';
import { Styled } from './styled';

interface Props extends ReduxProps, AnalyticsProps {
  action?: Action<SelectEvents>;
  assembliesAfterEffects: MeasurePanelAssemblyAfterEffects;
  itemsAfterEffects: MeasurePanelItemAfterEffects;
  isAssignReady: boolean;
  openSelectPanel: () => void;
  isSelectPanelOpen: boolean;
}

const MeasurePanelComponent = ({
  visibilityMode,
  collapsedGroups,
  setCollapsedGroups,
  itemsDatabase,
  openSidePanel,
  setAssemblyForm,
  setItemForm,
  closePanel,
  assembliesAfterEffects,
  itemsAfterEffects,
  assembliesDatabase,
  propertiesDatabase,
  openFormulaDialog,
  openBreakdownDialog,
  action,
  isAssignReady,
  setAssignPiaLoad,
  openSelectPanel,
  isSelectPanelOpen,
}: Props): JSX.Element => {
  const units = useUnits(propertiesDatabase);
  const [assemblies, items, variants] = usePiaData();
  const assemblyForm = useAssemblyPanel(
    assemblies,
    units,
    assembliesAfterEffects,
    assembliesDatabase,
    itemsDatabase,
    propertiesDatabase,
    openFormulaDialog,
    openBreakdownDialog,
    action,
    setAssignPiaLoad,
  );
  const itemForm = useItemPanel(
    items,
    units,
    itemsAfterEffects,
    propertiesDatabase,
    itemsDatabase,
    action,
    openFormulaDialog,
    openBreakdownDialog,
    setAssignPiaLoad,
  );
  useSetFormEffects(
    assemblyForm,
    itemForm,
    assembliesAfterEffects.afterSetForm,
    itemsAfterEffects.afterSetForm,
  );
  const canManage = useAbility(Operation.Manage, Subject.Takeoff2dPiaAssignment);
  const isShowNotification = useIsShowNotification(assemblyForm, itemForm) || !canManage;
  const getGroupKeys = useGroupKeysCallback(assemblyForm, itemForm);
  const toggleGroup = useToggleGroupsCallBack(collapsedGroups, setCollapsedGroups);
  const openAssemblyPanel = useOpenAssemblyPanelCallback(
    assemblyForm,
    itemsDatabase,
    openSidePanel,
    setAssemblyForm,
    closePanel,
  );
  const openItemPanel = useOpenItemPanelCallback(itemForm, openSidePanel, setItemForm, closePanel);
  const hasVariants = !!variants && !!Object.values(variants.variants).length;

  return (
    <Styled.Container>
      <Styled.PanelBlock showNotification={isShowNotification}>
        <VisibilityPanel
          groups={assemblyForm.assemblyGroupForm}
          getGroupKeys={getGroupKeys}
          openSelectPanel={openSelectPanel}
          isSelectPanelOpen={isSelectPanelOpen}
        />
        <Styled.ScrollBarContainer>
          <RenderIf condition={!isShowNotification}>
            <OverrideLabel>
              <MixedPropertyContextProvider
                propertyVariants={variants?.variants}
                getIdByVariant={variants?.getIdsByVariant}
              >
                <AssemblyGroupForm
                  groups={assemblyForm.assemblyGroupForm}
                  visibilityMode={visibilityMode}
                  collapsedGroups={collapsedGroups}
                  toggleGroup={toggleGroup}
                  parentId={ASSEMBLY_GROUP_FORM_KEY}
                  hideDeleteButton={true}
                  editAssembly={!hasVariants && openAssemblyPanel}
                  disabled={!isAssignReady}
                  withInhered={true}
                />
                <ItemGroupForm
                  groups={itemForm.itemGroupForm}
                  visibilityMode={visibilityMode}
                  collapsedGroups={collapsedGroups}
                  toggleGroup={toggleGroup}
                  parentId={ITEM_GROUP_FORM_KEY}
                  hideDeleteButton={true}
                  editItem={!hasVariants && openItemPanel}
                  disabled={!isAssignReady}
                  withInhered={true}
                />
              </MixedPropertyContextProvider>
            </OverrideLabel>
          </RenderIf>
          <RenderIf condition={isShowNotification}>
            <Styled.EmptyList>
              <Text fontSize={14}>
                No applied items and assemblies yet
              </Text>
              <UpgradeWrapper isNeedUpdate={!canManage}>
                <RectangleButton
                  height={30}
                  text='Choose'
                  onClick={canManage ? openSelectPanel : ConstantFunctions.doNothing}
                  mood={canManage ? Mood.Secondary : Mood.Disabled}
                  fontSize={14}
                />
              </UpgradeWrapper>
            </Styled.EmptyList>
          </RenderIf>
        </Styled.ScrollBarContainer>
      </Styled.PanelBlock>
      <FormulaDialog />
      <RenderIf condition={!isAssignReady}>
        <Spinner show={!isAssignReady} withBackground={true} />
      </RenderIf>
    </Styled.Container>
  );
};

export const MeasurePanel = connectRedux(withAnalyticsContext(MeasurePanelComponent));
