import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconSortZA: React.FC = () => {
  return (
    <svg
      width='14px'
      height='14px'
      viewBox='0 0 14 14'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M4,3.70710678 L4,11.5 C4,11.7761424 3.77614237,12 3.5,12 C3.22385763,12 3,
          11.7761424 3,11.5 L3,3.70710678 L1.35355339,5.35355339 C1.15829124,5.54881554 0.841708755,
          5.54881554 0.646446609,5.35355339 C0.451184464,5.15829124 0.451184464,4.84170876 0.646446609,
          4.64644661 L3.14644661,2.14644661 C3.34170876,1.95118446 3.65829124,1.95118446 3.85355339,
          2.14644661 L6.35355339,4.64644661 C6.54881554,4.84170876 6.54881554,5.15829124 6.35355339,
          5.35355339 C6.15829124,5.54881554 5.84170876,5.54881554 5.64644661,5.35355339 L4,3.70710678 Z'
          fill={KreoColors.f3}
          fillRule='nonzero'
        />
        <path
          d='M11,10.2928932 L12.6464466,8.64644661 C12.8417088,8.45118446 13.1582912,8.45118446 13.3535534,
          8.64644661 C13.5488155,8.84170876 13.5488155,9.15829124 13.3535534,9.35355339 L10.8535534,
          11.8535534 C10.6582912,12.0488155 10.3417088,12.0488155 10.1464466,11.8535534 L7.64644661,
          9.35355339 C7.45118446,9.15829124 7.45118446,8.84170876 7.64644661,8.64644661 C7.84170876,
          8.45118446 8.15829124,8.45118446 8.35355339,8.64644661 L10,10.2928932 L10,3 C10,
          2.72385763 10.2238576,2.5 10.5,2.5 C10.7761424,2.5 11,2.72385763 11,3 L11,10.2928932 Z'
          fill='#DDE2EB'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
