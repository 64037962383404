import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { DrawingsRendererApiContextProvider } from 'common/components/drawings/drawings-contexts';
import {
  DrawingsPdfLinkPlansWrapper,
} from 'common/components/drawings/drawings-pdf-browser/drawings-pdf-browser-content/drawings-pdf';
import { Content } from 'common/components/page-header/content-manager';
import { Spinner } from 'common/components/spinner';
import { RoleCode } from 'common/enums/role-code';
import { State } from 'common/interfaces/state';
import { SmallScreenDialog } from 'common/small-screen-dialog';
import { UndoRedoController } from 'common/undo-redo';
import { CommentsProvider } from 'unit-2d-comments/comments-context';
import { CommentsPanel } from 'unit-2d-comments/comments-panel/comments-panel';
import { SidePanel } from 'unit-2d-database/components';
import { pushItemToUrl } from 'unit-2d-database/helpers/current-tab-getters';
import { FastNavigationLinks } from '../../../units/2d/constants/fast-navigation-links';
import { AccountSelectors } from '../../../units/account/selectors';
import { ProjectRoutes } from '../routes/project';
import { getFastNavigationContents } from './get-fast-navigation-helpers';
import { Styled } from './styled';
import { TwoDDatabaseFastNavigationPage } from './two-d-database-fast-navigation-page';
import { TwoDReportTemplateFastNavigation } from './two-d-report-template-fast-navigation';


interface StateProps {
  projectsToDelete: Record<string, boolean>;
  isRoleGuest: boolean;
}

interface Props extends RouteComponentProps<{ projectId: string }>, AbilityAwareProps, StateProps {}

class TwoDProjectFastNavigationComponent extends React.PureComponent<Props> {
  private manager: React.ComponentType;
  private link: React.ComponentType;
  private contents: Content[] = [
    {
      title: 'Project',
      Component: ProjectRoutes,
      type: FastNavigationLinks.Project,
      isAlwaysRender: () => true,
      styled: Styled.TwoDPageContainer,
      onClick: () => pushItemToUrl(FastNavigationLinks.Project, this.props.history.push),
      isDisabled: () => false,
    },
    {
      title: 'Plans',
      Component: DrawingsPdfLinkPlansWrapper,
      type: FastNavigationLinks.Plans,
      isAlwaysRender: () => false,
      styled: Styled.DrawingsPdfBrowserContainer,
      onClick: () => pushItemToUrl(FastNavigationLinks.Plans, this.props.history.push),
      isDisabled: () => false,
    },
    {
      title: 'Assemblies',
      Component: TwoDDatabaseFastNavigationPage,
      type: FastNavigationLinks.Assemblies,
      isAlwaysRender: () => false,
      styled: Styled.TwoDDatabaseContainer,
      onClick: () => pushItemToUrl(FastNavigationLinks.Assemblies, this.props.history.push),
      isDisabled: () => this.props.isRoleGuest,
      isUpgrade: () => this.props.ability && this.props.ability.cannot(Operation.Read, Subject.PiaDatabase),
    },
    {
      title: 'Report template',
      Component: TwoDReportTemplateFastNavigation,
      type: FastNavigationLinks.ReportTemplate,
      isAlwaysRender: () => false,
      styled: Styled.TwoDDatabaseContainer,
      onClick: () => pushItemToUrl(FastNavigationLinks.ReportTemplate, this.props.history.push),
      isDisabled: () => this.props.ability
        && this.props.ability.cannot(Operation.Read, Subject.Takeoff2dReportTemplateShow),
      isUpgrade: () => this.props.ability
        && this.props.ability.cannot(Operation.Manage, Subject.Takeoff2dReportTemplate),
    },
  ];

  constructor(props: Props) {
    super (props);
    const { manager, link } = getFastNavigationContents(this.contents);
    this.manager = manager;
    this.link = link;
  }

  public render(): JSX.Element {
    const ContentManager = this.manager;
    const FastNavigation = this.link;

    if (this.props.projectsToDelete[this.props.match.params.projectId]) {
      return (
        <Styled.SpinnerContainer>
          <Spinner show={true} fullScreen={true} withBackground={true}/>
        </Styled.SpinnerContainer>
      );
    }

    return (
      <CommentsProvider>
        <FastNavigation {...this.props} />
          <Styled.UndoRedoControllerWrapper>
            <DrawingsRendererApiContextProvider>
              <UndoRedoController>
                <ContentManager {...this.props} />
              </UndoRedoController>
            </DrawingsRendererApiContextProvider>
          </Styled.UndoRedoControllerWrapper>
        <SidePanel />
        <CommentsPanel/>
        <SmallScreenDialog />
      </CommentsProvider>
    );
  }
}


function mapStateToProps(state: State): StateProps {
  const isRoleGuest = AccountSelectors.currentSubscription(state).userRoleCode === RoleCode.Guest;
  return {
    projectsToDelete: state.projects.projectsToDelete,
    isRoleGuest,
  };
}

const ConnectedToRedux = connect(mapStateToProps)(TwoDProjectFastNavigationComponent);

export const TwoDProjectFastNavigation = withAbilityContext(ConnectedToRedux);
