import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import './index.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { Stepper } from '../../../../actions';
import { ScenarioRouteParams } from '../../../../routes/app-routes-params';
import { Master } from '../../components';
import { ScenarioEditPage, ScenariosData } from '../../interfaces';
import { Packages } from '../packages';

interface ReduxProps {
  scenario: ScenariosData.Scenario;
}

interface ReduxActions {
  setStep: (step: number) => void;
}

interface Props
  extends ReduxProps,
    ReduxActions,
    RouteComponentProps<ScenarioRouteParams> {}

class WorkPackagesPage extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);
    this.props.setStep(ScenarioEditPage.WorkPackages);
  }

  public render(): JSX.Element {
    const { scenario, match } = this.props;
    return (
      <Master scenario={scenario} projectId={match.params.projectId}>
        <div className='work-package-page-container'>
          <Packages projectId={+match.params.projectId} scenarioId={+match.params.scenarioId} />
        </div>
      </Master>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    scenario: state.scenarios.editScenario,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    setStep: (step: number) => {
      dispatch(Stepper.setStep(step));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const WorkPackages = connector(WorkPackagesPage);
