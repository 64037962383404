import autobind from 'autobind-decorator';
import { EngineObject, EngineObjectConfig } from '../../common';
import { RotationProcessor } from '../../interfaces';
import { DragEventWrapper, DrawingsDragCallbackParams } from '../drag-instances';

export interface RotationProcessorConfig extends EngineObjectConfig {
  dragProcessingHelper: DragEventWrapper;
  changeRotation: (diffAngle: number, finish: boolean) => boolean;
  getCenterPoint: () => paper.Point;
}

export class BaseRotationProcessor<T extends RotationProcessorConfig = RotationProcessorConfig>
  extends EngineObject<T>
  implements RotationProcessor {
  protected _angle: number;
  protected _centerPoint: paper.Point;

  public startRotate(point: paper.Point): void {
    this._centerPoint = this._config.getCenterPoint();
    this._angle = point.subtract(this._centerPoint).angle;
    this._config.dragProcessingHelper.setCallback(this.changeRotation, { defaultValidPoint: point });
  }

  @autobind
  protected changeRotation({ point, finish }: DrawingsDragCallbackParams): boolean {
    const angle = point.subtract(this._centerPoint).angle;
    const diffAngle = angle - this._angle;
    this._angle = angle;
    return this._config.changeRotation(diffAngle, finish);
  }
}
