import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { SvgSpinner } from 'common/components/svg-spinner';
import { FaqCaption } from 'common/enums/faq-caption';
import { HeaderContext } from 'common/enums/header-context';
import { PageHeader } from 'common/interfaces/page-header';
import { State } from 'common/interfaces/state';
import { RevisionsActions } from 'unit-cost-estimate/actions/creators';
import { CostEstimateValidationStep, RevisionInfoModel, RevisionState } from 'unit-cost-estimate/interfaces';
import { CostEstimatePage } from 'unit-cost-estimate/pages/cost-estimate';
import { isCostEstimateValidationStepGreaterThan as stepGrateThan } from 'unit-cost-estimate/utils/validation-step';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { CostEstimateProjectRevisionRouteParams } from '..';
import { PageLayout } from '../../../layouts/page-layout';
import { NotFoundPage } from '../../../units/notfound/page';
import { PersistedStorageActions } from '../../../units/persisted-storage/actions/creators';
import { Urls } from '../urls';
import { ActivityAssignmentRoutes } from './activity-assignment';
import { MeasurementsRoutes } from './measurements';


interface StateProps {
  projectId: number;
  projectName: string;
  revisionsInfo: RevisionInfoModel[];
  revisionState: RevisionState;
}

interface RoutesDispatchProps {
  changeProjectName: (name: string) => void;
  setProjectRevisionId: (projectId: number, revisionId: number) => void;
  fetchRevisionState: (revisionId: number) => void;
}

interface RoutesProps
  extends StateProps,
    RoutesDispatchProps,
    RouteComponentProps<CostEstimateProjectRevisionRouteParams>,
    AbilityAwareProps {}

const SpinnerPage: React.FC = () => <SvgSpinner size='large' />;

class RevisionRoutesComponent extends React.Component<RoutesProps> {
  public componentDidMount(): void {
    this.fetchRevisionStateIfNeeded();
  }

  public componentDidUpdate(prevProps: RoutesProps): void {
    this.fetchRevisionStateIfNeeded(prevProps);
  }

  public render(): React.ReactNode {
    const { match, revisionState, revisionsInfo } = this.props;

    if (revisionsInfo && !this.isRevisionInProject()) {
      return <Redirect to={this.getLastRevisionIndexUrl()} />;
    }

    if (!revisionState) {
      return <Route component={SpinnerPage} />;
    }

    const step = revisionState.validationStep;
    const indexProjectPageUrl = Urls.project.index.url(match.params);

    return (
      <Switch>
        <Redirect exact={true} path={Urls.project.revision.index.path} to={this.getLastNotApprovedStepUrl()}/>
        {
          stepGrateThan(step, CostEstimateValidationStep.ActivityAssignment, true) &&
            <Route path={Urls.project.revision.activityAssignment.index.path} component={ActivityAssignmentRoutes}/>
        }
        {
          stepGrateThan(step, CostEstimateValidationStep.Measurements, true) &&
          <Route path={Urls.project.revision.measurements.index.path} component={MeasurementsRoutes}/>
        }
        {
          stepGrateThan(step, CostEstimateValidationStep.Reports, true) &&
            <PageLayout
              exact={true}
              metaTitle='Cost Estimate'
              path={Urls.project.revision.costEstimate.path}
              backgroundColor='white'
              component={CostEstimatePage}
              header={this.getProjectHeader()}
              subject={Subject.CostEstimate}
              redirectUrl={indexProjectPageUrl}
            />
        }
        <Route component={NotFoundPage} />
      </Switch>
    );
  }

  private isRevisionInProject(): boolean {
    const { revisionsInfo, match } = this.props;
    const revisionId = parseInt(match.params.revisionId, 10);
    return revisionsInfo && revisionsInfo.some(x => x.id === revisionId);
  }

  private getLastNotApprovedStepUrl(): string {
    const { match, revisionState } = this.props;
    const revisionUrls = Urls.project.revision;
    const params = match.params;

    switch (revisionState.validationStep) {
      case CostEstimateValidationStep.ActivityAssignment:
        return revisionUrls.activityAssignment.index.url(params);
      case CostEstimateValidationStep.Measurements:
        return revisionUrls.measurements.index.url(params);
      case CostEstimateValidationStep.Reports:
        return revisionUrls.costEstimate.url(params);
      default: return null;
    }
  }

  private getLastRevisionIndexUrl(): string {
    const { match, revisionsInfo } = this.props;
    if (revisionsInfo.length) {
      return Urls.project.revision.index.url({
        projectId: match.params.projectId,
        revisionId: revisionsInfo[revisionsInfo.length - 1].id.toString(),
      });
    }

    return Urls.project.index.url(match.params);
  }

  private fetchRevisionStateIfNeeded(prevProps?: RoutesProps): void {
    const revisionId = parseInt(this.props.match.params.revisionId, 10);
    const prevRevisionId = prevProps && parseInt(prevProps.match.params.revisionId, 10);
    const currentProjectId = this.props.projectId;

    if (currentProjectId && revisionId && revisionId !== prevRevisionId) {
      this.props.setProjectRevisionId(currentProjectId, revisionId);
      this.props.fetchRevisionState(revisionId);
    }
  }

  private getProjectHeader(faqCaption?: FaqCaption, videoUrl?: string): PageHeader {
    return {
      context: HeaderContext.ProjectRevision,
      backUrl: Urls.listing.url(),
      title: this.props.projectName,
      faqCaption,
      videoUrl,
      onEditTitle: this.props.ability.can(Operation.Update, Subject.Project)
        ? this.props.changeProjectName
        : null,
      showLeftMenu: true,
    };
  }
}

function mapStateToProps(state: State, ownProps: RoutesProps): StateProps {
  const currentProject = state.projects.currentProject;
  const projectId = currentProject && currentProject.id;
  const projectName = currentProject && currentProject.name || '';
  const revisionsInfo = currentProject && currentProject.revisions;
  const revisionId = parseInt(ownProps.match.params.revisionId, 10);
  const revisionState = state.projects.revisionStates[revisionId];

  return {
    projectId,
    projectName,
    revisionsInfo,
    revisionState,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): RoutesDispatchProps {
  return {
    fetchRevisionState: revisionId => dispatch(RevisionsActions.fetchRevisionsState(revisionId)),
    changeProjectName: (name: string) => dispatch(ProjectsActions.updateProjectName(name)),
    setProjectRevisionId: (projectId, revisionId) =>
      dispatch(PersistedStorageActions.persistSelectedProjectRevisionId(projectId, revisionId)),
  };
}

export const RevisionRoutes = withAbilityContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RevisionRoutesComponent),
);
