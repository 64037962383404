import { Text } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Styled } from './styled';


interface Props {
  description: string;
  value: number;
  unit?: string;
}


export const  FooterRow: React.FC<Props> = ({ description, value, unit }: Props) => {
  const defaultUnit = unit ? unit : '£';

  return (
    <Styled.FooterContainer key={description}>
      <Styled.FooterCell>
        <Styled.Description>
          <Text>{description}</Text>
        </Styled.Description>
        <Styled.Value>
          <Text>{defaultUnit}{value}</Text>
        </Styled.Value>
      </Styled.FooterCell>
    </Styled.FooterContainer>
  );
};
