import * as THREE from 'three';
import { DrawingsPolygonGeometry, ShortPointDescription } from 'common/components/drawings/interfaces';
import { ThreeDMeasuresCreators } from 'common/components/drawings/utils/3d-geometries/3d-measures-creators';
import { Geometry } from './geometry';

export class Polygon extends Geometry<DrawingsPolygonGeometry> {
  protected addHolesIfNeed(shape: THREE.Shape, geometry: DrawingsPolygonGeometry): void {
    if (geometry.children) {
      const pathes = new Array<THREE.Path>(geometry.children.length);
      for (let i = 0; i < geometry.children.length; i++) {
        const path = new THREE.Path();
        const linePoints = [];
        this.fillPath(path, geometry.children[i], linePoints);
        this._linePoints.push(linePoints);
        pathes[i] = path;
      }
      shape.holes = pathes;
    }
  }

  protected getConvertedGeometry(geometry: DrawingsPolygonGeometry): { indices: Uint16Array, vertices: Float32Array } {
    const points = geometry.points.map(this.convertPoint);

    const children = geometry.children ? geometry.children.map(child => child.map(this.convertPoint)) : [];
    return ThreeDMeasuresCreators.createPitched3DPolygonWithHeightAndHoles(
      {
        polygon2D: points,
        polygonHoles2D: children,
        height: this._height,
      },
    );
  }

  private fillPath(path: THREE.Path, points: string[], linePoints: ShortPointDescription[]): void {
    const start = this._config.getPointInfo(points[0]);
    path.moveTo(start[0], -start[1]);
    linePoints.push([start[0], -start[1]]);
    for (let i = 1; i < points.length; i++) {
      const point = this._config.getPointInfo(points[i]);
      path.lineTo(point[0], -point[1]);
      linePoints.push([point[0], -point[1]]);
    }
    path.lineTo(start[0], -start[1]);
    linePoints.push([start[0], -start[1]]);
  }
}
