import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { UndoRedoContextApiProps, withUndoRedoApiProps } from 'common/undo-redo';
import { UndoRedoActionMethods } from 'common/undo-redo/undo-redo-context-provider';
import { arrayUtils } from 'common/utils/array-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { PersistedStorageDrawingsSelectors } from 'persisted-storage/selectors/drawings-selectors';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { AssignedPia, TablePreset } from '../../../../../units/2d/interfaces';
import { DrawingsAnnotationActions } from '../../actions/creators/annotation';
import { DrawingsAnnotationLegendActions } from '../../actions/creators/drawings-annotation-legend';
import { InstancesVisibilityActions } from '../../actions/creators/instances-visibility';
import { DrawingsUpdateActions } from '../../actions/creators/update';
import {
  BulkGroupsChangePayload,
  CreateDrawingGroup,
  CreateDrawingGroupsTree,
  MoveDrawingGroupTree,
  UpdateDrawingGroup,
  UpdateDrawingGroupTree,
} from '../../actions/payloads/drawings-annotation-legend';
import { DrawingGroupChanges } from '../../actions/payloads/update';
import { DrawingDialogs } from '../../constants/drawing-dialogs';
import { DrawingChangeOperation } from '../../enums/drawing-change-operation';
import { DrawingChangeSource } from '../../enums/drawing-change-source';
import { DrawingsDrawMode } from '../../enums/drawings-draw-mode';
import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { DrawingsGeometryGroup } from '../../interfaces/drawings-geometry-group';
import {
  DrawingsGeometryInstance,
  DrawingsGeometryInstanceWithId,
  UpdateGroupInDrawingsPayload,
} from '../../interfaces/drawings-geometry-instance';
import { DrawingAnnotationNamingUtils } from '../../utils/drawing-annotation-naming-utils';
import { DrawingAnnotationUtils } from '../../utils/drawing-annotation-utils';
import { DrawingsCommonUtils } from '../../utils/drawings-common-utils';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';
import { DrawingsGroupUtils } from '../../utils/drawings-group-utils';
import {
  DrawingsUndoRedoHelper,
  GroupActionData,
  GroupPayloadForInstanceCreate,
} from '../../utils/drawings-undo-redo-helper';
import { DrawingsInstanceMeasureHelper } from '../../utils/measures/get-drawings-instance-measure-helper';
import { DrawingsSelectionContextProps, withDrawingsSelectionContextProps } from '../drawings-selection-context';
import { DrawingsRendererApiContextProps, withRendererApiContext } from '../renderer-api-context';
import {
  AddInstancesPayload,
  AddInstancesWithUndoRedoPayload,
  DrawingsElementAndGroupOperationsContext,
  DrawingsElementAndGroupOperationsContextProps,
} from './element-and-group-operations-context';
import { WithStateConnectorProps, withStateConnector } from './with-state-connector';


interface OwnProps extends UndoRedoContextApiProps,
  DrawingsRendererApiContextProps,
  DrawingsSelectionContextProps,
  WithStateConnectorProps {
  canEditMeasurements: boolean;
  selectedInstances: string[];
  drawMode: DrawingsDrawMode;
  filteredElements: string[];
  colors: Record<string, string>;
  isKeepOriginName: boolean;
  groupForInstanceCreation: DrawingsGeometryGroup;
  drawingsInstancesValueHelper: DrawingsInstanceMeasureHelper;
  onMoveToCell: (instancesIds: string[], type: string) => void;
  onMoveTableToCell: (instancesIds: string[], preset: TablePreset) => void;
  onDynamicMoveToCell: (preset: TablePreset) => void;
  onTraceLink: (instanceId: string) => void;
  getTraceLinks: (instanceId: string) => string[];
  onFolderToCell: (type: string) => void;
}

interface StateProps {
  projectId: number;
  useGroupNameForNewGeometry: boolean;
  drawingGeometryGroups: DrawingsGeometryGroup[];
  instances: Record<string, DrawingsGeometryInstance>;
  points: Record<string, ShortPointDescription>;
  selectedGroups: string[];
  pinnedGroupIds: string[];
  assignedPia: Record<string, AssignedPia>;
}

interface DispatchProps {
  resetGroupsChange: (data: BulkGroupsChangePayload) => void;
  openDialog: (dialogName: string, data: any) => void;
  closeDialog: (dialogName: string) => void;
  updateDrawingsGroup: (data: UpdateDrawingGroupTree) => void;
  addDrawingsGroup: (data: CreateDrawingGroupsTree) => void;
  moveDrawingsGroup: (data: MoveDrawingGroupTree) => void;
  updateColorsInElements: (instancesIds: string[], color: string) => void;
  updateInstancesVisibility: (instancesToShow: string[], instancesToHide: string[]) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps, AnalyticsProps {

}

class ElementOperationsContextProvider extends React.PureComponent<Props> {

  private api: DrawingsElementAndGroupOperationsContextProps = {
    removeInstances: this.removeInstances,
    removeInstancesWithUndo: this.removeInstancesWithUndo,
    addInstances: this.processAddInstances,
    addInstancesWithUndo: this.addInstancesWithUndo,
    removeSelectedElementsFromGroups: this.removeSelectedElementsFromGroups,
    showSelectedInstances: this.showSelectedInstances,
    hideSelectedInstances: this.hideSelectedInstances,
    isolateSelectedInstances: this.isolateSelectedInstances,
    removeSelectedInstancesHandler: this.removeSelectedInstancesHandler,
    onMoveToCell: this.props.onMoveToCell,
    onFolderToCell: this.props.onFolderToCell,
    onMoveTableToCell: this.props.onMoveTableToCell,
    onDynamicMoveToCell: this.props.onDynamicMoveToCell,
    canTraceLink: this.canTraceLink,
    onTraceLink: this.props.onTraceLink,
    moveToGroupWithUndoRedo: this.moveToGroupWithUndoRedo,
    getInstanceMeasureHelper: () => this.props.drawingsInstancesValueHelper,
    removeDrawingsFromGroupsWithUndoRedo: this.removeDrawingsFromGroupsWithUndoRedo,
    updateDrawingsGroup: this.props.updateDrawingsGroup,
    groupMeasurementsWithUndoRedo: this.groupMeasurementsUndoRedo,
    createNewGroupsWithUndoRedo: this.createNewGroupUndoRedo,
    setDrawingsGroups: this.setDrawingsGroups,
    changeColorUndoRedo: this.changeColorUndoRedo,
    duplicateSelectedInstances: this.duplicateSelectedInstances,
    changeShowInstances: this.changeShowInstances,
    getGroupPayloadForInstanceCreate: this.getGroupsForInstanceCreate,
  };


  public render(): React.ReactNode {
    return (
      <DrawingsElementAndGroupOperationsContext.Provider value={this.api}>
        {this.props.children}
      </DrawingsElementAndGroupOperationsContext.Provider>
    );
  }

  private get isBooleanDrawingMode(): boolean {
    return DrawingsCommonUtils.isBooleanDrawMode(this.props.drawMode);
  }

  @autobind
  private canTraceLink(id: string): boolean {
    return !!this.props.getTraceLinks(id).length;
  }

  @autobind
  private removeSelectedInstancesHandler(onlyInstances?: boolean, preRemoveAction?: () => void): void {
    if (this.props.selectedInstances.some(instance => this.canTraceLink(instance))) {
      this.props.openDialog(
        DrawingDialogs.DELETE_MEASUREMENTS_DIALOG,
        () => this.onRemoveSelectedInstances(onlyInstances, preRemoveAction),
      );
    } else {
      if (this.props.selectedInstances.length > 1) {
        this.props.openDialog(
          DrawingDialogs.DELETE_MEASUREMENTS_DIALOG,
          () => this.onRemoveSelectedInstances(onlyInstances, preRemoveAction),
        );
      } else {
        this.onRemoveSelectedInstances(onlyInstances, preRemoveAction);
      }
    }
  }

  @autobind
  private onRemoveSelectedInstances(onlyInstances?: boolean, preRemoveAction?: () => void): void {
    if (preRemoveAction) {
      preRemoveAction();
    }
    const { selectedInstances, selectedGroups, drawingGeometryGroups } = this.props;
    const selectedGroupsSet = new Set(selectedGroups);
    const updatedGroups = drawingGeometryGroups
      .map(group => selectedGroupsSet.has(group.id)
        ? { ...group, measurements: group.measurements.filter(m => !selectedInstances.includes(m)) }
        : group,
      );
    const parentToChildMap = DrawingsGroupUtils.getParentToChildMap(updatedGroups);
    const groupIdsToRemove = onlyInstances ? [] : arrayUtils.filterMap(
      selectedGroups,
      id => DrawingsGroupUtils.isGroupEmpty(id, parentToChildMap),
      id => id,
    );
    const removedInstancesByPages = arrayUtils.groupBy(selectedInstances, x => this.props.instances[x].drawingId);
    this.removeInstancesWithUndo(removedInstancesByPages, groupIdsToRemove);
    this.props.closeDialog(DrawingDialogs.DELETE_MEASUREMENTS_DIALOG);
  }

  @autobind
  private moveToGroupWithUndoRedo(
    groupId: string,
    groupIds: string[],
    measurementIds: string[],
    orderIndex?: number,
  ): void {
    const { drawingGeometryGroups, pinnedGroupIds } = this.props;
    const data = {
      groupIds,
      measurementIds,
      groupId,
      orderIndex,
    };
    const targetGroup = this.props.drawingGeometryGroups.find(g => g.id === groupId);
    const groupWithColor = targetGroup ? this.getClosestGroupWithColor(targetGroup) : null;
    let measurementsUndoRedo;
    if (groupWithColor) {
      const instancesToUpdate = this.getInstancesToChangeColor(measurementIds, groupIds);
      measurementsUndoRedo = this.changeColorUndoRedo(instancesToUpdate, groupWithColor.color);
    }

    const { undo, redo } = DrawingsUndoRedoHelper.getGroupUndoRedo(
      drawingGeometryGroups,
      pinnedGroupIds,
      data,
      (...args) => {
        this.moveToGroup(...args);
        if (measurementsUndoRedo) { measurementsUndoRedo.redo(); }
      },
      (...args) => {
        if (measurementsUndoRedo) { measurementsUndoRedo.undo(); }
        this.setDrawingsGroups(...args);
      },
    );
    redo();
    this.props.addUndoRedo(undo, redo);
  }

  @autobind
  private addInstancesWithUndo(
    payload: AddInstancesWithUndoRedoPayload,
  ): void {
    this.props.addInstancesWithUndo(payload);
  }


  @autobind
  private processAddInstances(
    payload: AddInstancesPayload,
    groupsPayload?: GroupPayloadForInstanceCreate,
  ): void {
    this.props.addInstances(payload, groupsPayload);
  }

  @autobind
  private getGroupsForInstanceCreate(): GroupPayloadForInstanceCreate {
    const { groupForInstanceCreation, useGroupNameForNewGeometry, selectedGroups } = this.props;
    return {
      groupForInstanceCreation,
      useGroupNameForNewGeometry,
      selectedGroups,
    };
  }

  @autobind
  private removeInstancesWithUndo(
    instanceIds: Record<string, string[]>,
    groupIds: string[],
  ): void {
    if (!groupIds.length && !Object.keys(instanceIds).length) {
      return;
    }
    const { instances, points, assignedPia } = this.props;
    const prevState = this.props.drawingGeometryGroups;
    const pinnedGroups = this.props.pinnedGroupIds;
    const groupsPia = arrayUtils.toDictionary(groupIds, g => g, g => assignedPia[g]);
    const { undo, redo } = DrawingsUndoRedoHelper.createRemoveUndoRedo(
      instanceIds,
      points,
      instances,
      (instancesForRestore) => {
        this.setDrawingsGroups(prevState, pinnedGroups, groupIds, [], groupsPia);
        this.processAddInstances({
          ...instancesForRestore,
          ignoreSaveMeasuresOnCreate: true,
          forceSave: true,
        });
      },
      (removedInstances) => {
        this.removeInstances(removedInstances, groupIds);
      },
      id => assignedPia[id],
    );
    this.props.addUndoRedo(undo, redo);
    redo();
  }

  @autobind
  private removeInstances(
    instancesIds: Record<string, string[]>,
    groupIds: string[],
  ): void {
    this.props.removeInstances(instancesIds, groupIds);
  }

  @autobind
  private showSelectedInstances(): void {
    const { selectedInstances, rendererApi } = this.props;
    if (selectedInstances && selectedInstances.length && !this.isBooleanDrawingMode) {
      if (rendererApi) {
        rendererApi.engine.setVisibility(selectedInstances, true);
      }
      this.changeShowInstances(selectedInstances);
    }
  }

  @autobind
  private hideSelectedInstances(): void {
    const { selectedInstances, rendererApi } = this.props;
    if (selectedInstances && selectedInstances.length && !this.isBooleanDrawingMode) {
      if (rendererApi) {
        rendererApi.engine.setVisibility(selectedInstances, false);
      }
      this.changeHideInstances(selectedInstances);
    }
  }

  @autobind
  private removeSelectedElementsFromGroups(): void {
    this.removeDrawingsFromGroupsWithUndoRedo(this.props.selectedInstances);
  }

  @autobind
  private isolateSelectedInstances(): void {
    const { selectedInstances, filteredElements, updateInstancesVisibility } = this.props;
    if (selectedInstances && selectedInstances.length) {
      const selectedSet = new Set(selectedInstances);
      const instancesToHide = filteredElements.filter(x => !selectedSet.has(x));
      const { undo, redo } =
        DrawingsUndoRedoHelper.createVisibilityUndoRedo(selectedInstances, instancesToHide, updateInstancesVisibility);
      this.props.addUndoRedo(undo, redo);
      redo();
    }
  }

  @autobind
  private changeHideInstances(instancesIds: string[]): void {
    const { undo, redo } =
      DrawingsUndoRedoHelper.createVisibilityUndoRedo([], instancesIds, this.props.updateInstancesVisibility);
    this.props.addUndoRedo(undo, redo);
    redo();
  }

  @autobind
  private changeShowInstances(instancesIds: string[]): void {
    const { undo, redo } =
      DrawingsUndoRedoHelper.createVisibilityUndoRedo(instancesIds, [], this.props.updateInstancesVisibility);
    this.props.addUndoRedo(undo, redo);
    redo();
  }

  @autobind
  private removeDrawingsFromGroups({ measurementIds }: GroupActionData): void {
    if (!measurementIds || !measurementIds.length) {
      return;
    }
    this.props.moveDrawingsGroup({
      groups: [],
      measurements: measurementIds,
    });
  }

  @autobind
  private removeDrawingsFromGroupsWithUndoRedo(instanceIds: string[]): void {
    const { drawingGeometryGroups, pinnedGroupIds } = this.props;
    const actionData = {
      measurementIds: instanceIds,
      groupIds: [],
    };
    const { undo, redo } = DrawingsUndoRedoHelper.getGroupUndoRedo(
      drawingGeometryGroups,
      pinnedGroupIds,
      actionData,
      this.removeDrawingsFromGroups,
      this.setDrawingsGroups,
    );
    redo();
    this.props.addUndoRedo(undo, redo);
  }

  @autobind
  private groupMeasurementsUndoRedo(): void {
    const { pinnedGroupIds, selectedInstances } = this.props;

    const groupId = UuidUtil.generateUuid();
    const prevGroups = this.props.drawingGeometryGroups;
    const actionData = {
      ...this.getNewGroupData(selectedInstances),
      groupId,
    };
    const { undo, redo } = DrawingsUndoRedoHelper.getGroupUndoRedo(
      prevGroups,
      pinnedGroupIds,
      actionData,
      this.createNewGroup,
      this.setDrawingsGroups,
    );
    redo();
    this.props.addUndoRedo(undo, redo);
  }

  @autobind
  private createNewGroupUndoRedo(data: CreateDrawingGroupsTree): void {
    const { drawingGeometryGroups, pinnedGroupIds } = this.props;
    const actionData = {
      groupIds: [],
      measurementIds: [],
    };
    const { undo, redo } = DrawingsUndoRedoHelper.getGroupUndoRedo(
      drawingGeometryGroups,
      pinnedGroupIds,
      actionData,
      () => {
        this.props.addDrawingsGroup(data);
      },
      this.setDrawingsGroups,
    );
    redo();
    this.props.addUndoRedo(undo, redo);
  }

  @autobind
  private moveToGroup({ measurementIds, groupIds, groupId, orderIndex }: GroupActionData): void {
    const updatedData: MoveDrawingGroupTree = {
      parentGroupId: groupId,
      orderIndex,
      groups: groupIds,
      measurements: measurementIds,
    };
    this.props.moveDrawingsGroup(updatedData);
  }

  private getInstancesToChangeColor(
    measurementsIds: string[],
    groupIds: string[],
  ): string[] {
    const groupMap = DrawingsGroupUtils.getParentToChildMap(this.props.drawingGeometryGroups);
    const groupMeasurementIds = groupIds.reduce(
      (acc, id) => {
        const group = groupMap[id].value;
        if (!group.color) {
          acc = acc.concat(
            DrawingsGroupUtils.getAllInnerMeasurements(group, groupMap, gr => !gr.color),
          );
        }
        return acc;
      },
      []);
    return measurementsIds.concat(groupMeasurementIds);
  }

  @autobind
  private getColorOfInstance(id: string): string {
    const instance = this.props.instances[id];
    return DrawingAnnotationUtils.getColorOfInstance(instance);
  }

  @autobind
  private changeColorUndoRedo(
    instancesIds: string[],
    color: string,
    group?: UpdateDrawingGroup,
  ): UndoRedoActionMethods {
    const originalMeasurements = instancesIds.map(id => ({
      id,
      name: this.props.instances[id].name,
      color: this.getColorOfInstance(id),
    }));

    return DrawingsUndoRedoHelper.updateInstancesColorUndoRedo(
      color,
      group,
      originalMeasurements,
      this.getColorOfInstance,
      this.changeMeasurementsColor,
      this.props.updateDrawingsGroup,
    );
  }

  @autobind
  private changeMeasurementsColor(instancesIds: string[], color: string): void {
    if (this.props.rendererApi) {
      this.props.rendererApi.engine.changeEntitiesStyles(instancesIds, 'color', color);
    }
    this.props.updateColorsInElements(instancesIds, color);
  }

  private getClosestGroupWithColor(selectedGroup: DrawingsGeometryGroup): DrawingsGeometryGroup {
    const groupMap: Record<string, DrawingsGeometryGroup> = {};
    this.props.drawingGeometryGroups.forEach(group => {
      groupMap[group.id] = group;
    });
    return DrawingsGroupUtils
      .getClosestGroup(selectedGroup, groupMap, g => !!g.color);
  }

  @autobind
  private getNewGroupData(instanceIds: string[]): GroupActionData {
    const { drawingGeometryGroups, selectedGroups } = this.props;
    const selectedGroupRoots =
      DrawingsGroupUtils.getSelectedGroupRoots(selectedGroups, drawingGeometryGroups);
    if (selectedGroupRoots.length === 0) {
      const parentGroup = this.getInstancesParentGroup(instanceIds);
      return {
        measurementIds: instanceIds,
        groupIds: [],
        newGroupParent: parentGroup,
      };
    }
    const selectedGroupRootsIds = selectedGroupRoots.map(group => group.id);
    const groupsMap = DrawingsGroupUtils.getParentToChildMap(drawingGeometryGroups);
    const innerMeasurements = [];
    selectedGroupRoots.forEach(
      gr => innerMeasurements.push(...DrawingsGroupUtils.getAllInnerMeasurements(gr, groupsMap, () => true)),
    );
    const innerMeasurementsSet = new Set(innerMeasurements);
    const commonParentGroup = this.getCommonParentGroup(selectedGroupRoots);
    const rootsInstancesIds = instanceIds.filter(id => !innerMeasurementsSet.has(id));
    if (rootsInstancesIds.length) {
      const commonParentInstance = this.getInstancesParentGroup(rootsInstancesIds);
      const commonParent = commonParentInstance === commonParentGroup
        ? commonParentInstance
        : undefined;
      return {
        measurementIds: rootsInstancesIds,
        groupIds: selectedGroupRootsIds,
        newGroupParent: commonParent,
      };
    }
    return {
      measurementIds: [],
      groupIds: selectedGroupRootsIds,
      newGroupParent: commonParentGroup,
    };
  }

  private getCommonParentGroup(groups: DrawingsGeometryGroup[]): DrawingsGeometryGroup {
    const commonParentGroup = groups[0].parentId;
    for (const group of groups) {
      const currentGroupParent = group.parentId;
      if (commonParentGroup !== currentGroupParent) {
        return undefined;
      }
    }

    return this.props.drawingGeometryGroups.find((group) => group.id === commonParentGroup);
  }

  @autobind
  private createNewGroup({
    groupId,
    groupIds,
    measurementIds,
    newGroupParent,
  }: GroupActionData): void {
    const newGroup: CreateDrawingGroup = {
      id: groupId,
      name: DrawingsGroupUtils.NEW_GROUP_NAME,
      parentGroupId: newGroupParent && newGroupParent.id,
      innerGroups: [],
    };
    this.props.addDrawingsGroup({
      groups: [newGroup],
      parentGroupId: newGroupParent && newGroupParent.id,
    });
    this.props.moveDrawingsGroup({
      parentGroupId: newGroup.id,
      groups: groupIds,
      measurements: measurementIds,
    });
    this.props.sendEvent(MetricNames.measureManager.createFolder);
  }

  private getInstancesParentGroup(instanceIds: string[]): DrawingsGeometryGroup {
    const drawingsGeometryGroups = this.props.drawingGeometryGroups;
    const commonParentGroup = drawingsGeometryGroups.find(group => group.measurements.includes(instanceIds[0]));
    for (const id of instanceIds) {
      const currentParentGroup = drawingsGeometryGroups.find(group => group.measurements.includes(id));
      if (currentParentGroup !== commonParentGroup) {
        return undefined;
      }
    }

    return commonParentGroup;
  }

  @autobind
  private setDrawingsGroups(
    prevDrawingGroups: DrawingsGeometryGroup[],
    prevPinnedGroups: string[],
    affectedGroupIds: string[],
    affectedMeasurementIds: string[],
    pia?: Record<string, AssignedPia>,
  ): void {
    const currentGroups = this.props.drawingGeometryGroups;
    const prevToCurrentGroupsMap = {};
    const affectedGroupSet = new Set(affectedGroupIds);
    const removeGroupsIds: string[] = [];
    const updateGroups: DrawingsGeometryGroup[] = [];
    for (const currentGroup of currentGroups) {
      const prevGroup = prevDrawingGroups.find(group => group.id === currentGroup.id);
      if (prevGroup) {
        if (affectedGroupSet.has(currentGroup.id)) {
          prevToCurrentGroupsMap[prevGroup.id] = currentGroup;
          updateGroups.push(prevGroup);
        }
      } else {
        removeGroupsIds.push(currentGroup.id);
      }
    }

    const newGroups = prevDrawingGroups.filter(group =>
      !prevToCurrentGroupsMap[group.id]
      && affectedGroupSet.has(group.id));

    const measurementsToMove = affectedMeasurementIds.map(measurementId => {
      const parentGroup = prevDrawingGroups.find(group => group.measurements.includes(measurementId));
      return {
        parentGroupId: parentGroup && parentGroup.id,
        id: measurementId,
      };
    });

    this.props.resetGroupsChange({
      groups: prevDrawingGroups,
      pinnedGroupIds: prevPinnedGroups,
      create: newGroups.map<CreateDrawingGroup>(x => ({
        parentGroupId: x.parentId,
        innerGroups: [],
        id: x.id,
        name: x.name,
        color: x.color,
      })),
      moveGroups: updateGroups.map(group => group.id),
      moveMeasurements: measurementsToMove,
      delete: removeGroupsIds,
      pia,
    });
  }

  @autobind
  private duplicateSelectedInstances(): void {
    if (this.props.selectedInstances.length) {
      this.duplicateInstances(this.props.selectedInstances);
    }
  }

  @autobind
  private duplicateInstances(instancesIds: string[]): void {
    const geometriesForAdd = new Array<DrawingsGeometryInstanceWithId>();
    const newPoints: Record<string, ShortPointDescription> = {};
    const oldPointsToNew = {};
    const processPoints = (instancePoints: string[]): string[] => {
      const points = new Array<string>(instancePoints.length);
      for (let i = 0; i < points.length; i++) {
        const point = instancePoints[i];
        if (oldPointsToNew[point]) {
          points[i] = oldPointsToNew[point];
        } else {
          const pointId = UuidUtil.generateUuid();
          newPoints[pointId] = this.props.points[point];
          points[i] = pointId;
          oldPointsToNew[point] = pointId;
        }
      }
      return points;
    };

    const pia = {};
    for (const instanceId of instancesIds) {
      const instance = this.props.instances[instanceId];
      const { type, geometry: sourceGeometry, name } = instance;
      const points = processPoints(sourceGeometry.points);
      const geometry = { ...sourceGeometry, points };
      if (DrawingsGeometryUtils.isPolygon(type, geometry) && geometry.children) {
        geometry.children = geometry.children.map(processPoints);
      }
      const newInstanceId = UuidUtil.generateUuid();
      geometriesForAdd.push(
        {
          ...instance,
          geometry,
          id: newInstanceId,
          name: this.props.isKeepOriginName
            ? name
            : DrawingAnnotationNamingUtils.getDuplicateGeometryName(name),
        },
      );
      pia[newInstanceId] = this.props.assignedPia[instanceId];
    }
    this.addInstancesWithUndo({
      instances: geometriesForAdd,
      points: newPoints,
      pia,
    });
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    instances: state.drawings.aiAnnotation.geometry,
    points: state.drawings.aiAnnotation.points,
    drawingGeometryGroups: state.drawings.drawingGeometryGroups,
    projectId: state.projects.currentProject.id,
    selectedGroups: state.drawings.selectGeometryGroup,
    pinnedGroupIds: state.drawings.pinnedGroupIds,
    useGroupNameForNewGeometry: PersistedStorageDrawingsSelectors.selectGroupNameAsGeometry(state.persistedStorage),
    assignedPia: state.twoD.assignPia,
  };
}


function mapDispatchToProps(dispatch: Dispatch<AnyAction>, { canEditMeasurements }: OwnProps): DispatchProps {
  return {
    openDialog: (dialogName, data?) => dispatch(KreoDialogActions.openDialog(dialogName, data)),
    closeDialog: (dialogName) => dispatch(KreoDialogActions.closeDialog(dialogName)),
    updateDrawingsGroup: (data) => {
      dispatch(DrawingsAnnotationLegendActions.updateGroups(data));
      const groupChange = {
        operation: DrawingChangeOperation.Update,
        data: {
          groups: data.groups.map(x => x.id),
          measurements: data.measurements ? data.measurements.map(x => x.id) : [],
        },
      };
      dispatch(DrawingsUpdateActions.commitUpdates([groupChange], DrawingChangeSource.Groups));
    },
    addDrawingsGroup: (groups) => {
      dispatch(DrawingsAnnotationLegendActions.addGroups(groups));
      const groupChange: DrawingGroupChanges = {
        operation: DrawingChangeOperation.Create,
        data: { groups: groups.groups, measurements: [] },
        pia: groups.pia,
      };
      dispatch(DrawingsUpdateActions.commitUpdates([groupChange], DrawingChangeSource.Groups));
    },
    moveDrawingsGroup: (data) => {
      dispatch(DrawingsAnnotationLegendActions.moveGroups(data));
      const measurements: UpdateGroupInDrawingsPayload[] =
        data.measurements.map(m => ({ id: m, parentGroupId: data.parentGroupId }));
      dispatch(DrawingsAnnotationActions.updateGroupInInstances(measurements));
      const groupChange = {
        operation: DrawingChangeOperation.Move,
        data: { groups: data.groups, measurements: data.measurements },
      };
      dispatch(DrawingsUpdateActions.commitUpdates([groupChange], DrawingChangeSource.Groups));
    },
    resetGroupsChange: (data) => {
      dispatch(DrawingsAnnotationLegendActions.bulkGroupsUpdate(data));
      const createGroupChange = {
        operation: DrawingChangeOperation.Create,
        data: { groups: data.create, measurements: [] },
        pia: data.pia,
      };
      const moveGroupChange = {
        operation: DrawingChangeOperation.Move,
        data: { groups: data.moveGroups, measurements: data.moveMeasurements.map(m => m.id) },
      };
      const removeGroupChange = {
        operation: DrawingChangeOperation.Delete,
        data: { groups: data.delete, measurements: [] },
      };
      dispatch(DrawingsUpdateActions.commitUpdates(
        [createGroupChange, moveGroupChange, removeGroupChange],
        DrawingChangeSource.Groups),
      );
    },
    updateColorsInElements: (instancesIds, color) =>
      dispatch(DrawingsAnnotationActions.updateGeometryParams(instancesIds, 'color', color)),
    updateInstancesVisibility: (instancesToShow, instancesToHide) =>
      dispatch(InstancesVisibilityActions.updateVisibility(instancesToShow, instancesToHide, canEditMeasurements)),
  };
}

const ConnectedToRedux = connect(mapStateToProps, mapDispatchToProps)(ElementOperationsContextProvider);

export const DrawingsElementStateOperationsContextProvider =
    withRendererApiContext(withDrawingsSelectionContextProps(withUndoRedoApiProps(
      withAnalyticsContext(withStateConnector(ConnectedToRedux)))));
