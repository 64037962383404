import * as React from 'react';

export interface DrawingsGeometryOperationsContextState {
  selectedIdForSubtract: string;
  offsetValue: number;
}


export interface DrawingsGeometryOperationsContextApi {
  setSelectedIdForSubtract: (instanceId: string) => void;
  setOffsetValue: (value: number) => void;
  startSubtract: () => void;
  startUnite: () => void;
}


export interface DrawingsGeometryOperationsFullContext
  extends DrawingsGeometryOperationsContextState, DrawingsGeometryOperationsContextApi {
}

export const DrawingsGeometryOperationsContext = React.createContext<DrawingsGeometryOperationsContextState>(null);
export const DrawingsGeometryOperationsApiContext = React.createContext<DrawingsGeometryOperationsContextApi>(null);
