import autobind from 'autobind-decorator';
import * as React from 'react';

import { MultilevelSelectOptionDataWithObjectValue } from 'common/components/multi-level-drop-down-menu';
import { MultiLevelSelectOption } from 'common/components/multi-level-drop-down-menu/multi-level-select-option';
import { DrawingsSubmenuContainer } from './submenu-containers';


interface Props<T> {
  options: Array<MultilevelSelectOptionDataWithObjectValue<T>>;
  optionRenderer: (option: MultilevelSelectOptionDataWithObjectValue<T>) => React.ReactNode;
  onSelect: (value: T, event?: React.MouseEvent<HTMLDivElement>) => void;
}

export class DrawingsSelectType<T> extends React.PureComponent<Props<T>> {
  public render(): React.ReactNode {
    return (
      <DrawingsSubmenuContainer>
        {
          this.props.options.map((option, i) => (
            <MultiLevelSelectOption<MultilevelSelectOptionDataWithObjectValue<T>>
              key={`${option.name} ${i}`}
              optionData={option}
              isSelected={false}
              subvariants={null}
              value={option.name}
              selectedValue={null}
              onSelectClick={this.onSelect}
              themeStyle={true}
              isSelectable={true}
            >
              {this.props.optionRenderer(option)}
            </MultiLevelSelectOption>
          ))
        }
      </DrawingsSubmenuContainer>
    );
  }

  @autobind
  private onSelect(
    _value: React.ReactText | React.ReactText[],
    option: MultilevelSelectOptionDataWithObjectValue<T>,
    event: React.MouseEvent<HTMLDivElement>,
  ): void {
    this.props.onSelect(option.value, event);
  }
}
