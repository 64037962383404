import { Avatar, ElementTooltip } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { Styled } from './styled';


export const AVATAR_HEIGHT = 40;

interface Props {
  name: string;
  logo: string;
  id: number;
  onCompanyClick: (companyId: number) => void;
}

export const Company: React.FC<Props> = ({ name, logo, id, onCompanyClick }) => {
  const handleCompanyClick = useCallback(() => {
    onCompanyClick(id);
  }, [id, onCompanyClick]);

  return (
    <ElementTooltip
      text={name}
      position={'right'}
      speed={'s'}
      wordBreakAll={true}
    >
      <Styled.AvatarContainer>
        <Avatar
          onClick={handleCompanyClick}
          name={name}
          size={AVATAR_HEIGHT}
          avatar={logo}
        />
      </Styled.AvatarContainer>
    </ElementTooltip>
  );
};
