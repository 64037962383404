import { Text } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Container = styled.div`
  margin: 10px;

  > div {
    width: 140px!important;
    height: 80px!important;
    canvas {
      width: 140px;
      height: 80px;
    }
  }

  ${Text} {
    margin-top: 10px;
  }
`;

export const Styled = {
  Container,
};
