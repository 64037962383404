import React from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { DrawingsInstanceMeasure, HoverState } from '../../interfaces';
import { InstanceInformation } from './instance-information';

interface StateProps {
  hoverState: HoverState;
}

interface OwnProps {
  getInstancesMeasures: (instanceId: string[]) => DrawingsInstanceMeasure[];
}

interface Props extends StateProps, OwnProps {}

const InstanceInformationContextWrapperComponent: React.FC<Props>= ({ getInstancesMeasures, hoverState }) => {

  if (!hoverState) {
    return null;
  }

  return (
    <InstanceInformation
      instanceId={hoverState.hoverInstanceId}
      position={hoverState.position}
      getInstancesMeasures={getInstancesMeasures}
    />
  );
};


function mapStateToProps(s: State): StateProps {
  return {
    hoverState: s.drawings.selectedInstances.length ? null : s.drawings.hoverState,
  };
}

export const InstanceInformationContextWrapper = connect(mapStateToProps)(InstanceInformationContextWrapperComponent);
