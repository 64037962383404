import * as Ag from 'ag-grid-community';
import React, { useCallback } from 'react';

import { DataBaseTable, RowData, useGridApiState } from 'common/components/data-base-table';
import { QuickSearchInput } from 'common/components/quick-search';
import { HandleAddedOption, HandleUpdateOption } from '../side-panel/components/property-panel/forms/tree-form/hooks';
import { PiaBreakdownNode } from '../side-panel/components/property-panel/forms/tree-form/interfaces';
import {
  useDefaultColumnsMemo,
  useGetRowNodeIdCallback,
  useSelectedNodeEffect,
  useTableContextMemo,
  useTransactionEffect,
} from './hooks';
import { Styled } from './styled';

const COLUMNS = [];
const ROW_DATA: RowData[] = [];

interface Props {
  selectedNodeId?: string;
  editable: boolean;
  options: PiaBreakdownNode[];
  suppressRowClickSelection: boolean;
  enableMultiSelect: boolean;
  onRowSelected: (event: Ag.RowSelectedEvent) => void;
  addOption?: HandleAddedOption;
  updateOption?: HandleUpdateOption;
  handleDragEnd?: (
    targetId: string,
    sourceId: string,
    isGroup: boolean,
    beforeElement: boolean,
    selectedNodeIds: string[],
  ) => void;
  extendContextMenuItem?: (
    items: Array<Ag.MenuItemDef | string>,
    params: Ag.GetContextMenuItemsParams,
  ) => Array<Ag.MenuItemDef | string>;
}

export const BreakdownProperty: React.FC<Props> = ({
  editable,
  options,
  selectedNodeId,
  suppressRowClickSelection,
  enableMultiSelect,
  addOption,
  updateOption,
  handleDragEnd,
  extendContextMenuItem,
  onRowSelected,
}) => {
  const [saveGridApiRef, gridApi] = useGridApiState();

  const DEFAULT_COLUMNS = useDefaultColumnsMemo(editable);
  const getRowNodeId = useGetRowNodeIdCallback();
  const tableContext = useTableContextMemo(addOption, updateOption, options);

  useTransactionEffect(options, gridApi);
  useSelectedNodeEffect(gridApi, selectedNodeId);

  const setQuickSearchValue = useCallback((value: string) => {
    gridApi.setQuickFilter(value);
  }, [gridApi]);

  return (
    <Styled.Container>
      <QuickSearchInput
        placeholder={'Start typing...'}
        isQuickSearchVisible={true}
        setQuickSearchValue={setQuickSearchValue}
        tooltipText={'Clear'}
      />
      <DataBaseTable
        rowData={ROW_DATA}
        columns={COLUMNS}
        defaultColumns={DEFAULT_COLUMNS}
        selectByCheckbox={true}
        suppressRowClickSelection={suppressRowClickSelection}
        autoGroupName={'group'}
        onRowSelected={onRowSelected}
        onDragEnd={handleDragEnd}
        enableRowDrag={true}
        onGridReady={saveGridApiRef}
        context={tableContext}
        extendContextMenuItem={extendContextMenuItem}
        getRowNodeId={getRowNodeId}
        isDragBeforeEnable={true}
        enableMultiSelect={enableMultiSelect}
        selectedNodeId={selectedNodeId}
      />
    </Styled.Container>
  );
};
