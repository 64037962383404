import { Waves, Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { Styled } from './styled';

interface StateComponent {
  scrollExist: boolean;
  scrollStart: boolean;
  scrollEnd: boolean;
}

interface Props {
  children: React.ReactNode[];
  dragDirection?: 'vertical' | 'horizontal';
  disableAutoScroll?: boolean;
  step?: number;
  isSynchronizationScrolls?: boolean;
}

const DEFAULT_STEP = 40;

export class TabsContainerWithScroll extends React.Component<Props, StateComponent> {
  private body: HTMLBodyElement;
  private enableScroll: boolean = false;
  private startMove: number = 0;
  private scrollLeft: number = 0;
  private tabListScrollWidth: number = 0;
  private containerWidth: number = 0;
  private containerRef: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      scrollExist: false,
      scrollEnd: false,
      scrollStart: false,
    };
  }

  public render(): JSX.Element {
    const enableRightScrollButton = this.state.scrollExist && !this.state.scrollEnd;
    const enableLeftScrollButton = this.state.scrollExist && this.state.scrollStart;
    return (
      <Styled.Container
        ref={this.saveRefContainer}
        onScroll={this.onScroll}
        onMouseUp={this.props.isSynchronizationScrolls ? this.synchronizationWithBrowserScroll : null}
      >
        <Styled.TabsList
          onMouseDown={!this.props.disableAutoScroll ? this.downTabList : null}
          onMouseUp={this.upTabList}
          onMouseMove={this.moveTabList}
          onMouseLeave={this.mouseLeaveTabList}
        >
          <ReactResizeDetector handleWidth={true} onResize={this.onResize} />
          {this.props.children}
        </Styled.TabsList>
        <Styled.ScrollButton
          onClick={this.onScrollRight}
          left={true}
          enabled={enableLeftScrollButton}
        >
          <Styled.IconArrow>
            <Icons.RightSmall />
            <Waves/>
          </Styled.IconArrow>
        </Styled.ScrollButton>
        <Styled.ScrollButton
          onClick={this.onScrollLeft}
          enabled={enableRightScrollButton}
        >
          <Styled.IconArrow>
            <Icons.RightSmall />
            <Waves/>
          </Styled.IconArrow>
        </Styled.ScrollButton>
      </Styled.Container>
    );
  }

  public componentDidMount(): void {
    this.getNeedScroll();
  }

  public componentDidUpdate(prevProps: Props): void {
    const isScrolled = this.containerRef.scrollLeft !== this.scrollLeft;
    if (isScrolled) {
      this.scrollLeft = this.containerRef.scrollLeft;
    }
    if (!isEqual(prevProps.children, this.props.children) || isScrolled) {
      this.getNeedScroll();
    }
  }

  @autobind
  private onScrollLeft(): void {
    const step = this.props.step || DEFAULT_STEP;
    this.scrollLeft = this.containerRef.scrollLeft + step;
    this.containerRef.scrollTo({ left: this.scrollLeft });
  }

  @autobind
  private onScroll(): void {
    this.scrollLeft = this.containerRef.scrollLeft;
    this.updateStartEndScrollStatus();
  }

  @autobind
  private onScrollRight(): void {
    const step = this.props.step || DEFAULT_STEP;
    this.scrollLeft = this.containerRef.scrollLeft - step;
    this.containerRef.scrollTo({ left: this.scrollLeft });
  }

  @autobind
  private onResize(width: number): void {
    this.containerWidth = Math.ceil(width);
    this.getNeedScroll();
  }

  @autobind
  private getNeedScroll(): void {
    if (this.containerRef) {
      this.tabListScrollWidth = this.containerRef.firstElementChild.scrollWidth;
      const scrollExist = this.tabListScrollWidth > this.containerWidth;
      if (scrollExist) {
        this.updateStartEndScrollStatus();
      }
      if (scrollExist !== this.state.scrollExist) {
        this.setState({ scrollExist });
      }
    }
  }

  @autobind
  private updateStartEndScrollStatus(): void {
    if (!this.state.scrollStart && this.scrollLeft > 0) {
      this.setState({ scrollStart: true });
    } else if (this.state.scrollStart && this.scrollLeft <= 0) {
      this.setState({ scrollStart: false });
    }

    const currentScrollPosition = this.scrollLeft + this.containerWidth;
    if (!this.state.scrollEnd && currentScrollPosition >= this.tabListScrollWidth) {
      this.setState({ scrollEnd: true });
    } else if (this.state.scrollEnd && currentScrollPosition < this.tabListScrollWidth) {
      this.setState({ scrollEnd: false });
    }
  }

  @autobind
  private downTabList(e: React.MouseEvent<HTMLDivElement>): void {
    this.enableScroll = true;
    this.startMove = this.props.dragDirection === 'vertical' ? e.clientY : e.clientX;
    this.body = document.querySelector('body');
    this.body.className = 'text-select-disable';
  }

  @autobind
  private upTabList(): void {
    this.enableScroll = false;
    if (this.body) {
      this.body.className = '';
    }
  }

  @autobind
  private mouseLeaveTabList(): void {
    if (this.enableScroll) {
      this.upTabList();
    }
  }

  @autobind
  private moveTabList(e: React.MouseEvent<HTMLDivElement>): void {
    if (this.enableScroll) {
      this.scrollLeft = this.startMove - (this.props.dragDirection === 'vertical' ? e.clientY : e.clientX)
        + this.containerRef.scrollLeft;
      this.containerRef.scrollTo({ left: this.scrollLeft });

      this.updateStartEndScrollStatus();
    }
  }

  @autobind
  private synchronizationWithBrowserScroll(): void {
    const scrollLeft = this.containerRef.scrollLeft;
    const currentScrollPosition = this.scrollLeft + this.containerWidth;

    if (scrollLeft === 0) {
      this.setState({ scrollStart: false });
    } else if (!this.state.scrollStart && this.scrollLeft > 0) {
      this.setState({ scrollStart: true });
    }

    if (currentScrollPosition < this.tabListScrollWidth) {
      this.setState({ scrollEnd: false });
    } else if (this.state.scrollExist && currentScrollPosition >= this.tabListScrollWidth) {
      this.setState({ scrollEnd: true });
    }
  }

  @autobind
  private saveRefContainer(ref: HTMLDivElement): void {
    this.containerRef = ref;
    this.getNeedScroll();
  }
}
