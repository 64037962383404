import * as React from 'react';

import './classification-ontology-tree-element-uniclass.scss';

import { ClassificationOntologyUniclassInfo } from '../../interfaces/classification/ontology-data';

const ClassificationOntologyTreeElementUniclassContainer: React.FC = ({ children }) => {
  return (
    <div className='classification-ontology-tree-element-uniclass'>
      {children}
    </div>
  );
};

interface EntityProps {
  code: string;
  description: string;
}

const ClassificationOntologyTreeElementUniclassEntity: React.FC<EntityProps> = ({ code, description }) => {
  return (
    <div className='classification-ontology-tree-element-uniclass__entity'>
      <div
        className='classification-ontology-tree-element-uniclass__code'
        title={code}
      >
        {code}
      </div>
      <div
        className='classification-ontology-tree-element-uniclass__description'
        title={description}
      >
        {description}
      </div>
    </div>
  );
};

interface Props {
  uniclass: ClassificationOntologyUniclassInfo;
  isError: boolean;
  isMultilayer: boolean;
  isLayer: boolean;
}

export class ClassificationOntologyTreeElementUniclass extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { uniclass, isError, isMultilayer, isLayer } = this.props;
    if (isError || !uniclass) {
      return (
        <ClassificationOntologyTreeElementUniclassContainer>
          Unclassified
        </ClassificationOntologyTreeElementUniclassContainer>
      );
    } else if (isMultilayer) {
      return (
        <ClassificationOntologyTreeElementUniclassContainer>
          {
            isLayer ?
            <ClassificationOntologyTreeElementUniclassEntity
              code={uniclass.productCode}
              description={uniclass.productDescription}
            />
              :
            <ClassificationOntologyTreeElementUniclassEntity
              code={uniclass.systemCode}
              description={uniclass.systemDescription}
            />
          }
        </ClassificationOntologyTreeElementUniclassContainer>
      );
    } else {
      return (
        <ClassificationOntologyTreeElementUniclassContainer>
          <ClassificationOntologyTreeElementUniclassEntity
            code={uniclass.systemCode}
            description={uniclass.systemDescription}
          />
          <ClassificationOntologyTreeElementUniclassEntity
            code={uniclass.productCode}
            description={uniclass.productDescription}
          />
        </ClassificationOntologyTreeElementUniclassContainer>
      );
    }
  }
}
