import * as React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Action, Dispatch } from 'redux';

import './alerts.scss';

import { State as ReduxState } from 'common/interfaces/state';
import { NotificationActions } from '../../actions';
import { AlertType } from '../../alert-type';
import { Alert } from '../../interfaces';
import { AlertNotification } from './alert-notification';

interface ReduxProps {
  alerts: Alert[];
}

interface ReduxActions {
  deleteAlert: (alert: Alert) => void;
}

interface Props extends ReduxProps, ReduxActions { }

class AlertsComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { alerts } = this.props;
    let displayAnyError = false;
    return (
      <TransitionGroup className='alerts-container'>
        {alerts.map(x => {
          if (x.alertType === AlertType.Error) {
            if (displayAnyError) {
              return null;
            }
            displayAnyError = true;
          }
          return (
            <CSSTransition key={x.id} classNames='alert-notification' timeout={300}>
              <AlertNotification
                key={x.id}
                onClose={this.props.deleteAlert}
                alert={x}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    );
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    alerts: state.notifications.alerts,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    deleteAlert: alert => {
      dispatch(NotificationActions.deleteAlert(alert));
    },
  };
};

export const Alerts = connect(mapStateToProps, mapDispatchToProps)(AlertsComponent);
