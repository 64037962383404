import { Column } from 'ag-grid-community';

import { TreeTableColumnWithId } from 'common/components/tree-table/tree-table-column-controller';
import { isMainColumn } from '../../components/quantity-take-off-left-panel/map-columns-def';
import { QtoTreeRowProperty } from '../../interfaces/quantity-take-off';
import { TreeTableAgg } from './aggregation-functions';
import { BreakdownPropertiesUtils } from './breakdown-properties-utils';
import { PropertyHelper } from './property-helper';

function merge(
  reportProperties: Record<string, QtoTreeRowProperty>,
  elementProperties: Record<string, string>,
  usedColumns: Record<string, boolean>,
  getColumn: (id: string) => Column,
): {
  properties: Record<string, QtoTreeRowProperty>,
  newColumns: Array<TreeTableColumnWithId<QtoTreeRowProperty>>,
} {
  const result = { ...reportProperties };
  const newColumns = [];
  for (const [key, value] of Object.entries(elementProperties)) {
    if (!BreakdownPropertiesUtils.isValidProperty(key, value) || !isMainColumn(key)) {
      continue;
    }
    const keyInResult = key in result;

    if (!keyInResult || !usedColumns[key]) {
      const col = getColumn(key);
      const name = col.getColDef().headerName;
      const column = {
        id: col.getColId(),
        properties: {
          [PropertyHelper.columnProperties.header]: { default: name },
          [PropertyHelper.columnProperties.aggregationStrategy]: { default: TreeTableAgg.Sum },
          [PropertyHelper.columnProperties.isVisible]: { default: col.isVisible() },
        },
      };
      if (!usedColumns[key]) {
        newColumns.push(column);
        usedColumns[key] = true;
      }
    }

    if (!keyInResult || PropertyHelper.isDefaultValue(result[key])) {
      result[key] = BreakdownPropertiesUtils.convertToTreeRowProperty(value);
    }
  }
  return {
    properties: result,
    newColumns,
  };
}


export const MergeRowPropertiesHelper = {
  merge,
};
