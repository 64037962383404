import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { State } from 'common/interfaces/state';
import { useCommentsFilter } from 'unit-2d-comments/comments-panel/hooks';
import { CommentaryThread, FiltersData } from 'unit-2d-comments/interfaces';
import { CommentsApiContext, CommentsContext } from './comments-context';
import { CommentsProviderApi } from './interfaces';

interface Props {
  comments: CommentaryThread[];
  filterValues: FiltersData;
  currentUserId: string;
}

const CommentsProviderComponent: React.FC<Props> = ({ comments, filterValues, currentUserId, children }) => {
  const filteredComments = useCommentsFilter(comments, filterValues, currentUserId);
  const [ focusToComment, setFocusToCommentCallback ] = useState(null);

  const api = useMemo<CommentsProviderApi>(() => ({
    setOnFocusToComment: (callback) => setFocusToCommentCallback(() => callback),
    focusToComment,
  }), [focusToComment]);

  return (
    <CommentsContext.Provider value={filteredComments}>
      <CommentsApiContext.Provider value={api}>
        {children}
      </CommentsApiContext.Provider>
    </CommentsContext.Provider>
  );
};

function mapStateToProps(state: State): Props {
  return {
    comments: state.twoDComments.comments,
    filterValues: state.twoDComments.filters,
    currentUserId: state.account.id,
  };
}

export const CommentsProvider = connect(mapStateToProps)(CommentsProviderComponent);
