import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './database-resource-table.scss';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RequestStatus } from 'common/enums/request-status';
import { I18nAwareProps, withI18n } from 'common/i18n/i18n-context';
import { State as ReduxState } from 'common/interfaces/state';
import { DatabaseResourceActions } from '../../actions/creators/database-resource';
import { DatabaseResourceListingActions } from '../../actions/creators/database-resource-listing';
import { DatabaseResourceVariantActions } from '../../actions/creators/database-resource-variant';
import { ResourceType } from '../../enums';
import { DatabaseTableRootModel, RequestFilterModel } from '../../interfaces/data';
import { DatabaseTable } from '../database-table';
import {
  DatabaseTableColumn,
  DatabaseTableRootMenuItem,
  DatabaseTableVariantMenuItem,
} from '../database-table/interfaces';
import { ResourceTableColumnProvider } from './resource-table-columns-provider';

interface ReduxProps {
  selectedResourceType: ResourceType;
  resourcesLoadStatus: RequestStatus;
  isReadOnly: boolean;
  resources: DatabaseTableRootModel[];
  totalResourcesCount: number;
}

interface ReduxActions {
  removeResourceVariant: (databaseId: number, resourceId: number, variantId: number, type: ResourceType) => void;
  removeResource: (databaseId: number, resourceId: number, type: ResourceType) => void;
  loadResources: (databaseId: number, resourceType: ResourceType, filter: RequestFilterModel) => void;
  resetRequestStatuses: () => void;

  openCreateResource: () => void;
  openCreateResourceVariant: (resourceId: number) => void;
  openEditResource: (resourceId: number) => void;
  openEditResourceVariant: (resourceId: number, variantId: number) => void;
}

interface Props extends ReduxProps, ReduxActions, AbilityAwareProps, I18nAwareProps {
  databaseId: number;
  isCheckboxesVisible: boolean;
}

class DatabaseResourceTableComponent extends React.Component<Props> {
  private readonly resourceMenuItems: DatabaseTableRootMenuItem[]  = [
    {
      name: 'Edit',
      action: this.openEditResource,
    },
    {
      name: 'Delete',
      action: this.removeResource,
    },
  ];

  private readonly resourceVariantMenuItems: DatabaseTableVariantMenuItem[] = [
    {
      name: 'Edit',
      action: this.openEditResourceVariant,
    },
    {
      name: 'Delete',
      action: this.removeResourceVariant,
    },
  ];

  public componentDidUpdate(prevProps: Props): void {
    const shouldReloadActivities = prevProps.databaseId !== this.props.databaseId ||
      prevProps.selectedResourceType !== this.props.selectedResourceType;
    if (shouldReloadActivities) {
      this.props.resetRequestStatuses();
    }
  }

  public render(): JSX.Element {
    const canCreate = !this.props.isReadOnly && this.props.ability.can(Operation.Create, Subject.Database);
    const openCreateResource = canCreate ? this.props.openCreateResource : null;
    const openCreateResourceVariant = canCreate ? this.props.openCreateResourceVariant : null;

    return (
      <DatabaseTable
        entityName={this.props.selectedResourceType}
        title={this.props.selectedResourceType}
        items={this.props.resources}
        loadStatus={this.props.resourcesLoadStatus}
        columns={this.getColumns()}
        rootMenuItems={this.getResourceMenuItems()}
        variantMenuItems={this.getResourceVariantMenuItems()}

        disabledRootIds={[]}
        selectedRootIds={[]}
        disabledVariantIds={[]}
        selectedVariantIds={[]}

        loadItems={this.loadResources}
        openCreateRoot={openCreateResource}
        openCreateVariant={openCreateResourceVariant}
        openEditRoot={this.props.openEditResource}
        openEditVariant={this.props.openEditResourceVariant}
        selectRoot={null}
        selectVariant={null}

        batch={25}
        bufferItemCounts={5}
        totalItemsCount={this.props.totalResourcesCount}
      />
    );
  }

  private getColumns(): Array<DatabaseTableColumn<any>> {
    const { i18n } = this.props;
    switch (this.props.selectedResourceType) {
      case ResourceType.Material:
        return ResourceTableColumnProvider.getMaterialsColumns(i18n.currency.symbol);
      case ResourceType.Labour:
        return ResourceTableColumnProvider.getLaborsColumns(i18n.currency.symbol);
      case ResourceType.Plant:
        return ResourceTableColumnProvider.getPlantsColumns(i18n.currency.symbol);
      default: return null;
    }
  }

  private getResourceVariantMenuItems(): DatabaseTableVariantMenuItem[] | null {
    return !this.props.isReadOnly && this.props.ability.can(Operation.Create, Subject.Database)
      ? this.resourceVariantMenuItems
      : null;
  }

  private getResourceMenuItems(): DatabaseTableRootMenuItem[] | null {
    return !this.props.isReadOnly && this.props.ability.can(Operation.Create, Subject.Database)
      ? this.resourceMenuItems
      : null;
  }

  @autobind
  private loadResources(skip: number, take: number, search: string): void {
    const filter = { skip, take, search };
    this.props.loadResources(this.props.databaseId, this.props.selectedResourceType, filter);
  }

  @autobind
  private openEditResource(resourceId: number): void {
    this.props.openEditResource(resourceId);
  }

  @autobind
  private openEditResourceVariant(resourceId: number, variantId: number): void {
    this.props.openEditResourceVariant(resourceId, variantId);
  }

  @autobind
  private removeResourceVariant(materialId: number, variantId: number): void {
    this.props.removeResourceVariant(this.props.databaseId, materialId, variantId, this.props.selectedResourceType);
  }

  @autobind
  private removeResource(materialId: number): void {
    this.props.removeResource(this.props.databaseId, materialId, this.props.selectedResourceType);
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  const resourceListing = state.database.currentDatabase.resourceListing;
  return {
    resources: resourceListing.resources,
    totalResourcesCount: resourceListing.totalCount,
    selectedResourceType: resourceListing.resourceType,
    resourcesLoadStatus: state.database.statuses.resourceListing,
    isReadOnly: state.database.currentDatabase ? state.database.currentDatabase.database.isReadOnly : true,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    removeResourceVariant: (databaseId, resourceId, variantId, type) =>
      dispatch(DatabaseResourceVariantActions.deleteResourceVariant(databaseId, resourceId, variantId, type)),
    removeResource: (databaseId, resourceId, type) =>
      dispatch(DatabaseResourceActions.deleteResource(databaseId, resourceId, type)),
    loadResources: (databaseId, type, filter) =>
      dispatch(DatabaseResourceActions.loadResources(databaseId, type, filter)),
    resetRequestStatuses: () => dispatch(DatabaseResourceListingActions.resetRequestStatuses()),
    openCreateResource: () => dispatch(DatabaseResourceListingActions.openCreateResource()),
    openCreateResourceVariant: (resourceId) =>
      dispatch(DatabaseResourceListingActions.openCreateResourceVariant(resourceId)),
    openEditResource: (resourceId) => dispatch(DatabaseResourceListingActions.openEditResource(resourceId)),
    openEditResourceVariant: (resourceId, variantId) =>
      dispatch(DatabaseResourceListingActions.openEditResourceVariant(resourceId, variantId)),
  };
};

export const DatabaseResourceTable = withAbilityContext(
  connect(mapStateToProps, mapDispatchToProps)(withI18n(DatabaseResourceTableComponent)),
);
