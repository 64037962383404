import { Input } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { mathUtils } from 'common/utils/math-utils';
import { StringUtils } from 'common/utils/string-utils';
import { UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { Styled } from './styled';

interface Props {
  isDistanceValid: boolean;
  disableDistance: boolean;
  distance: string | number;
  changeDistance: (value: string) => void;
}

interface ComponentState {
  tempFeet: string;
  tempInches: string;
}

export class CalibrateImperialInput extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    const [tempFeet, tempInches] = UnitUtil.convertLengthToImperial(Number(props.distance), UnitTypes.M);
    this.state = {
      tempFeet: tempFeet.toString(),
      tempInches: tempInches.toString(),
    };
  }

  public render(): React.ReactNode {
    return (
      <Styled.InputsContainer>
        <Styled.DoubleInputContainer>
          <Input
            width='100%'
            type='text'
            text={UnitTypes.Ft}
            input={{
              value: this.state.tempFeet.toString(),
              onChange: e => this.changeImperialDistance([e.target.value, this.state.tempInches]),
              disabled: this.props.disableDistance,
            }}
            placeholder='0'
            isInvalid={!this.props.isDistanceValid}
          />
        </Styled.DoubleInputContainer>
        <Styled.DoubleInputContainer>
          <Input
            width='100%'
            type='text'
            text={UnitTypes.In}
            input={{
              value: this.state.tempInches.toString(),
              onChange: e => this.changeImperialDistance([this.state.tempFeet, e.target.value]),
              disabled: this.props.disableDistance,
            }}
            placeholder='0'
            isInvalid={!this.props.isDistanceValid}
          />
        </Styled.DoubleInputContainer>
      </Styled.InputsContainer>
    );
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.distance !== prevProps.distance) {
      const [tempFeet, tempInches] = UnitUtil.convertLengthToImperial(Number(this.props.distance), UnitTypes.M);
      this.setState({ tempFeet: tempFeet.toString(), tempInches: tempInches.toString() });
    }
  }

  @autobind
  private changeImperialDistance([feet, inches]: [string, string]): void {
    const feetCleared = StringUtils.removeNonNumericSymbols(feet);
    const inchesCleared = StringUtils.removeNonNumericSymbols(inches);
    const feetValue = Number(feetCleared);
    let inchesValue = Number(inchesCleared);
    if (Number.isNaN(feetValue) || Number.isNaN(inchesValue)) {
      return;
    }

    inchesValue = mathUtils.floor(inchesValue, 2);
    let tempInches = inchesCleared;
    if (/[0-9]+(\.|(\.\d*0+))$/.test(inchesCleared)) {
      const dotIndex = inchesCleared.indexOf('.');
      if (inchesCleared.length - dotIndex > 2) {
        return;
      }
    } else {
      tempInches = inchesValue.toString();
    }
    this.setState({ tempFeet: feetCleared, tempInches });
    this.props.changeDistance(UnitUtil.convertImperialLengthToSi([feetValue, inchesValue]).toString());
  }
}
