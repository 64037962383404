import classNames from 'classnames';
import * as React from 'react';

import './master.scss';

import { MainLayout } from 'common/components/main-layout';
import { SubHeader } from 'common/components/sub-header';
import { AppUrls } from 'routes/app-urls';
import { getRootAdminMenuItems } from '../menu-items';

interface AdminMasterPageProps {
  title: string;
  classNames?: string;
}

export class AdminMasterPage extends React.Component<AdminMasterPageProps> {
  public render(): React.ReactNode {
    return (
      <MainLayout
        getMenuItems={getRootAdminMenuItems}
        subHeader={(
          <SubHeader
            title={this.props.title}
          />
        )}
        hideCompaniesSelect={true}
        backUrl={AppUrls.products.url()}
        metaTitle={`Admin ${this.props.title}`}
      >
        <div className='content-wrapper'>
          <div className={classNames('admin-page', this.props.classNames)}>
            {this.props.children}
          </div>
        </div>
      </MainLayout>
    );
  }
}
