import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { DrawingsGeometryGroup } from '../../interfaces';
import { DrawingsGroupUtils } from '../../utils/drawings-group-utils';

export function useSelectedGroupRoots(): DrawingsGeometryGroup[] {
  const selectedGroups = useSelector<State, string[]>(state => state.drawings.selectGeometryGroup);
  const drawingsGroups = useSelector<State, DrawingsGeometryGroup[]>(
    state => state.drawings.drawingGeometryGroups,
  );

  return useMemo(() => {
    return DrawingsGroupUtils.getSelectedGroupRoots(selectedGroups, drawingsGroups);
  }, [selectedGroups, drawingsGroups]);
}
