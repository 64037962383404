import OpenReplayTracker from '@openreplay/tracker';

export class OpenReplaySessionTracker {
  private static _tracker: OpenReplayTracker;
  private static _projectKey: string;

  public static setKey(projectKey: string): void {
    OpenReplaySessionTracker._projectKey = projectKey;
  }

  public static getInstance(): OpenReplayTracker {
    if (process.env.NODE_ENV === 'development') {
      return null;
    }
    if (!OpenReplaySessionTracker._projectKey) {
      return null;
    }
    if (!OpenReplaySessionTracker._tracker) {
      OpenReplaySessionTracker._tracker = new OpenReplayTracker({
        projectKey: OpenReplaySessionTracker._projectKey,
        ingestPoint: 'https://openreplay-test.kreo.net/ingest',
        __DISABLE_SECURE_MODE: true,
      });
      OpenReplaySessionTracker._tracker.start();
    }
    return OpenReplaySessionTracker._tracker;
  }
}
