import * as Ag from 'ag-grid-community';
import { getNodeById } from 'unit-2d-database/components/breakdown-property/utils';
import { Constants } from 'unit-2d-database/components/side-panel/components/property-panel/forms/tree-form/constants';
import { BreakDownPropertyValue, PiaBreakdownNode, Property } from 'unit-2d-database/interfaces';
import { SEPARATOR_PROPERTY_KEY } from '../items-helper/utils';

export const CODE_COLUMN_ID = `[[code]]`;

const getValue = (params: Ag.ValueGetterParams, rowNode: Ag.RowNode): string => {
  const rowCodeMap = params.context.rowCodeMap;
  const rowId = rowNode && rowNode.id;
  if (!rowId) {
    return undefined;
  }
  const code = rowCodeMap && rowCodeMap[rowId];

  return code !== undefined
    ? code
    : params.api.getValue(CODE_COLUMN_ID, rowNode);
};

const setValue = (params: Ag.ValueGetterParams, rowNode: Ag.RowNode, code: string): void => {
  const rowCodeMap = params.context.rowCodeMap;
  if (rowCodeMap) {
    rowCodeMap[rowNode.id] = code;
  } else {
    params.context.rowCodeMap = {
      [rowNode.id]: code,
    };
  }
};

const getLastCodePart = (params: Ag.ValueGetterParams, root: PiaBreakdownNode[]): string => {
  const node = params.node;
  if (node.id === 'ROOT_NODE_ID') {
    return '';
  }
  const groupData: string = node.groupData ? node.groupData['ag-Grid-AutoColumn'] : '';
  const propertyNodeId = groupData.split(SEPARATOR_PROPERTY_KEY)[1];
  const propertyNode = getNodeById(root, propertyNodeId);

  if (propertyNode && propertyNode.code) {
    return propertyNode.code.toString();
  }

  if (node.childIndex === 0) {
    return '1';
  }

  const parent = node.parent;
  if (!parent) {
    return '';
  }
  const children = parent.childrenAfterSort;
  const prevChild = children[node.childIndex - 1];
  const prevCode = prevChild ? getValue(params, prevChild) : undefined;

  if (prevCode) {
    const prevCodeParts = prevCode.split(Constants.codeSeparator);
    const prevLastCode = prevCodeParts[prevCodeParts.length - 1];
    if (!Number.isNaN(Number(prevLastCode))) {
      return (Number(prevLastCode) + 1).toString();
    }
  }

  return (node.childIndex + 1).toString();
};

const getRoot = (
  params: Ag.ValueGetterParams,
  properties: Array<Property<BreakDownPropertyValue>>,
): PiaBreakdownNode[] => {
  const field = params.node.field;
  if (!field) {
    return [];
  }
  const propertyNameParts = (new RegExp('(.*) \\{\\w+\\}', 'g')).exec(field);
  if (!propertyNameParts) {
    return [];
  }
  const property = properties.find((p) => p.name === propertyNameParts[1]);
  const root = property ? property.value.root : [];
  return root;
};

export const getCodeColumn = (
  properties: Array<Property<BreakDownPropertyValue>>,
): Ag.ColDef => {
  return (
    {
      colId: CODE_COLUMN_ID,
      field: CODE_COLUMN_ID,
      width: 98,
      headerName: `Code`,
      lockPosition: true,
      lockPinned: true,
      pinned: 'left',
      lockVisible: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      enableRowGroup: false,
      rowGroup: false,
      valueGetter: (params: Ag.ValueGetterParams) => {
        const node = params.node;
        if (!node.group) {
          return '';
        }
        const parentCode = getValue(params, node.parent);
        const lastCodePart = getLastCodePart(params, getRoot(params, properties));
        const code = parentCode ? `${parentCode}.${lastCodePart}` : lastCodePart.toString();
        setValue(params, node, code);
        return code;
      },
    }
  );
};

export const extendsCodeColumns = (
  columnsMap: Record<string, Ag.ColDef>,
  properties: Array<Property<BreakDownPropertyValue>>,
): void => {
  columnsMap[CODE_COLUMN_ID] = getCodeColumn(properties);
};
