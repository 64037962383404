import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Assembly, Item, Property, UpdateAssemblyPayload } from 'unit-2d-database/interfaces';
import { TwoDDatabaseActions } from 'unit-2d-database/store-slice';
import { SidePanelContentProps } from '../../interfaces';
import { AssemblyPanel, OwnState } from '../assembly-panel/assembly-panel';

interface DispatchProps {
  update: (updateAssembly: UpdateAssemblyPayload) => void;
  create: (name: string, items: Item[]) => void;
}

interface Props extends SidePanelContentProps<Property, OwnState>, DispatchProps {}

const AssemblyPanelDatabeseComponent = (
  props: Props,
): JSX.Element => {
  const isShowRenameDialog = useCallback((assembliesDatabase: Assembly[], data: Assembly, formName: string) => {
    return !!assembliesDatabase.find(({ id, name }) => (!data || data.id !== id) &&  name === formName);
  }, []);
  return (
    <AssemblyPanel {...props} isShowRenameDialog={isShowRenameDialog} />
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return ({
    update: (payload) => dispatch(TwoDDatabaseActions.updateAssemblyRequest(payload)),
    create: (name, items) => dispatch(TwoDDatabaseActions.createAssemblyRequest({ name, items })),
  });
};

export const AssemblyPanelDatabese = connect(null, mapDispatchToProps)(AssemblyPanelDatabeseComponent);
