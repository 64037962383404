import styled from 'styled-components';

const WIDTH = 360;

const Container = styled.div`
  z-index: 1000;
  width: ${WIDTH}px;
  height: 409px;
  background-color: ${p => p.theme.color.backgroundRush};
  border-radius: 30px;
`;

const IconListContainer = styled.div`
  overflow: auto;
  box-sizing: border-box;
  height: calc(100% - 60px);
  padding: 15px 20px 25px 25px;
`;

const IconsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill,80px);
  grid-template-rows: repeat(auto-fill,80px);
  grid-gap: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
`;

const Header = styled.div`
  height: 60px;
  padding: 0 30px;
  border-bottom: 1px solid  ${p => p.theme.color.backgroundRush};
  box-sizing: border-box;
`;

export const Styled = {
  Container,
  IconsList,
  IconListContainer,
  Header,
};
