import { UnitTypes } from 'common/utils/unit-util';
import { CEMeasurementsPercentageExtractorType as PercentExtractorType } from 'unit-cost-estimate/constants';
import {
  CEMeasurementActivity,
  CEMeasurementExtractorFunction,
  CEMeasurementNode,
  CEMeasurementNodeResponse,
  CEMeasurementValue,
} from 'unit-cost-estimate/interfaces';
import { CEMeasurementsUtils } from './ce-measurements-utils';

function getIdWithPostfix(extractorId: string, postfix: PercentExtractorType): string {
  return `${extractorId} ${postfix}`;
}

function getPrimaryValueId(extractorId: string): string {
  return getIdWithPostfix(extractorId, PercentExtractorType.PrimaryValue);
}

function getSecondaryValueId(extractorId: string): string {
  return getIdWithPostfix(extractorId, PercentExtractorType.SecondaryValue);
}

function getPrimaryPercentageId(extractorId: string): string {
  return getIdWithPostfix(extractorId, PercentExtractorType.PrimaryPercentage);
}

function getSecondaryPercentageId(extractorId: string): string {
  return getIdWithPostfix(extractorId, PercentExtractorType.SecondaryPercentage);
}

function getBaseExtractorId(extractorId: string): string {
  // eslint-disable-next-line max-len
  const regexp = new RegExp(`\\s((${PercentExtractorType.PrimaryPercentage})|(${PercentExtractorType.SecondaryPercentage})|(${PercentExtractorType.PrimaryValue})|(${PercentExtractorType.SecondaryValue}))$`);
  return extractorId.replace(regexp, '');
}

function getPercentageExtractors(extractor: CEMeasurementExtractorFunction): CEMeasurementExtractorFunction[] {
  return [
    {
      allowPercentage: false,
      extractorFunctionId: getPrimaryPercentageId(extractor.extractorFunctionId),
      unitName: UnitTypes.Percentage,
    },
    {
      allowPercentage: false,
      extractorFunctionId: getPrimaryValueId(extractor.extractorFunctionId),
      unitName: extractor.unitName,
    },
    {
      allowPercentage: false,
      extractorFunctionId: getSecondaryPercentageId(extractor.extractorFunctionId),
      unitName: UnitTypes.Percentage,
    },
    {
      allowPercentage: false,
      extractorFunctionId: getSecondaryValueId(extractor.extractorFunctionId),
      unitName: extractor.unitName,
    },
  ];
}

function getRelatedValues(value: number, percentage: number): Record<PercentExtractorType, number> {
  return {
    [PercentExtractorType.PrimaryValue]: value * percentage,
    [PercentExtractorType.SecondaryValue]: value * (1 - percentage),
    [PercentExtractorType.PrimaryPercentage]: percentage,
    [PercentExtractorType.SecondaryPercentage]: 1 - percentage,
  };
}

function getPercentageExtractorValues(
  measurement: CEMeasurementValue, percentage: CEMeasurementValue,
): CEMeasurementValue[] {
  if (!percentage) {
    return [null, null, null, null];
  }

  const isNeedManualValue = measurement.manualValue && percentage.manualValue;
  const value = CEMeasurementsUtils.getMeasurementValue(measurement);
  const percentageValue = CEMeasurementsUtils.getMeasurementValue(percentage);

  const autoValues = getRelatedValues(measurement.autoValue, percentage.autoValue);
  const manualValues = isNeedManualValue ? getRelatedValues(value, percentageValue) : null;

  return [
    {
      autoValue: autoValues[PercentExtractorType.PrimaryPercentage] * 100,
      manualValue: manualValues && manualValues[PercentExtractorType.PrimaryPercentage] * 100,
    },
    {
      autoValue: autoValues[PercentExtractorType.PrimaryValue],
      manualValue: manualValues && manualValues[PercentExtractorType.PrimaryValue],
    },
    {
      autoValue: autoValues[PercentExtractorType.SecondaryPercentage] * 100,
      manualValue: manualValues && manualValues[PercentExtractorType.SecondaryPercentage] * 100,
    },
    {
      autoValue: autoValues[PercentExtractorType.SecondaryValue],
      manualValue: manualValues && manualValues[PercentExtractorType.SecondaryValue],
    },
  ];
}

function getMappedMeasurements(
  measurements: CEMeasurementValue[],
  percentages: CEMeasurementValue[],
  extractorIndexWithPercentageIndexSet: Set<number>,
): CEMeasurementValue[] {
  const result: CEMeasurementValue[] = [];
  for (const [index, measurement] of measurements.entries()) {
    result.push(measurement);
    if (extractorIndexWithPercentageIndexSet.has(index)) {
      result.push(...getPercentageExtractorValues(measurement, percentages[index]));
    }
  }

  return result;
}

function mapToMeasurementNode(measurementResponse: CEMeasurementNodeResponse): CEMeasurementNode {
  const extractorFunctions: CEMeasurementExtractorFunction[] = [];
  const extractorWithPercentageIndexSet = new Set<number>();
  for (const [index, extractor] of measurementResponse.extractorFunctions.entries()) {
    extractorFunctions.push(extractor);
    if (extractor.allowPercentage) {
      extractorWithPercentageIndexSet.add(index);
      extractorFunctions.push(...getPercentageExtractors(extractor));
    }
  }

  const activities: CEMeasurementActivity[] = measurementResponse.activities.map(activity => ({
    activityId: activity.activityId,
    name: activity.name,
    level: activity.level,
    measurements: getMappedMeasurements(activity.measurements, activity.percentages, extractorWithPercentageIndexSet),
  }));

  return {
    ...measurementResponse,
    extractorFunctions,
    activities,
  };
}


export const CEPercentageExtractorUtils = {
  mapToMeasurementNode,
  getRelatedValues,
  getBaseExtractorId,
  getPrimaryValueId,
  getSecondaryValueId,
  getPrimaryPercentageId,
  getSecondaryPercentageId,
};
