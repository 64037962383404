import { useCallback } from 'react';

import { DrawingsFile } from 'common/components/drawings/interfaces/drawings-file-info';

type UseSelectChangeCallBacks = (
  file: DrawingsFile,
  onChangeDrawingSelection: (id: string[], select: boolean, openLast: boolean) => void,
) => [() => void, () => void];

export const useSelectChangeCallbacks: UseSelectChangeCallBacks = (file, onChangeDrawingSelection) => {
  const selectAll = useCallback(() => {
    const pageIds = file.pages.map(p => p.drawingId);
    onChangeDrawingSelection(pageIds, true, false);
  }, [onChangeDrawingSelection, file]);
  const deselectAll = useCallback(() => {
    const pageIds = file.pages.map(p => p.drawingId);
    onChangeDrawingSelection(pageIds, false, false);
  }, [onChangeDrawingSelection, file]);

  return [selectAll, deselectAll];
};
