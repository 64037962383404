import { useCallback } from 'react';
import { DrawingsDrawMode } from '../../enums';
import { useDrawModeApi } from '../../hooks';
import { useTurnOnBucketFillEffect } from '../../hooks/use-turn-on-bucket-fill-effect';
import { SaveDrawModeOptions } from '../../interfaces';

export function useSetDrawMode(): (drawMode: DrawingsDrawMode, options?: SaveDrawModeOptions) => void {
  const { setDrawMode } = useDrawModeApi();
  const turnOnBucketFill = useTurnOnBucketFillEffect();

  return useCallback((drawMode: DrawingsDrawMode, options?: SaveDrawModeOptions) => {
    if (drawMode === DrawingsDrawMode.BucketFill) {
      turnOnBucketFill(() => setDrawMode(drawMode, options));
    } else {
      setDrawMode(drawMode, options);
    }
  }, [setDrawMode, turnOnBucketFill]);
}

export function useToggleDrawMode(): (drawMode: DrawingsDrawMode, options?: SaveDrawModeOptions) => void {
  const { drawMode } = useDrawModeApi();
  const setDrawMode = useSetDrawMode();

  return useCallback((newDrawMode: DrawingsDrawMode, options?: SaveDrawModeOptions) => {
    if (newDrawMode === drawMode) {
      setDrawMode(DrawingsDrawMode.Disabled);
    } else {
      setDrawMode(newDrawMode, options);
    }
  }, [setDrawMode, drawMode]);
}
