

import { DrawingsCanvasConstants } from 'common/components/drawings/constants/drawing-canvas-constants';
import { DrawingsInstanceType } from 'common/components/drawings/enums/drawings-instance-type';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { PaperIntersectionUtils } from 'common/components/drawings/utils/paper-utils';
import { DrawingsPaperColorInfo } from '../../../interfaces/drawings-canvas-context-props';
import { FinishedDrawingElement, TryAddPointConfig } from './drawings-geometry-temp-entity';
import {
  DrawingsGeometryTempEntityWithStroke,
  DrawingsGeometryTempEntityWithStrokeConfig,
} from './drawings-geometry-temp-entity-with-stroke';


export class DrawingsGeometryTempPolygonEntity
<T extends DrawingsGeometryTempEntityWithStrokeConfig = DrawingsGeometryTempEntityWithStrokeConfig>
  extends DrawingsGeometryTempEntityWithStroke<T>  {
  constructor(config: T) {
    super(config);
    this._path.fillColor = this._config.color.fill;
    this.updateStrokeParams();
  }

  public canComplete(addLastPoint: boolean): boolean {
    return this._path.segments.length > 3 - Number(addLastPoint);
  }

  public get isValidArea(): boolean {
    const threshold = DrawingsCanvasConstants.polygonAreaThreshold
      / this._config.renderParametersContextObserver.getContext().zoom;
    return Math.abs(this._path.area) > threshold;
  }

  public get canSave(): boolean {
    return !PaperIntersectionUtils.hasLineIntersectionsWithPath(
      this._path.segments,
      [this._path.firstSegment.point, this._path.lastSegment.previous.point],
      1,
      this._path.segments.length - 2,
    );
  }

  public override tryAddPoint(point: paper.Point, config?: TryAddPointConfig): boolean {
    return this.canAddPoint(point) ? super.tryAddPoint(point, config) : false;
  }

  public convert(
    addLastPoint: boolean,
    instanceType: DrawingsInstanceType,
    keyPrefix: string = '',
  ): FinishedDrawingElement[] {
    const {
      points,
      polygon,
    } = DrawingsPaperUtils.convertPathToPolygon(
      this._path,
      this._config.color.stroke.toCSS(true),
      this._config.strokeWidth,
      this._config.strokeStyle,
      addLastPoint,
      keyPrefix,
    );
    const { polygonHeight, offset } = this._config.newDrawingStylesObserver.getContext();
    if (polygonHeight !== null && polygonHeight !== undefined) {
      polygon.height = polygonHeight;
    }
    if (offset !== null && offset !== undefined) {
      polygon.offset = offset;
    }
    return [{
      type: instanceType,
      geometry: polygon,
      points,
    }];
  }

  public updateColor(color: DrawingsPaperColorInfo): void {
    super.updateColor(color);
    this._path.fillColor = color.fill;
  }

  public tryToSnappPoint(point: paper.Point): boolean {
    const threshold = DrawingsCanvasConstants.snappingThreshold
      / this._config.renderParametersContextObserver.getContext().zoom;
    if (this._path.segments.length > 3) {
      const toFirst = this._path.firstSegment.point.subtract(point).length;
      return toFirst < threshold;
    }
    return false;
  }

  private canAddPoint(point: paper.Point): boolean {
    return this.pointsCount <= 2 || !PaperIntersectionUtils.hasLineIntersectionsWithPath(
      this._path.segments,
      [this._path.lastSegment.previous.point, point],
      0,
      this._path.segments.length - 2,
    );
  }
}
