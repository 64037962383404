import { IconInputStyled, InputStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { DataBaseSelectorTableStyled } from 'common/components/database-selector-table';
import { MenuPropertiesStyled } from 'unit-2d-database/components/menu-properties';

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const PanelBlock = styled.div`
flex: 1;
display: flex;
flex-direction: column;
padding: 5px;
background: ${p => p.theme.color.background};
&:first-child {
  padding-right: 0px;
}
${MenuPropertiesStyled.Container} {
  position: absolute;
  right: 0;
  z-index: 1;
}
`;

const AssemblyForm = styled.div`
  padding: 10px;
  ${IconInputStyled.Container} {
    width: 100%;

    ${IconInputStyled.InputContainer} {
      flex-grow: 1;
      ${InputStyled.InputContainer} {
        width: 100%;
      }
    }
  }
`;

const ScrollBarContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const TableContainer = styled.div`
  height: 100%;
  ${DataBaseSelectorTableStyled.Container} {
    border-left: 1px solid ${p => p.theme.color.background};
  }
`;

const PanelContainer = styled.div`
  height: 100%;
  border-radius: 10px;
  background: ${p => p.theme.color.backgroundRush};
  display: flex;
  flex-direction: column;
`;

export const Styled = {
  Container,
  PanelBlock,
  AssemblyForm,
  ScrollBarContainer,
  TableContainer,
  PanelContainer,
};
