import { useCallback, useState } from 'react';
import { ConstantFunctions } from 'common/constants/functions';

interface OpenState {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export interface Position {
  x: number;
  y: number;
}

interface MenuState<T = Position> {
  isOpen: boolean;
  position: T;
  onOpen: (e: React.MouseEvent, right?: boolean) => void;
  onClose: () => void;
}

export function useOpenMenu(): OpenState {
  const [isOpen, setOpen] = useState(false);
  const onClose = useCallback(() => setOpen(false), []);
  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);
  return {
    isOpen,
    onOpen,
    onClose,
  };
}

export function useOpenMenuWithRect(enabled: boolean): MenuState<DOMRect> {
  const { isOpen, onClose, onOpen: openMenu } = useOpenMenu();
  const [rect, setRect] = useState<DOMRect | null>(null);
  const onOpen = useCallback((e: React.MouseEvent) => {
    ConstantFunctions.stopEvent(e);
    setRect(e.currentTarget.getBoundingClientRect());
    openMenu();
  }, []);
  if (!enabled) {
    return {
      isOpen: false,
      position: null,
      onOpen: ConstantFunctions.doNothing,
      onClose: ConstantFunctions.doNothing,
    };
  }
  return {
    isOpen,
    position: rect,
    onOpen,
    onClose,
  };
}

export function useOpenMenuWithPosition(enabled: boolean): MenuState {
  const { isOpen, onClose, onOpen: openMenu } = useOpenMenu();
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const onOpen = useCallback((e: React.MouseEvent, right?: boolean) => {
    ConstantFunctions.stopEvent(e);
    const buttonRect = e.currentTarget.getBoundingClientRect();
    const x = right ? buttonRect.left + buttonRect.width : buttonRect.left;
    const y = buttonRect.bottom + 10;
    setPosition({ x, y });
    openMenu();
  }, []);
  if (!enabled) {
    return {
      isOpen: false,
      position: { x: 0, y: 0 },
      onOpen: ConstantFunctions.doNothing,
      onClose: ConstantFunctions.doNothing,
    };
  }
  return {
    isOpen,
    position,
    onOpen,
    onClose,
  };
}
