import autobind from 'autobind-decorator';
import { KreoColors } from 'common/enums/kreo-colors';

export class StepWrapperEngineApi {
  private readonly warningColor: string = '#ffae3d';
  private readonly trivialColor: string = KreoColors.gray6;
  private engine: any;

  constructor(engine: any) {
    this.engine = engine;
  }

  @autobind
  public paintEngine(trivial: number[], warning: number[]): void {
    if (!this.engine) {
      return;
    }

    if (trivial.length > 0 || warning.length > 0) {
      this.engine.setColorTint(null);
      this.engine.setColorTint(this.trivialColor, trivial);
      this.engine.setColorTint(this.warningColor, warning);
    }
  }

  @autobind
  public clearEngine(): void {
    if (!this.engine) {
      return;
    }

    this.engine.setColorTint(null);
  }

  @autobind
  public dropSelect(): void {
    if (!this.engine) {
      return;
    }

    this.engine.setSelected([]);
  }

  @autobind
  public showAll(): void {
    if (!this.engine) {
      return;
    }

    this.engine.setRenderMode('standard');
  }

  @autobind
  public isolate(ids: number[]): void {
    if (!this.engine) {
      return;
    }

    this.engine.setRenderMode('ghost');
    this.engine.setRenderMode('standard', ids);
  }
}
