import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconExpandedRows: React.FC = () => {
  return (
    <svg
      width='8px'
      height='8px'
      viewBox='0 0 8 8'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icons/8x8/expanded-rows' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M2.2236068,5.9472136 C1.97661755,6.07070822 1.67628103,5.97059605 1.5527864,5.7236068
          C1.42929178,5.47661755 1.52940395,5.17628103 1.7763932,5.0527864 L4,3.94098301 L6.2236068,5.0527864
          C6.47059605,5.17628103 6.57070822,5.47661755 6.4472136,5.7236068 C6.32371897,5.97059605
          6.02338245,6.07070822 5.7763932,5.9472136 L4,5.05901699 L2.2236068,5.9472136 Z M4,2.55901699
          L2.2236068,3.4472136 C1.97661755,3.57070822 1.67628103,3.47059605 1.5527864,3.2236068
          C1.42929178,2.97661755 1.52940395,2.67628103 1.7763932,2.5527864 L4,1.44098301 L6.2236068,2.5527864
          C6.47059605,2.67628103 6.57070822,2.97661755 6.4472136,3.2236068 C6.32371897,3.47059605 6.02338245,3.57070822
          5.7763932,3.4472136 L4,2.55901699 Z'
          id='Combined-Shape'
          fill={KreoColors.f4}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
