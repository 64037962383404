import { DialogState } from 'common/UIKit/dialogs/dialog-state';
import { kreoDialogReducerMethods } from 'common/UIKit/dialogs/reducer-methods';

const INITIAL_STATE: DialogState = {};


export default function(
  state: DialogState = INITIAL_STATE,
  action: any = { type: '', payload: null },
): DialogState {
  if (action.type in kreoDialogReducerMethods) {
    return kreoDialogReducerMethods[action.type](state, action.payload);
  } else {
    return state;
  }
}
