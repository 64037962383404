import React, { useContext } from 'react';

import {
  DrawingsElementAndGroupOperationsContext,
  DrawingsElementAndGroupOperationsContextProps,
} from './element-and-group-operations-context';


export function withDrawingsElementAndGroupOperationsContext<P>(
  Component: React.ComponentType<P & DrawingsElementAndGroupOperationsContextProps>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof DrawingsElementAndGroupOperationsContextProps>>> {
  return (props: P) => {
    const apiContext = useContext(DrawingsElementAndGroupOperationsContext);
    return <Component {...props} {...apiContext}/>;
  };
}
