import * as React from 'react';


import { FlatPanel as Fp } from 'common/UIKit';

const FlatPanel: any = Fp;


interface Props {
  caption: any;
  onClick: () => void;
  controlName: string;
}

export const BaseControl: React.FC<Props>  = (props) => {
  return (
    <FlatPanel
      floatVertical={0}
      floatHorizontal={0}
      onClick={props.onClick}
      controlName={props.controlName}
    >
      {props.caption}
    </FlatPanel>
  );
};
