import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import { DefaultCellRendererParams } from 'common/components/excel-table/default-cell-renderer';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';

export class RangeSelection {
  private isMouseDown: boolean;
  private selectRange: Ag.CellRangeParams;

  @autobind
  public onMouseDown(rowId: string, columnId: string, params: DefaultCellRendererParams): void {
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);

    this.selectRange = {
      rowStartIndex: rowIndex,
      columnStart: columnId,
      rowEndIndex: rowIndex,
      columnEnd: columnId,
    };
    this.isMouseDown = true;
    this.updateRangeSelection(params);
  }

  @autobind
  public onMouseEnter(rowId: string, columnId: string, params: DefaultCellRendererParams): void {
    if (this.isMouseDown) {
      const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
      this.selectRange = { ...this.selectRange, rowEndIndex: rowIndex, columnEnd: columnId };
      this.updateRangeSelection(params);
      const value = this.getRangeSelectionString();
      params.context.referenceReader.updateTarget(value);
    }
  }

  @autobind
  public onMouseUp(): void {
    this.isMouseDown = false;
  }

  private updateRangeSelection(params: DefaultCellRendererParams): void {
    params.api.clearRangeSelection();
    params.api.addCellRange(this.selectRange);
  }


  private getRangeSelectionString(): string {
    const endId = ExcelTableRowIdentification.getRowIdFromIndex(this.selectRange.rowEndIndex);
    const endColumn = this.selectRange.columnEnd;

    return `:${endColumn}${endId}`;
  }
}
