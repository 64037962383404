import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import './nrm-category-toggle.scss';

import { State as ReduxState } from '../../../../common/interfaces/state';
import { DatabaseActivityListingActions } from '../../actions/creators/database-activity-listing';
import { ActivityCategoryType } from '../../enums';

interface ReduxProps {
  selectedCategoryType: ActivityCategoryType;
}

interface ReduxActions {
  setSelectedCategoryType: (type: ActivityCategoryType) => void;
}

interface Props extends ReduxProps, ReduxActions {}


class NrmCategoryToggleComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const { selectedCategoryType } = this.props;
    return (
        <div className={`nrm-category-toggle ${selectedCategoryType}`}>
          <div
            className='nrm-category-toggle__item'
            onClick={this.onSelectNrm1}
          >
            NRM 1
          </div>
          <div
            className='nrm-category-toggle__item'
            onClick={this.onSelectNrm2}
          >
            NRM 2
          </div>
        </div>
    );
  }

  @autobind
  private onSelectNrm1(): void {
    if (this.props.selectedCategoryType !== ActivityCategoryType.Nrm1) {
      this.props.setSelectedCategoryType(ActivityCategoryType.Nrm1);
    }
  }
  @autobind
  private onSelectNrm2(): void {
    if (this.props.selectedCategoryType !== ActivityCategoryType.Nrm2) {
      this.props.setSelectedCategoryType(ActivityCategoryType.Nrm2);
    }
  }
}

const mapStateToProps = (state: ReduxState): ReduxProps => {
  return {
    selectedCategoryType:
      state.database.currentDatabase.activityListing.categories.selectedCategoryType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxActions => {
  return {
    setSelectedCategoryType: type =>
      dispatch(DatabaseActivityListingActions.setSelectedCategoryType(type)),
  };
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export const NrmCategoryToggle = connector(NrmCategoryToggleComponent);
