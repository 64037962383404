import styled from 'styled-components';

export const Wrapper = styled.div`
  > div {
    background-color: transparent;
    backdrop-filter: none;
  }
`;

export const MenuFileManagerButton = styled.div`
  top: 0px;
  left: 244px;
  height: 60px;
  display: flex;
  align-items: center;

  > svg {
    width: 20px;
    height: 20px;
    fill: ${p => p.theme.color.gray};
  }
`;

export const MenuFileManager = styled.div`
  position: absolute;
  top: 45px;
  left: 140px;
  width: 265px;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  background-color: ${p => p.theme.color.nightBackground};
  border-radius: 15px;

  > div {
    > div:first-child {
      height: 47px;
    }
    > div:last-child {
      height: 47px;
      > div:first-child {
        margin: 0 auto;
      }
    }
  }
`;

export const Styled = {
  Wrapper,
  MenuFileManagerButton,
  MenuFileManager,
};
