import styled from 'styled-components';

const Container = styled.div`
`;

const Counter = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;

  #left_round_button {
    pointer-events: ${p => p.disabled ? 'none' : 'all'};
    > svg {
      fill: ${p => p.disabled && p.theme.color.disabled};
    }
  }

  button {
    border: 1px solid ${props => props.theme.color.background};
    box-shadow: none;
  }
`;

const CounterText = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 30px;
  margin: 0 20px;
  box-sizing: border-box;
  border-radius: 25px;
  border: 1px solid ${props => props.theme.color.background};
`;

export const Styled = {
  Container,
  Counter,
  CounterText,
};
