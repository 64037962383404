import React from 'react';
import ReactDOM from 'react-dom';
import { getOrCreateRoot } from '../utils';


export class ModalRootWrapper extends React.PureComponent {
  public static root: HTMLDivElement;

  constructor(props: {}) {
    super(props);
    ModalRootWrapper.getRoot();
  }

  public static getRoot(): HTMLDivElement {
    if (!ModalRootWrapper.root) {
      ModalRootWrapper.root = getOrCreateRoot();
    }
    return ModalRootWrapper.root;
  }

  public render(): React.ReactNode {
    return ReactDOM.createPortal(
      this.props.children,
      ModalRootWrapper.root,
    );
  }
}
