import classNames from 'classnames';
import * as React from 'react';

import './internal-header.scss';

import { CompaniesSelect } from 'common/components/companies-select';
import { ProfileIcon } from 'common/components/profile/profile-icon';
import { ProjectPeoplePanel } from 'common/components/project-people-panel';
import { TitleEditable } from 'common/components/title-editable';
import { VideoUrl } from 'common/constants/video-url';
import { FaqCaption } from 'common/enums/faq-caption';
import { HeaderContext } from 'common/enums/header-context';
import { FaqLink } from 'common/UIKit/faq-link';
import { RevisionHeader } from 'unit-cost-estimate/headers';
import { HelpCenter } from '../../units/analytics/components/help-center';
import { BellBadge } from '../../units/notifications/components/bell-notifications';
import { Logo } from '../logo';
import {
  DatabaseActivityAssignmentHeader,
  DatabaseHeader,
  ProjectHeader,
  ScenarioHeader,
} from './header-content';

interface Props {
  context?: HeaderContext;
  color?: string;
  backUrl?: string;
  logoType?: number;
  showLeftMenu?: boolean;
  disableToggle?: boolean;
  title?: string;
  faqCaption?: FaqCaption;
  videoUrl?: string;
  onEditTitle?: (newTitle: string) => void;
}

export class InternalHeader extends React.Component<Props> {

  public render(): JSX.Element {
    let content = null;
    let isProjectPage = false;
    let isDisplayUserInfo = true;
    let hideBellBadge = false;
    let isBillingPage = false;
    const { context, title, logoType, onEditTitle, backUrl, showLeftMenu, faqCaption, videoUrl } = this.props;

    switch (context) {
      case HeaderContext.Project:
        content = <ProjectHeader />;
        isProjectPage = true;
        break;
      case HeaderContext.Database:
        content = <DatabaseHeader />;
        break;
      case HeaderContext.DatabaseActivityAssignment:
        content = <DatabaseActivityAssignmentHeader />;
        break;
      case HeaderContext.EditScenario:
        content = <ScenarioHeader />;
        isDisplayUserInfo = false;
        break;
      case HeaderContext.BillingPages:
        content = <CompaniesSelect />;
        hideBellBadge = true;
        isBillingPage = true;
        break;
      case HeaderContext.ProjectRevision:
        content = <RevisionHeader />;
        hideBellBadge = true;
        isBillingPage = true;
        break;
      default:
    }

    return (
      <div
        className={classNames('internal-header', {
          'internal-header--project': isProjectPage,
          'internal-header--billing': isBillingPage,
        })}
      >
        <div className='internal-header__logo'>
          <Logo backUrl={backUrl} logoType={logoType} showLeftMenu={showLeftMenu} />
        </div>
        <div className='internal-header__content'>
          {title ? (
            <div className='internal-header__title'>
              {!onEditTitle ? (
                <p className='internal-header__page-title'>{title}</p>
              ) : (
                <TitleEditable
                  className='internal-header__edit-title'
                  withoutSaveBtn={true}
                  label={title}
                  text={title}
                  onChange={onEditTitle}
                  canEdit={true}
                />
              )}
            </div>
          ) : null}
          {content}
          <HelpCenter url={videoUrl ? videoUrl : VideoUrl.fullDemo} />
          <FaqLink caption={faqCaption} />
        </div>
        {isDisplayUserInfo ? (
          <div
            className={classNames('internal-header__user-info', {
              'internal-header__user-info--without-bell': hideBellBadge,
            })}
          >
            {isProjectPage ? <ProjectPeoplePanel /> : <ProfileIcon showExpandIcon={true} />}
            {!hideBellBadge && <BellBadge />}
          </div>
        ) : null}
      </div>
    );
  }
}
