import { DrawingsCanvasColors } from 'common/components/drawings/constants/drawing-canvas-constants';
import { DrawingMarkShapes } from 'common/components/drawings/constants/drawing-styles';
import { ContextObserver, ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import {
  DrawingsCountGeometry,
  DrawingsSimplifiedBoundingRect,
} from 'common/components/drawings/interfaces/drawings-geometry';
import { ShapeFactory } from '../../../interfaces';
import { DrawingsGeometryEntityCountMark } from '../../count-mark/drawings-geometry-entity-count-mark';
import { DrawingsGeometryPreviewConfig } from './drawings-geometry-preview-config';

interface DrawingsGeometryPreviewCountConfig extends DrawingsGeometryPreviewConfig {
  geometry: DrawingsCountGeometry;
  renderParamsContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  textRenderParametersObserver: ContextObserver<MeasuresViewSettings>;
  shapeCreator: ShapeFactory;
}

export class DrawingsGeometryPreviewCount {
  private _config: DrawingsGeometryPreviewCountConfig;
  private _bounds: DrawingsSimplifiedBoundingRect;
  private _points: DrawingsGeometryEntityCountMark[] = [];

  constructor(config: DrawingsGeometryPreviewCountConfig) {
    this._config = config;
    this.render();
  }

  public get bounds(): DrawingsSimplifiedBoundingRect {
    return this._bounds;
  }

  public set hasError(value: boolean) {
    if (value) {
      this._points.forEach(x => x.changeColor(DrawingsCanvasColors.warningStrokeColor));
    } else {
      this._points.forEach(x => x.changeColor(this._config.color.stroke));
    }
  }

  public destroy(): void {
    this._points.forEach(x => x.destroy());
  }

  public updateLabels(params: MeasuresViewSettings): void {
    this._points.forEach(x => x.updateRenderParams(params));
  }

  public updateRenderParams(params: DrawingsRenderParams): void {
    this._points.forEach(x => x.changeVisualData(params.zoom));
  }

  private render(): void {
    const points = this._config.geometry.points;
    const allXs = [];
    const allYs = [];
    for (let i = 0; i < points.length; i++) {
      const point = this._config.getPoint(points[i]);
      allXs.push(point.x);
      allYs.push(point.y);
      this._points.push(
        new DrawingsGeometryEntityCountMark({
          renderParamsContextObserver: this._config.renderParamsContextObserver,
          id: 'temp',
          geometry: point,
          layer: this._config.group,
          color: this._config.color.stroke,
          order: i + 1,
          rotation: this._config.textRenderParametersObserver.getContext().rotation,
          shapeCreator: this._config.shapeCreator,
          shape: this._config.geometry.shape || DrawingMarkShapes.Circle,
        }),
      );
    }

    this._bounds = {
      left: Math.min(...allXs),
      top: Math.min(...allYs),
      right: Math.max(...allXs),
      bottom: Math.max(...allYs),
    };
  }
}
