import { CodecUtils } from 'common/utils/codec-utils';

export enum BillingCardBrand {
    UnKnown = 'UnKnown',
    Visa = 'Visa',
    Mastercard = 'Mastercard',
    AmericanExpress = 'AmericanExpress',
    Discover = 'Discover',
    Jcb = 'Jcb',
    DinersClub = 'DinersClub',
    Other = 'Other',
}

export const BillingCardBrandC = CodecUtils.createEnumCodec<BillingCardBrand>(BillingCardBrand, 'BillingCardBrand');

