import { State } from 'common/interfaces/state';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { store } from '../../../../store';
import { ConnectionStatus, multiViewActions } from '../multi-view-reducers';

export class ConnectionChecker {
  private connectionStatusChecker = new DeferredExecutor(1000);

  protected handleLifeConnection(): void {
    const state: State = store.getState();
    if (state.multiView.connectionStatus !== ConnectionStatus.connect) {
      store.dispatch(multiViewActions.setConnection(ConnectionStatus.connect));
    }
    this.connectionStatusChecker.reset();
    this.startDisconnect();
  }

  private startDisconnect(): void {
    this.connectionStatusChecker.execute(this.handleLooseConnection);
  }

  private handleLooseConnection(): void {
    store.dispatch(multiViewActions.setConnection(ConnectionStatus.disconnect));
  }
}
