import * as paper from 'paper';

import { DrawingsCanvasColors, DrawingsCanvasConstants } from 'common/components/drawings/constants';
import { DrawingsAllowedPathType } from 'common/components/drawings/interfaces';


export class DrawingsGeometrySelectionArea {
  private _rectangle: paper.Path.Rectangle;

  constructor(point: paper.Point, layer: paper.Layer) {
    const currentSelectionArea = new paper.Path.Rectangle(point, point);
    currentSelectionArea.strokeColor = DrawingsCanvasColors.utility;
    currentSelectionArea.fillColor = DrawingsCanvasColors.utilityTransparent;
    currentSelectionArea.strokeWidth = DrawingsCanvasConstants.lineStroke;
    this._rectangle = currentSelectionArea;
    this._rectangle.addTo(layer);
  }

  public set strokeWidth(value: number) {
    this._rectangle.strokeWidth = value;
  }

  public set dashArray(value: number[]) {
    this._rectangle.dashArray = value;
  }

  public get bounds(): paper.Rectangle {
    return this._rectangle.bounds;
  }

  public updateDragPosition(point: paper.Point): void {
    if (!this._rectangle.segments.length) {
      return;
    }
    this._rectangle.segments[1].point = new paper.Point(point.x, this._rectangle.segments[0].point.y);
    this._rectangle.segments[2].point = point;
    this._rectangle.segments[3].point = new paper.Point(this._rectangle.segments[0].point.x, point.y);
  }

  public destroy(): void {
    this._rectangle.remove();
  }

  public intersects(path: DrawingsAllowedPathType | paper.PathItem): boolean {
    return this._rectangle.intersects(path);
  }
}
