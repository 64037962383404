import * as t from 'io-ts';

export const UniPairInfoC = t.exact(
  t.partial({
    uniSystemId: t.number,
    uniProductId: t.number,
  }),
  'UniPairInfo');

export type UniPairInfo = t.TypeOf<typeof UniPairInfoC>;
