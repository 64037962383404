import Ag from 'ag-grid-community';

export interface DiffToPatch {
  added?: Record<string, any>;
  deleted?: Record<string, any>;
  updated?: Record<string, any>;
}

export const patch = (diff: DiffToPatch, source: (id: string) => Record<string, any>): Ag.RowDataTransaction  => {
  const update = [];
  if (diff.updated) {
    Object.entries(diff.updated).forEach(([key, value]) => {
      const valueToUpdate = source(key);
      Object.keys(value).forEach(field => {
        valueToUpdate[field] = value[field];
      });
      update.push(valueToUpdate);
    });
  }

  const add = [];
  if (diff.added) {
    Object.entries(diff.added).forEach(([key, value]) => {
      const updatedValue = source(key);
      if (updatedValue) {
        Object.keys(value).forEach(field => {
          updatedValue[field] = value[field];
        });
        update.push(updatedValue);
      } else {
        add.unshift(value);
      }
    });
  }

  const remove = [];
  if (diff.deleted) {
    Object.entries(diff.deleted).forEach(([id, value]) => {
      const valueToUpdate = source(id);
      if (!value) {
        remove.push(valueToUpdate);
        return;
      }
      Object.keys(value).forEach(field => {
        valueToUpdate[field] = value[field];
      });
      update.push(valueToUpdate);
    });
  }

  return {
    add,
    remove,
    update,
  };
};
