import './extractor-display-value.scss';

import { mathUtils } from 'common/utils/math-utils';
import { AggregationExtractor } from './aggregation-cell-value-getter';

export const extractorDisplayValue = (aggregateEf: Record<string, AggregationExtractor>): string => {
  return (
    `<div
       class='extractor-display-value'
      >
      ${Object.keys(aggregateEf)
      .sort((a, b) => {
        const orderIndexA = aggregateEf[a].orderIndex;
        const orderIndexB = aggregateEf[b].orderIndex;
        return orderIndexA - orderIndexB;
      }).map((key) => {
        return (
          `<div class='extractor-display-value__row'>
                <div class='extractor-display-value__name'>${aggregateEf[key].name}:</div>
                <div class='extractor-display-value__value'>
                ${mathUtils.round(Number(aggregateEf[key].value), 2)} ${aggregateEf[key].unit}</div>
              </div>`
        );
      })}
    </div>`
  );
};
