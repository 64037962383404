import autobind from 'autobind-decorator';
import * as paper from 'paper';
import { UuidUtil } from 'common/utils/uuid-utils';
import { DrawingsGeometryGroup } from '../..';
import { DrawingsInstanceType } from '../../enums/drawings-instance-type';
import { ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import {
  DrawingsGeometryType,
  DrawingsPolygonGeometry,
  DrawingsSimplifiedBoundingRect,
} from '../../interfaces/drawings-geometry';
import { DrawingsGeometryInstance, DrawingsGeometryInstanceWithId } from '../../interfaces/drawings-geometry-instance';
import { DrawingAnnotationNamingUtils } from '../../utils/drawing-annotation-naming-utils';
import { DrawingsCanvasUtils } from '../../utils/drawings-canvas-utils';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';
import { PasteCallback } from '../interfaces';

interface DrawingsCopyPasteBufferConfig {
  getInstance: (id: string) => DrawingsGeometryInstance;
  getPoint: (id: string) => ShortPointDescription;
  getGroups: () => DrawingsGeometryGroup[];
  pasteWithScale: (apply: () => void) => void;
  paste: PasteCallback;
  rotation: number;
  keepOriginName: boolean;
  targetGroupId: string;
  keepStructure: boolean;
  canEdit3d: boolean;
}

interface DrawingsGeometryRendererBufferedInstance {
  geometry: DrawingsGeometryType;
  type: DrawingsInstanceType;
  name: string;
  id: string;
  groupId: string;
  drawingId: string;
}

export interface DrawingsGeometryCopyPasteBuffer {
  geometry: DrawingsGeometryRendererBufferedInstance[];
  points: Record<string, ShortPointDescription>;
  source: DrawingsSourceOfBuffer;
}

export enum DrawingsSourceOfBuffer {
  Copy,
  Cut,
}

export class DrawingsCopyPasteBuffer {
  private _config: DrawingsCopyPasteBufferConfig;

  private _bufferedInstancesRect: DrawingsSimplifiedBoundingRect;
  private _bufferedInstancesCenter: paper.Point;
  private _bufferedInstances: DrawingsGeometryCopyPasteBuffer;

  constructor(config: DrawingsCopyPasteBufferConfig) {
    this._config = config;
  }

  public get bufferedInstancesSource(): DrawingsSourceOfBuffer {
    return this._bufferedInstances ? this._bufferedInstances.source : null;
  }

  public get bufferedInstances(): DrawingsGeometryCopyPasteBuffer {
    return this._bufferedInstances;
  }

  public set config(config: DrawingsCopyPasteBufferConfig) {
    this._config = config;
  }

  public set keepOriginName(value: boolean) {
    this._config.keepOriginName = value;
  }

  public set targetGroupId(value: string) {
    this._config.targetGroupId = value;
  }

  public set keepStructure(value: boolean) {
    this._config.keepStructure = value;
  }

  public canPasteToZeroPoint({ width, height }: { width: number, height: number }): boolean {
    if (!this.canPaste()) {
      return false;
    }
    const { right, bottom } = this._bufferedInstancesRect;
    return width > right && height > bottom;
  }

  @autobind
  public canPaste(): boolean {
    return !!this._bufferedInstances;
  }

  public isSameDrawingToPaste(drawingId: string): boolean {
    return this._bufferedInstances.geometry[0].drawingId === drawingId;
  }

  public cut(instancesIds: string[]): void {
    if (instancesIds && instancesIds.length) {
      this.moveToBuffer(instancesIds, DrawingsSourceOfBuffer.Cut);
    }
  }

  public copy(instancesIds: string[]): void {
    if (instancesIds && instancesIds.length) {
      this.moveToBuffer(instancesIds, DrawingsSourceOfBuffer.Copy);
    }
  }

  public pasteToSamePosition(drawingId: string): void {
    const leftTopPoint = new paper.Point(
      this._bufferedInstancesRect.left,
      this._bufferedInstancesRect.top,
    );
    return this.pasteWithPoint(leftTopPoint, drawingId);
  }

  public pasteWithPoint(
    coordinates: paper.Point,
    drawingId: string,
    ignoreScale?: boolean,
  ): void {
    if (!this.canPaste()) {
      return;
    }
    const leftTopPoint = new paper.Point(
      DrawingsCanvasUtils.getTopLeftPointByRotation(
        this._bufferedInstancesRect,
        this._config.rotation,
      ),
    );
    const diff = coordinates.subtract(leftTopPoint);
    this.pasteWithPositionDiff(diff, drawingId, ignoreScale);
  }

  public pasteWithCenter(coordinates: paper.Point, drawingId: string): void {
    if (!this.canPaste()) {
      return;
    }

    const diff = coordinates.subtract(this._bufferedInstancesCenter);
    this.pasteWithPositionDiff(diff, drawingId);
  }

  private pasteWithPositionDiff(
    diff: paper.Point,
    drawingId: string,
    ignoreScale?: boolean,
  ): void {
    if (ignoreScale || this._bufferedInstances.geometry[0].drawingId === drawingId) {
      this.applyPaste(diff, drawingId);
    } else {
      this._config.pasteWithScale(() => this.applyPaste(diff, drawingId));
    }
  }

  private applyPaste(diff: paper.Point, drawingId: string): void {
    const points: Record<string, ShortPointDescription> = {};
    const processedPoints = {};
    const processPoint = (pointId: string): string => {
      if (pointId in processedPoints) {
        return processedPoints[pointId];
      }
      const newPointId = UuidUtil.generateUuid();
      const point = this._bufferedInstances.points[pointId];
      points[newPointId] = [point[0] + diff.x, point[1] + diff.y];
      return newPointId;
    };
    const instances = new Array<DrawingsGeometryInstanceWithId>();
    const isCut = this._bufferedInstances.source === DrawingsSourceOfBuffer.Cut;
    const groups = new Set(this._config.getGroups().map((x) => x.id));
    const getInstanceGroupId = (id: string): string => {
      if (this._config.keepStructure) {
        return groups.has(id) ? id : undefined;
      }
      return this._config.targetGroupId;
    };

    const canEdit3d = this._config.canEdit3d;
    const instanceMap = {};
    for (const instance of this._bufferedInstances.geometry) {
      const name = isCut || this._config.keepOriginName
        ? instance.name
        : DrawingAnnotationNamingUtils.getCopyGeometryName(instance.name);
      let geometry: DrawingsGeometryType;
      if (DrawingsGeometryUtils.isPolyGeometry(instance.type, instance.geometry) ||
        DrawingsGeometryUtils.isCount(instance.type, instance.geometry)) {
        geometry = {
          ...instance.geometry,
          points: instance.geometry.points.map(processPoint),
        };
        if (!canEdit3d) {
          if (DrawingsGeometryUtils.isPolyline(instance.type, geometry)) {
            delete geometry.height;
            delete geometry.offset;
            delete geometry.thickness;
          } else if (DrawingsGeometryUtils.isClosedContour(instance.type, geometry)) {
            delete geometry.height;
            delete geometry.offset;
          }
        }
        if (DrawingsGeometryUtils.isPolygon(instance.type, instance.geometry)) {
          if (instance.geometry.children) {
            (geometry as DrawingsPolygonGeometry).children = instance.geometry.children.map((x) => x.map(processPoint));
          }
        }
        const newId = isCut ? instance.id : UuidUtil.generateUuid();
        instances.push({
          id: newId,
          geometry,
          type: instance.type,
          drawingId,
          name,
          groupId: getInstanceGroupId(instance.groupId),
          isAuto: false,
        });

        instanceMap[newId] = instance.id;
      }
    }
    this._config.paste(instances, points, this._bufferedInstances.source, instanceMap);
    if (isCut) {
      this._bufferedInstances.source = DrawingsSourceOfBuffer.Copy;
    }
  }

  private moveToBuffer(instancesIds: string[], source: DrawingsSourceOfBuffer): void {
    const buffer: DrawingsGeometryCopyPasteBuffer = {
      geometry: [],
      points: {},
      source,
    };

    const centerPoint = [0, 0];
    const allXs = [];
    const allYs = [];

    const addPointsToResult = (pointIds: string[]): void => {
      for (const pointId of pointIds) {
        if (pointId in buffer.points) {
          return;
        }
        const point = this._config.getPoint(pointId);
        buffer.points[pointId] = point;
        allXs.push(point[0]);
        allYs.push(point[1]);
        centerPoint[0] += point[0];
        centerPoint[1] += point[1];
      }
    };

    for (const instanceId of instancesIds) {
      const { geometry, type, name, groupId, drawingId } = this._config.getInstance(instanceId);
      buffer.geometry.push({ geometry, type, name, id: instanceId, groupId, drawingId });
      if (DrawingsGeometryUtils.isPolyGeometry(type, geometry) || DrawingsGeometryUtils.isCount(type, geometry)) {
        addPointsToResult(geometry.points);
        if (DrawingsGeometryUtils.isPolygon(type, geometry) && geometry.children) {
          for (const child of geometry.children) {
            addPointsToResult(child);
          }
        }
      }
    }
    this._bufferedInstancesRect = {
      left: Math.min(...allXs),
      top: Math.min(...allYs),
      right: Math.max(...allXs),
      bottom: Math.max(...allYs),
    };
    this._bufferedInstancesCenter = new paper.Point(centerPoint).divide(Object.keys(buffer.points).length);
    this._bufferedInstances = buffer;
  }
}
