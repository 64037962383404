import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { NewInstanceSettingsActionType } from '../actions/types/new-instance-settings';
import { DrawingsState } from '../interfaces';

export const NewInstanceSettingsReducerMethods: ReducerMethods<DrawingsState> = {
  [NewInstanceSettingsActionType.UPDATE_NEW_INSTANCE_SETTINGS]: (state, payload) => {
    return new MonoliteHelper(state)
      .set(_ => _.newInstanceSettings[payload.key], payload.value)
      .get();
  },
};

