import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { ConnectionStatus } from 'common/utils/multi-view/multi-view-reducers';
import { LostConnectionDialog } from './lost-connection-dialog';
import { Styled } from './styled';

interface Props {
  openInNewWindow: () => void;
  canReopen: boolean;
}

export const StatusIcon: React.FC<Props> = ({ openInNewWindow, canReopen }) => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const connectionStatus = useSelector<State, ConnectionStatus>(s => s.multiView.connectionStatus);
  const connectionRef = useRef(connectionStatus);

  useEffect(() => {
    connectionRef.current = connectionStatus;
    setTimeout(() => {
      if (connectionRef.current !== ConnectionStatus.connect) {
        setShowMessage(true);
      }
    }, 3000);
  }, [connectionStatus]);


  return (
    <Styled.StatusContainer>
      <RenderIf condition={showMessage}>
        <LostConnectionDialog
          setShowMessage={setShowMessage}
          openInNewWindow={openInNewWindow}
          canReopen={canReopen}
        />
      </RenderIf>
      <Styled.Status isActive={connectionStatus === ConnectionStatus.connect} />
    </Styled.StatusContainer>
  );
};
