import { QtoReportRowPropertiesKeys } from '../../enums/qto-report-row-properties-keys';
import { QtoReportRowUpdateType } from '../../enums/qto-report-row-update-type';
import { QtoReportRow } from '../../interfaces/quantity-take-off';
import { PropertyHelper } from '../../utils/quantity-take-off-tree-table';

function getDiffStatus(data: QtoReportRow): QtoReportRowUpdateType {
  const property = data.properties && data.properties[QtoReportRowPropertiesKeys.DiffStatus];
  return PropertyHelper.getActualValue(property) || QtoReportRowUpdateType.Undefined;
}


function isRowNotDeleted(data: QtoReportRow): boolean {
  const diffProperty = data.properties && data.properties[QtoReportRowPropertiesKeys.DiffStatus];
  return PropertyHelper.getActualValue(diffProperty) !== QtoReportRowUpdateType.Deleted;
}

export const QuantityTakeOffReportRowDiffStatusUtils = {
  getDiffStatus,
  isRowNotDeleted,
};
