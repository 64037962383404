import { H2 } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { InvitePeopleForm } from 'common/components/invite-people-form';
import { Mood } from 'common/enums/mood';
import { RoleGroup } from 'common/enums/role-group';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { SpinnerPage } from 'routes/spinner-page';
import { SubscriptionRolesApi } from '../../api/subscription-roles';
import { useSaveInvitePeople } from './hooks';
import { HEADER_TEXT } from './interfaces';
import { Styled } from './styled';

interface Props {
  afterSubmit?: () => void;
}

const INVITE_PEOPLE_PAGE_CONFIRM_DIALOG = 'INVITE_PEOPLE_PAGE_CONFIRM_DIALOG';

export const InvitingPeoplePage: React.FC<Props>  = ({
  afterSubmit,
}) => {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [defaultRoles, setDefaultRoles] = useState<string[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const payload = await SubscriptionRolesApi.getDefaultSubscriptionRoles();
      if (payload && payload.roles?.length) {
        const roles = arrayUtils.filterMap(
          payload.roles,
          (role) => role.code !== RoleGroup.Guest,
          (role) => role.name,
        );
        setDefaultRoles(roles);
      }
      setLoaded(true);
    })();
  }, []);
  const saveInvitePeople = useSaveInvitePeople();
  const handleSubmit = useCallback((data) => {
    if (data && data.length === 0) {
      dispatch(KreoDialogActions.openDialog(INVITE_PEOPLE_PAGE_CONFIRM_DIALOG));
      return;
    }
    saveInvitePeople(data);
    if (afterSubmit) {
      afterSubmit();
    }
  }, [saveInvitePeople, afterSubmit, dispatch]);
  const handleDialogSkip = useCallback(() => {
    handleSubmit(null);
  }, [handleSubmit]);
  const handleSubmitDialog = useCallback(() => {
    dispatch(KreoDialogActions.closeDialog(INVITE_PEOPLE_PAGE_CONFIRM_DIALOG));
  }, [dispatch]);

  if (!isLoaded) {
    return <SpinnerPage />;
  }

  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.HeaderTextContainer>
          <H2>{HEADER_TEXT}</H2>
        </Styled.HeaderTextContainer>
        <Styled.BodyContainer>
          <Styled.MessageContentContainer>
            <InvitePeopleForm
              onSubmit={handleSubmit}
              roles={defaultRoles}
            />
          </Styled.MessageContentContainer>
        </Styled.BodyContainer>
      </Styled.Wrapper>
      <ConfirmationDialog
        name={INVITE_PEOPLE_PAGE_CONFIRM_DIALOG}
        title={`Teammates aren't invited`}
        text={`You haven't invited any teammates yet. Do you want to invite them?`}
        confirmButtonText={'Skip'}
        cancelButtonText={'Invite'}
        cancelButtonMood={Mood.Secondary}
        submitButtonMood={Mood.Default}
        onConfirm={handleDialogSkip}
        onCancel={handleSubmitDialog}
        ExtraStyled={Styled.DialogContainer}
        className={'invite-teammates-dialog'}
      />
    </Styled.Container>
  );
};
