import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { useCommentsContext } from 'unit-2d-comments/comments-context';
import { CommentaryTargetTypeGuards, TwoDCommentsActions } from 'unit-2d-comments/index';
import { CommentaryDrawingWithPointTarget, CommentaryTarget, TemporaryComment } from 'unit-2d-comments/interfaces';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { Person } from '../../../../../units/people/interfaces/person';
import { Comment, DrawingsCommentApi } from './comment';

interface StateProps {
  users: Person[];
  currentUserId: string;
  tempCommentaryData: CommentaryTarget;
  selectedCommentId: number;
  temporatyComments: TemporaryComment[];
}

interface OwnProps {
  currentDrawingId: string;
  sendCommentRef: (api: DrawingsCommentApi, id: number) => void;
}

interface DispatchProps {
  onFocusComment: (commentId: number) => void;
}

interface Props extends StateProps, OwnProps, DispatchProps {

}

export const TEMP_COMMENT_ID = 'tempComment';

const DrawingsCommentsListComponent: React.FC<Props> = (
  {
    currentUserId,
    currentDrawingId,
    users,
    tempCommentaryData,
    selectedCommentId,
    sendCommentRef,
    onFocusComment,
    temporatyComments,
  }) => {
  const commentaries = useCommentsContext();
  const { sendEvent } = useAnalytics();

  const handleFocusComment = useCallback((id: number) => {
    onFocusComment(id);
    sendEvent(MetricNames.comments.openComments, { target: 'drawing' });
  }, []);

  const commentariesToShow = useMemo(() => {
    return commentaries.filter((comment) => {
      return CommentaryTargetTypeGuards.isDrawingWithPoint(comment.target)
       && comment.target.pageId === currentDrawingId;
    });
  }, [commentaries, currentDrawingId]);
  const usersDict = useMemo(() => arrayUtils.toDictionaryByKey(users, user => user.id), []);

  return (
    <>
      {
        commentariesToShow.map((comment) => {
          return (
            <Comment
              temporary={false}
              key={comment.id}
              user={usersDict[comment.userId]}
              target={comment.target as CommentaryDrawingWithPointTarget}
              sendCommentRef={sendCommentRef}
              id={comment.id}
              onClick={handleFocusComment}
              isSelected={comment.id === selectedCommentId}
            />
          );
        })
      }
      {
        temporatyComments.map((comment) => {
          if (!CommentaryTargetTypeGuards.isDrawingWithPoint(comment.target)) {
            return null;
          }
          return (
            <Comment
              temporary={true}
              key={comment.tempararyId}
              isSending={true}
              user={usersDict[currentUserId]}
              target={comment.target as CommentaryDrawingWithPointTarget}
              sendCommentRef={sendCommentRef}
              id={comment.tempararyId}
              onClick={onFocusComment}
              isSelected={false}
            />
          );
        })
      }
      { tempCommentaryData && CommentaryTargetTypeGuards.isDrawingWithPoint(tempCommentaryData) ?
        (
          <Comment
            id={TEMP_COMMENT_ID}
            temporary={true}
            user={usersDict[currentUserId]}
            sendCommentRef={sendCommentRef}
            target={tempCommentaryData}
            isSelected={false}
          />
        ) : null
      }
    </>
  );
};

function mapStateToProps(state: State): StateProps {
  return {
    users: state.people.companiesUsers,
    tempCommentaryData: state.twoDComments.newCommentData,
    currentUserId: state.account.id,
    selectedCommentId: state.twoDComments.selectedCommentId,
    temporatyComments: state.twoDComments.temporaryComments,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onFocusComment: (commentId) => {
      dispatch(TwoDCommentsActions.selectComment(commentId));
      dispatch(TwoDCommentsActions.setSelectedCommentId(commentId));
    },
  };
}

export const DrawingsCommentsList = connect(mapStateToProps, mapDispatchToProps)(DrawingsCommentsListComponent);
