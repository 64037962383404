import { InputSelect } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { Styled } from './styled';

interface Props {
  selectedFilter: string;
  onSelectFilter: (filterName: string) => void;
  availableFilters: string[];
}

const ProjectAccessFilterComponent: React.FC<Props> = ({ selectedFilter, onSelectFilter, availableFilters }) => {
  const onSelectFilterCallback = useCallback((index) => {
    onSelectFilter(availableFilters[index]);
  }, [onSelectFilter, availableFilters]);
  const selectedFilterIndex = availableFilters.findIndex(filter => filter === selectedFilter);
  return (
    <Styled.Container>
      <InputSelect
        elements={availableFilters}
        activeElementIndex={selectedFilterIndex || 0}
        onClick={onSelectFilterCallback}
        closeAfterClick={true}
      />
    </Styled.Container>
  );
};

export const ProjectAccessFilter = React.memo(ProjectAccessFilterComponent);

