import styled from 'styled-components';

import { ITEM_TYPE_COLOR } from '../../../constants';
import { FormulaInputAutocompleteItemType } from '../../../enums';


const Container = styled.div<{ selected: boolean }>`
  display: flex;
  color: ${p => p.theme.color.mainFont};
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 7px;
  border-radius: 10px;
  background-color: ${p => p.selected ? p.theme.color.pale : 'transparent'};
  &:hover{ 
    background-color: ${p => p.theme.color.pale};
  }
`;

const Title = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;


const Type = styled.div<{ type: FormulaInputAutocompleteItemType }>`
  padding: 3px 5px;
  color: white;
  background-color: ${p => ITEM_TYPE_COLOR[p.type]};
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 10px;
`;

export const Styled = {
  Container,
  Title,
  Type,
};
