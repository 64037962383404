import * as React from 'react';

export const IconUserInvite: React.FC = () => {
  return (
    <svg
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Icons/16x16/Activity/1.1-add-invite'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M12,4 L14.5,4 C14.7761424,4 15,4.22385763 15,4.5 C15,4.77614237 14.7761424,5 14.5,5 L12,5 L12,
        7.5 C12,7.77614237 11.7761424,8 11.5,8 C11.2238576,8 11,7.77614237 11,7.5 L11,5 L8.5,5 C8.22385763,
        5 8,4.77614237 8,4.5 C8,4.22385763 8.22385763,4 8.5,4 L11,4 L11,1.5 C11,1.22385763 11.2238576,
        1 11.5,1 C11.7761424,1 12,1.22385763 12,1.5 L12,4 Z M3.47937624,9.29917416 C3.18049533,
        8.94967955 3,8.49591825 3,8 C3,6.8954305 3.8954305,6 5,6 C6.1045695,6 7,6.8954305 7,8 C7,
        8.49591825 6.81950467,8.94967955 6.52062376,9.29917416 C7.97560476,9.89764946 9,11.3291946 9,
        13 C9,13.2761424 8.77614237,13.5 8.5,13.5 C8.22385763,13.5 8,13.2761424 8,13 C8,11.3431458 6.65685425,
        10 5,10 C3.34314575,10 2,11.3431458 2,13 C2,13.2761424 1.77614237,13.5 1.5,13.5 C1.22385763,13.5 1,
        13.2761424 1,13 C1,11.3291946 2.02439524,9.89764946 3.47937624,9.29917416 Z M5,9 C5.55228475,9 6,
        8.55228475 6,8 C6,7.44771525 5.55228475,7 5,7 C4.44771525,7 4,7.44771525 4,8 C4,8.55228475 4.44771525,9 5,9 Z'
          id='Combined-Shape'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
