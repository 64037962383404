import { isBoolean } from 'lodash';
import { PersistedStorageState } from 'persisted-storage/interfaces/state';

function selectGroupNameAsGeometry(storage: PersistedStorageState): boolean {
  return isBoolean(storage.drawingUseGroupNameForNewGeometry)
    ? storage.drawingUseGroupNameForNewGeometry
    : true;
}


export const PersistedStorageDrawingsSelectors = {
  selectGroupNameAsGeometry,
};
