import * as Ag from 'ag-grid-community';
import { AgGridHelper } from 'common/ag-grid';
import { DragType, RowDragHelper } from 'common/components/tree-table/row-drag-helper';

export { DragType } from 'common/components/tree-table/row-drag-helper';

export class QtoReportRowDragHelper extends RowDragHelper {
  public setDraggableNode(draggableNode: Ag.RowNode): void {
    if (!draggableNode.isSelected()) {
      return super.setDraggableNode(draggableNode);
    }

    this.draggableNode = draggableNode;
    const allSelectedChildren: Ag.RowNode[] = [];
    (Object.values(draggableNode.parent.childrenMapped) as Ag.RowNode[])
      .filter(children => children.isSelected())
      .forEach(children => allSelectedChildren.push(...children.allLeafChildren));
    this.draggableNodeChildrenIdSet = new Set(allSelectedChildren.map(node => node.id));
  }

  public setOverNode(api: Ag.GridApi, overNode: Ag.RowNode): void {
    this.innerSetOverNode(api, overNode, true);
  }

  public reset(api: Ag.GridApi): void {
    this.innerReset(api, true);
  }

  public moveNode(
    api: Ag.GridApi,
    node: Ag.RowNode,
    onMove: (parentId: string | null, position: number, rowIdsToMove: string[]) => void,
  ): void {
    this.deferredExecutor.reset();
    const rowIdsToMove = this.redrawRows(api, this.draggableNode);
    onMove(node.data.parentId, node.parent.data.children.indexOf(node.id), rowIdsToMove);
    this.reset(api);
  }

  private isRootNodeId(id: string): boolean {
    return id === AgGridHelper.constants.ROOT_NODE_ID;
  }

  private getRowById(api: Ag.GridApi, id: string): Ag.RowNode {
    return !id || this.isRootNodeId(id)
      ? AgGridHelper.getRootNode(api)
      : api.getRowNode(id);
  }

  private getNewParentRow(overNode: Ag.RowNode, dragType: DragType): Ag.RowNode {
    if (dragType === DragType.InsertAfter) {
      return overNode.parent;
    } else {
      return overNode;
    }
  }

  private getInsetPosition(newParentRow: Ag.RowNode, overNode: Ag.RowNode, dragType: DragType): number {
    if (dragType === DragType.InsertAfter) {
      return newParentRow.data.children.findIndex(x => x === overNode.id) + 1;
    } else {
      return newParentRow.data.children.length;
    }
  }

  private redrawRows(api: Ag.GridApi, node: Ag.RowNode): string[] {
    if (!this.overNode) {
      return null;
    }

    const parentRow = node.parent;
    const selectedNodes = node.isSelected()
      ? api.getSelectedNodes()
      : [node];
    const selectedNodeMap = new Map<string, Ag.RowNode>();
    selectedNodes.forEach(x => selectedNodeMap.set(x.id, x));
    const idsToMove = parentRow.data.children.filter(id => selectedNodeMap.has(id));
    const setIdsToMove = new Set(idsToMove);
    parentRow.data.children = parentRow.data.children.filter(x => !setIdsToMove.has(x));
    const replacedNodesData = [];
    for (const id of idsToMove) {
      replacedNodesData.push(selectedNodeMap.get(id).allLeafChildren.map(x => x.data));
    }
    parentRow.updateData(parentRow.data);
    api.applyTransaction({ remove: replacedNodesData.reduce((res, x) => { return res.push(...x) && res; }, []) });


    const newParentRow = this.getNewParentRow(this.overNode, this.dragType);
    const insertPosition = this.getInsetPosition(newParentRow, this.overNode, this.dragType);

    newParentRow.data.children = [
      ...newParentRow.data.children.slice(0, insertPosition),
      ...idsToMove,
      ...newParentRow.data.children.slice(insertPosition),
    ];

    const newParentRowId = newParentRow.id === AgGridHelper.constants.ROOT_NODE_ID
      ? null
      : newParentRow.id;
    replacedNodesData.forEach(x => x[0].parentId = newParentRowId);

    newParentRow.updateData(newParentRow.data);
    api.applyTransaction({
      add: replacedNodesData.map(x => x[0]),
      addIndex: insertPosition,
    });

    const childNodes =  replacedNodesData.reduce((res, x) => res.push(...x.slice(1)) && res, []);
    api.applyTransaction({ add: childNodes });

    const reorderedParent = this.getRowById(api, newParentRowId);
    reorderedParent.childrenAfterGroup = reorderedParent.data.children.map(id => reorderedParent.childrenMapped[id]);
    api.applyTransaction({ update: reorderedParent.allLeafChildren.map(x => x.data) });

    return idsToMove;
  }
}
