import * as React from 'react';

export const KreoIconPolylength: React.FC = () => {
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24'>
      <g
        id='Icons/24x24/Tools/Polylength'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          className='icon__color-light'
          // eslint-disable-next-line max-len
          d='M18,5 L18,19 L10.7071068,19 L5.85355339,14.1464466 C5.65829124,13.9511845 5.34170876,13.9511845 5.14644661,14.1464466 C4.95118446,14.3417088 4.95118446,14.6582912 5.14644661,14.8535534 L10.1464466,19.8535534 C10.2402148,19.9473216 10.3673918,20 10.5,20 L18.5,20 C18.7761424,20 19,19.7761424 19,19.5 L19,4.5 C19,4.22385763 18.7761424,4 18.5,4 L5.5,4 C5.22385763,4 5,4.22385763 5,4.5 C5,4.77614237 5.22385763,5 5.5,5 L18,5 Z'
          id='color2'
          fill='currentColor'
          fillRule='nonzero'
        />
        <path
          // eslint-disable-next-line max-len
          d='M18.5,18 C19.3284271,18 20,18.6715729 20,19.5 C20,20.3284271 19.3284271,21 18.5,21 C17.6715729,21 17,20.3284271 17,19.5 C17,18.6715729 17.6715729,18 18.5,18 Z M10.5,18 C11.3284271,18 12,18.6715729 12,19.5 C12,20.3284271 11.3284271,21 10.5,21 C9.67157288,21 9,20.3284271 9,19.5 C9,18.6715729 9.67157288,18 10.5,18 Z M5.5,13 C6.32842712,13 7,13.6715729 7,14.5 C7,15.3284271 6.32842712,16 5.5,16 C4.67157288,16 4,15.3284271 4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M5.5,3 C6.32842712,3 7,3.67157288 7,4.5 C7,5.32842712 6.32842712,6 5.5,6 C4.67157288,6 4,5.32842712 4,4.5 C4,3.67157288 4.67157288,3 5.5,3 Z M18.5,3 C19.3284271,3 20,3.67157288 20,4.5 C20,5.32842712 19.3284271,6 18.5,6 C17.6715729,6 17,5.32842712 17,4.5 C17,3.67157288 17.6715729,3 18.5,3 Z'
          id='color1'
          fill='currentColor'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};
