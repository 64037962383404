import * as t from 'io-ts';

import { ValidationStepC } from '../enums/validation-step';
import { ValidationStepStateC } from '../enums/validation-step-state';

export const ProjectValidationStateC = t.type(
  {
    documentId: t.number,
    validationStep: ValidationStepC,
    stepState: ValidationStepStateC,
  },
  'ProjectValidationState');

export type ProjectValidationState = t.TypeOf<typeof ProjectValidationStateC>;
