import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import './measurements-engine-layout.scss';

import { State } from 'common/interfaces/state';
import { CEMeasurementsActions } from 'unit-cost-estimate/actions/creators';
import { CEMeasurementsUtils } from 'unit-cost-estimate/utils';
import {
  MeasurementsEngineLayoutApiContextProvider,
} from 'unit-projects/components/measurements-engine-layout-api-context-provider';
import { ValidationResizableEngineLayoutNew } from 'unit-projects/components/validation-resizable-engine-layout-new';
import { ValidationResizableEngineLayoutApi } from 'unit-projects/interfaces/validation-resizable-engine-layout-api';
import { MeasurementsRevitTreeLayout } from './measurements-revit-tree-layout';


interface OwnProps {
  projectId: string;
  children?: React.ReactNode;
}

interface StateProps {
  isShowMeasurements: boolean;
  selectedNodeId: number;
  selectedIds?: number[];
}

interface DispatchProps {
  dropState: () => void;
  load: () => void;
  toggleCheckStatusFromEngine: (ids: number[]) => void;
  selectFromEngine: (id: number) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps {

}


class MeasurementsEngineLayoutComponent extends React.PureComponent<Props> {
  private layoutApi: ValidationResizableEngineLayoutApi = null;
  private isEngineSelect: boolean = true;

  public componentDidMount(): void {
    this.props.load();
  }


  public componentDidUpdate(prevProps: Props): void {
    if (Number.isInteger(this.props.selectedNodeId)) {
      if (prevProps.selectedNodeId !== this.props.selectedNodeId) {
        if (this.layoutApi && this.layoutApi.getSelected().length > 0) {
          this.isEngineSelect = false;
          this.layoutApi.setSelected([]);
        }
        this.layoutApi.showProperties(this.props.isShowMeasurements ? this.props.selectedIds : []);
        this.layoutApi.focus(this.props.selectedIds);
      }
      this.layoutApi.showIds(this.props.selectedIds);
    }
  }

  public componentWillUnmount(): void {
    this.props.dropState();
  }

  public render(): React.ReactNode {
    return (
      <div className='measurements-engine-layout'>
        <MeasurementsRevitTreeLayout/>
        <ValidationResizableEngineLayoutNew
          getApi={this.saveLayoutApi}
          projectId={this.props.projectId}
          isShowResizablePanel={this.props.isShowMeasurements}
          engineSelect={this.engineSelect}
        >
          <MeasurementsEngineLayoutApiContextProvider
            getSelected={this.getSelected}
            onAddSelected={this.onAddSelected}
            onDeselectElement={this.onDeselectElement}
            onSelectElements={this.selectElements}
            onShowProperties={this.showProperties}
            showAll={this.showAll}
          >
            {this.props.children}
          </MeasurementsEngineLayoutApiContextProvider>
        </ValidationResizableEngineLayoutNew>
      </div>
    );
  }

  @autobind
  private showAll(): void {
    if (this.layoutApi) {
      this.layoutApi.showAll();
    }
  }

  @autobind
  private getSelected(): number[] {
    if (this.layoutApi) {
      return this.layoutApi.getSelected();
    }
  }

  @autobind
  private showProperties(bimHandleIds: number[]): void {
    if (this.layoutApi) {
      this.layoutApi.showProperties(bimHandleIds);
    }
  }

  @autobind
  private selectElements(bimHandleIds: number[]): void {
    if (this.layoutApi) {
      this.isEngineSelect = false;
      this.layoutApi.setSelected(bimHandleIds);
      this.layoutApi.focus(bimHandleIds);
    }
  }

  @autobind
  private onDeselectElement(id: number): void {
    if (this.layoutApi) {
      const selected = new Set(this.layoutApi.getSelected());
      selected.delete(id);
      this.isEngineSelect = false;
      const bimHandleIds = Array.from(selected);
      this.layoutApi.setSelected(bimHandleIds);
      this.showProperties(bimHandleIds);
      this.layoutApi.focus(bimHandleIds);
    }
  }

  @autobind
  private onAddSelected(id: number): void {
    if (this.layoutApi) {
      const bimHandleIds = this.layoutApi.getSelected();
      bimHandleIds.push(id);
      this.isEngineSelect = false;
      this.layoutApi.setSelected(bimHandleIds);
      this.showProperties(bimHandleIds);
      this.layoutApi.focus(bimHandleIds);
    }
  }

  @autobind
  private engineSelect(ids: number[]): void {
    if (!this.isEngineSelect) {
      this.isEngineSelect = true;
      return;
    }
    if (this.props.isShowMeasurements) {
      this.props.toggleCheckStatusFromEngine(ids);
    }
    if (ids.length > 0) {
      if (!Number.isInteger(this.props.selectedNodeId)) {
        if (!this.props.isShowMeasurements) {
          this.props.selectFromEngine(ids[0]);
        }
        this.showProperties(ids);
      }
    } else {
      if (this.props.selectedNodeId) {
        this.showProperties(this.props.selectedIds);
      }
    }
  }

  @autobind
  private saveLayoutApi(layoutApi: ValidationResizableEngineLayoutApi): void {
    this.layoutApi = layoutApi;
  }
}


function mapStateToProps({ ceMeasurements }: State): StateProps {
  return {
    isShowMeasurements: ceMeasurements.showMeasurementEditor,
    selectedNodeId: ceMeasurements.selectedNodeId,
    selectedIds: CEMeasurementsUtils.getSelectedIds(ceMeasurements),
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    toggleCheckStatusFromEngine: ids => {
      dispatch(CEMeasurementsActions.toggleCheckStatusFromEngine(ids));
    },
    selectFromEngine: (id) => {
      dispatch(CEMeasurementsActions.selectNodeByEngineId(id));
    },
    load: () => {
      dispatch(CEMeasurementsActions.loadDataRequest());
    },
    dropState: () => {
      dispatch(CEMeasurementsActions.onBlurEdit());
      dispatch(CEMeasurementsActions.dropState());
    },
  };
}


const connector = connect(mapStateToProps, mapDispatchToProps);
export const MeasurementsEngineLayout = connector(MeasurementsEngineLayoutComponent);
