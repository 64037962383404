import React from 'react';
import { RadioMenu } from 'common/components/selection-menus/radio-menu/radio-menu';
import { ProjectType } from 'common/constants/project-type';
import { Styled } from './projects-filters-styled';

interface Props {
  selectedType: ProjectType;
  onChangeSelection: (type: ProjectType) => void;
}

const ProjectTypes = [
  { value: null, name: 'All' },
  ...Object.values(ProjectType).map(x => ({ value: x, name: x })),
];

export const ProjectsFilter: React.FC<Props> = ({ selectedType, onChangeSelection }) => {
  return (
    <Styled.Container>
      <RadioMenu
        onSelectionChanged={onChangeSelection}
        options={ProjectTypes}
        value={selectedType}
      />
    </Styled.Container>
  );
};
