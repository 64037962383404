import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionWith } from 'common/interfaces/action-with';
import { UserMap } from 'common/interfaces/people';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { AppUrls } from '../../../routes/app-urls';
import { AccountApi } from '../../account/api';
import { PeopleActions } from '../../people/actions/actions';
import { ViewerActions } from '../../viewer/actions';
import * as ActionsTypes from '../../viewer/actions/types';
import { CommentStatus, ViewerState } from '../../viewer/interfaces';
import { CommentModel } from '../../viewer/interfaces/comment';
import * as ViewerUtils from '../../viewer/utils';
import { ViewerApi } from '../api/viewer';
import { ViewerUtilsNew } from '../utils/viewer-utils-new';


function* getComments(action: ActionWith<boolean>): SagaIterator {
  try {
    const data: CommentModel[] = yield call(
      action.payload ? ViewerApi.getAllComments : ViewerApi.getUserComments,
    );
    const userGuids: string[] = [];
    for (const comment of data) {
      if (userGuids.indexOf(comment.owner) === -1) userGuids.push(comment.owner);
      for (const subComment of comment.data.subComments) {
        if (userGuids.indexOf(subComment.authorGuid) === -1) userGuids.push(subComment.authorGuid);
      }
    }
    const users: UserMap = yield call(AccountApi.getUsersInfo, userGuids);
    yield put(PeopleActions.saveUserMap(users));
    yield put(ViewerActions.getCommentsSuccess(data));
  } catch (error) {
    console.error('viewer: get comments failed', error, action.payload);
  }
}

function* createComment(action: any): SagaIterator {
  try {
    const engineState = action.engine.getState();
    const data = action.payload;

    const viewerSets: ViewerState = yield selectWrapper(state => state.viewersets);
    data.engineState = JSON.stringify(engineState);
    data.pointPosition = JSON.stringify(viewerSets.timemarker.point);
    data.pointType = viewerSets.timemarker.type === 'view' ? 0 : 1;
    data.data.screen = action.engine.takeScreenshotImmidiately(384, 216).PngBase64;
    yield call(ViewerApi.createComment, action.payload);
  } catch (error) {
    console.error('viewer: create comment failed', error, action.payload);
  }
}

function* createSubcomment(action: any): SagaIterator {
  try {
    const comment = action.payload.value;
    yield call(
      ViewerApi.addSubComment,
      action.payload.parrent,
      action.payload.id,
      comment,
    );
  } catch (error) {
    console.error('viewer: create subcomment failed', error, action.payload);
  }
}

function* getBimInfo(): SagaIterator {
  try {
    const data = yield call(ViewerApi.loadFullBimInfo);
    data.ids = ViewerUtilsNew.prepareTreeAndMapElements(data.allBimInfo);
    yield put(ViewerActions.getDocumentInfoSuccessed(data));
  } catch (error) {
    console.error('viewer: get bim info failed', error);
  }
}

function* hideElement(action: any): SagaIterator {
  try {
    const viewersets: ViewerState = yield selectWrapper(state => state.viewersets);
    const element = ViewerUtils.getByPath(
      ['bimInfo', ...action.payload.identificator.split('.')],
      viewersets,
    );
    const identificator = action.payload.identificator;
    if (identificator.length > 0) {
      const np = [...ViewerUtils.getSimplePathes(action.payload.identificator)];
      yield put(ViewerActions.showCommit(np));
      const pathes = [
        ...ViewerUtils.childIdentificators(identificator.split('.'), element),
        ...ViewerUtils.parentIdentificators(
          action.payload.identificator.split('.'),
          viewersets.bimInfo,
          'none',
        ),
      ];
      yield put(ViewerActions.hideCommit(pathes));
    }
  } catch (error) {
    console.error('viewer: hide element failed', error, action.payload);
  }
}

function* showElements(action: any): SagaIterator {
  try {
    const viewersets: ViewerState = yield selectWrapper(state => state.viewersets);
    const element = ViewerUtils.getByPath(
      ['bimInfo', ...action.payload.identificator.split('.')],
      viewersets,
    );
    const identificator = action.payload.identificator;
    const p = action.payload.identificator;
    if (identificator.length > 0) {
      const np = [...ViewerUtils.getSimplePathes(p)];
      yield put(ViewerActions.showCommit(np));
      const pathes = [
        ...ViewerUtils.childIdentificators(identificator.split('.'), element),
        ...ViewerUtils.parentIdentificators(
          action.payload.identificator.split('.'),
          viewersets.bimInfo,
          'standard',
        ),
      ];
      yield put(ViewerActions.showCommit(pathes));
    }
  } catch (error) {
    console.error('viewer: show elements failed', error, action.payload);
  }
}

function* ghost(action: any): SagaIterator {
  try {
    const viewersets: ViewerState = yield selectWrapper(state => state.viewersets);
    const element = ViewerUtils.getByPath(
      ['bimInfo', ...action.payload.identificator.split('.')],
      viewersets,
    );
    const identificator = action.payload.identificator;
    if (identificator.length > 0) {
      ViewerUtils.approveVisibilityToElement(element, 'ghost');
      yield put(ViewerActions.approveState());
      const pathes = [
        ...ViewerUtils.childIdentificators(identificator.split('.'), element),
        ...ViewerUtils.parentIdentificators(
          action.payload.identificator.split('.'),
          viewersets.bimInfo,
          'ghost',
        ),
      ];
      const np = [...ViewerUtils.getSimplePathes(identificator)];
      yield put(ViewerActions.showCommit(np));
      yield put(ViewerActions.ghostCommit(pathes));
    }
  } catch (error) {
    console.error('viewer: ghost failed', error, action.payload);
  }
}

function* changeMode(action: any): SagaIterator {
  try {
    const viewersets: ViewerState = yield selectWrapper(state => state.viewersets);
    const pathes = ViewerUtils.getIdps(action.payload.ids, viewersets.ids).slice();
    ViewerUtils.updateTreeRender(pathes, viewersets.bimInfo, action.payload.mode);
    yield put(ViewerActions.approveState());
  } catch (error) {
    console.error('viewer: change mode failed', error, action.payload);
  }
}

function* selectByIds(action: any): SagaIterator {
  try {
    const viewersets: ViewerState = yield selectWrapper(state => state.viewersets);
    const pathes = ViewerUtils.getIdps(action.payload, viewersets.ids);
    yield put(ViewerActions.selectByIdsCommit([...pathes], action.provider));
  } catch (error) {
    console.error('viewer: select by ids failed', error, action.payload);
  }
}

function* removeMessage(action: ActionWith<number>): SagaIterator {
  try {
    yield call(ViewerApi.removeComment, action.payload);
    yield put(ViewerActions.backToComments());
  } catch (error) {
    console.error('viewer: remove message failed', error, action.payload);
  }
}

function* resolve(action: any): SagaIterator {
  try {
    yield call(
      ViewerApi.updateStatus,
      action.payload.comment.id,
      action.payload.value ? CommentStatus.Resolved : CommentStatus.Open,
    );
  } catch (error) {
    console.error('viewer: resolve failed', error, action.payload);
  }
}

function* read(action: ActionWith<number>): SagaIterator {
  try {
    yield call(ViewerApi.readComment, action.payload);
  } catch (error) {
    console.error('viewer: read failed', error, action.payload);
  }
}

function* goToComment(action: any): SagaIterator {
  try {
    const [currentProjectId, location, comments]: [number, Location, CommentModel[]] = yield selectWrapper(state => {
      const projectId = state.projects.currentProject ? state.projects.currentProject.id : null;
      return [projectId, state.router.location, state.viewersets.comments];
    });

    if (currentProjectId !== action.payload.project || location.pathname.match('viewer') === null) {
      yield put(
        push(
          `${AppUrls.plan.project.viewer.url({ projectId: currentProjectId.toString() })}?comment=${
            action.payload.id
          }`,
        ),
      );
    } else {
      const comment = comments.find(x => x.id === action.payload.id);
      if (comment) {
        yield put(ViewerActions.readComment(comment.id));
        yield put(ViewerActions.goToComment(comment));
      }
    }
  } catch (error) {
    console.error('viewer: go to comment failed', error, action.payload);
  }
}

export function* viewerSagas(): SagaIterator {
  yield takeLatest(ActionsTypes.VIEWER_GET_COMMENTS, getComments);
  yield takeLatest(ActionsTypes.VIEWER_CREATE_COMMENT_REQUEST, createComment);
  yield takeLatest(ActionsTypes.VIEWER_ON_ADD_SUBCOMMENT, createSubcomment);
  yield takeLatest(ActionsTypes.VIEWER_GET_DOCUMENT_INFO, getBimInfo);
  yield takeLatest(ActionsTypes.VIEWER_HIDE_ELEMENTS, hideElement);
  yield takeLatest(ActionsTypes.VIEWER_SHOW_ELEMENTS, showElements);
  yield takeLatest(ActionsTypes.VIEWER_GHOST_ELEMENTS, ghost);
  yield takeLatest(ActionsTypes.VIEWER_CHANGE_RENDER_MODE, changeMode);
  yield takeLatest(ActionsTypes.VIEWER_SELECT_BY_ID, selectByIds);
  yield takeLatest(ActionsTypes.VIEWER_REMOVE_COMMENT, removeMessage);
  yield takeLatest(ActionsTypes.VIEWER_RESOLVE_COMMENT, resolve);
  yield takeLatest(ActionsTypes.VIEWER_READ_COMMENT, read);
  yield takeLatest(ActionsTypes.VIEWER_GO_TO_COMMENT_BY_ID, goToComment);
}
