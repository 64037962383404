import styled from 'styled-components';

const DropdownContainer = styled.div`
`;

const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`;


export const Styled = {
  DropdownContainer,
  InputsContainer,
};

