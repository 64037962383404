import autobind from 'autobind-decorator';
import GUI from 'lil-gui';
import * as paper from 'paper';

import {
  BucketAiStream,
  BucketAiStreamConfig,
  BucketInput,
} from 'common/components/drawings/api/bucket-ai/bucket-ai-stream';
import { StreamedRequestController, StreamProgress } from 'common/components/drawings/api/streams';
import { DrawingsScaleConstant } from 'common/components/drawings/constants/drawings-scale-constants';
import { ContextObserver, ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { WizzardStatus } from 'common/components/drawings/enums/dropper-state';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import { contoursArea } from 'common/components/drawings/helpers/geometry/magic-search';
import {
  AiRequestQuality,
  DrawingsRenderParams,
  DrawingsShortInfo,
  ShortPointDescription,
  WizzardToolsState,
} from 'common/components/drawings/interfaces';
import { PdfAiPolygonClickPayloadExtended } from 'common/components/drawings/interfaces/api-payloads';
import { PdfAiClickResponse } from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';
import { Vector2Utils } from 'common/components/drawings/utils/math-utils/vector2-utils';
import { State } from 'common/interfaces/state';
import { getOrCreateRoot } from 'common/UIKit';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { store } from '../../../../../../store';
import { DestroyableObject, EngineObjectConfig } from '../../common';
import { QualityDPI } from './bucket-ai-constants';
import { BucketAiMasks } from './bucket-ai-masks';

interface Config extends EngineObjectConfig {
  getCurrentDrawingInfo: () => DrawingsShortInfo;
  layer: paper.Layer | paper.Group;
  colorCache: DrawingsColorCacheHelper;
  renderParamsContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  wizzardSettingsObserver: ContextObserver<WizzardToolsState>;
}

const CONFIG = {
  DEBUG_CIRCLE: false,
  SNAP_DISTANCE: 35,
  SCALABLE_DISTANCE: true,
  ITERATIONS: 3,
  HOVER_DELAY: 400,
};


export class BucketAIHoverPreview extends DestroyableObject<Config> {
  private _actualPoint: paper.Point;
  private _previousSnapPoint: paper.Point;
  private _delayedExecutor: DeferredExecutor = new DeferredExecutor(CONFIG.HOVER_DELAY);
  private _streamController = new StreamedRequestController<BucketInput, PdfAiClickResponse, BucketAiStreamConfig>(
    BucketAiStream,
  );
  private _canceller: () => void;
  private _bucketAiMasks: BucketAiMasks;
  private _cachedMasks: ShortPointDescription[][][] = [];

  private _circle: paper.Path.Circle;
  private _gui: GUI;

  private _currentMode: AiRequestQuality;

  constructor(config: Config) {
    super(config);
    // this.initGui();
    config.wizzardSettingsObserver.subscribe(this.onUpdateWizzardSettings);
  }

  public destroy(): void {
    this.clean();
    if (this._gui) {
      getOrCreateRoot().removeChild(this._gui.domElement);
      this._gui = null;
    }
  }

  public clean(): void {
    this._bucketAiMasks?.destroy();
    this._delayedExecutor.reset();
    this.cancel();
    this._circle?.remove();
  }

  public async findMask(point: paper.Point): Promise<void> {
    this._actualPoint = point;
    if (!point || this._bucketAiMasks && !this._bucketAiMasks.isInsideMasks(point)) {
      this._bucketAiMasks?.destroy();
      this._bucketAiMasks = null;
    }
    if (!point) {
      this._previousSnapPoint = null;
      this._delayedExecutor.reset();
      return;
    }
    let shouldSendRequest = false;
    const p: ShortPointDescription = [point.x, point.y];
    const cachedResults = this._cachedMasks.filter(contours => contours.some(c => Vector2Utils.isPointInPolygon(p, c)));

    if (cachedResults.length) {
      const cachedResult = cachedResults.length > 1
        ? cachedResults.reduce<[ShortPointDescription[][], number]>((acc, curr, index) => {
          if (index === 0) {
            return acc;
          }
          const area = Math.abs(contoursArea(curr));
          return area < acc[1] ? [curr, area] : acc;
        }, [cachedResults[0], Math.abs(contoursArea(cachedResults[0]))])[0]
        : cachedResults[0];
      this._circle?.remove();
      this._previousSnapPoint = null;
      this._delayedExecutor.reset();
      this.cancel();
      this.showMasks(cachedResult);
    } else if (!this._previousSnapPoint) {
      shouldSendRequest = true;
    } else {
      const distance = this._previousSnapPoint.getDistance(point);
      const targetDistance = CONFIG.SNAP_DISTANCE / (
        CONFIG.SCALABLE_DISTANCE ? this._config.renderParamsContextObserver.getContext().zoom : 1
      );
      if (distance > targetDistance) {
        shouldSendRequest = true;
      }
    }

    if (shouldSendRequest) {
      await this.sendRequest(point);
    }
  }

  protected initGui(): void {
    this._gui = new GUI({ closeFolders: true });
    this._gui.domElement.style.position = 'absolute';
    getOrCreateRoot().appendChild(this._gui.domElement);
    this._gui.add(CONFIG, 'DEBUG_CIRCLE');
    this._gui.add(CONFIG, 'SNAP_DISTANCE', 5, 100);
    this._gui.add(CONFIG, 'SCALABLE_DISTANCE');
    this._gui.add(CONFIG, 'ITERATIONS', 1, 10, 1);
    this._gui.add(CONFIG, 'HOVER_DELAY', 10, 2000, 10).onChange(() => {
      this._delayedExecutor = new DeferredExecutor(CONFIG.HOVER_DELAY);
    });
  }

  private async request(point: paper.Point): Promise<void> {
    if (!point) {
      this._bucketAiMasks?.destroy();
      if (this._canceller) {
        this._canceller();
      }
      return Promise.resolve();
    }
    const drawingInfo = this._config.getCurrentDrawingInfo();
    const { quality = AiRequestQuality.Auto } = this._config.wizzardSettingsObserver.getContext();

    if (this._canceller) {
      this._canceller();
    }

    const res = await new Promise<PdfAiClickResponse>((resolve, reject) => {
      let cancelled = false;
      const state: State = store.getState();
      const project = state.projects.currentProject;
      const {
        originalCalibrationLineLength,
        drawingCalibrationLineLength,
        paperSize,
        width,
        height,
        pdfId,
        pageNumber,
        drawingId,
      } = drawingInfo;
      const input: PdfAiPolygonClickPayloadExtended = {
        dpi: QualityDPI[quality],
        autoDpi: quality === AiRequestQuality.Auto,
        scale: originalCalibrationLineLength / drawingCalibrationLineLength || 1,
        paperSize: paperSize || DrawingsScaleConstant.DEFAULT_FORMAT,
        type: 'polygon',
        userScreen: [0, 0, width, height],
        points: [[point.x, point.y]],
        pointsInfo: [{ positive: true }],
        text: '',
        fileId: pdfId,
        projectId: project.id,
        pageIdx: pageNumber,
        withPolygonizer: false,
        segmentationIterationLimit: CONFIG.ITERATIONS,
      };

      const onProgress = async ({ value, status }: StreamProgress<PdfAiClickResponse>): Promise<void> => {
        if (cancelled) {
          return;
        }
        if (status === WizzardStatus.Error) {
          reject(value);
        }
        if (status === WizzardStatus.Preview) {
          resolve(value);
        }
      };

      this._streamController.run({
        request: { input },
        onProgress,
        drawingId,
        fileId: pdfId,
        snap: false,
      });

      this._canceller = () => {
        cancelled = true;
        this._streamController.cancel();
        this._canceller = null;
      };
    }).finally(() => { this._canceller = null; return null;});
    const mask = res.output.contours.map(x => x.points);
    this._cachedMasks.push(mask);
    if (!this.showMasks(mask)) {
      await this.sendRequest(this._actualPoint);
    }
  }


  private showMasks(masks: ShortPointDescription[][]): boolean {
    if (this._bucketAiMasks) {
      this._bucketAiMasks.destroy();
    }
    if (!this._actualPoint) {
      return true;
    }
    const pointToCheck = [this._actualPoint.x, this._actualPoint.y] as ShortPointDescription;
    if (!masks.some((mask) => Vector2Utils.isPointInPolygon(pointToCheck, mask))) {
      return false;
    }
    this._bucketAiMasks = new BucketAiMasks({
      layer: this._config.layer,
      geometry: masks,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
    });

    return true;
  }

  private cancel(): void {
    if (this._canceller) {
      this._canceller();
      this._canceller = null;
    }
  }

  private async sendRequest(point: paper.Point): Promise<void> {
    this._previousSnapPoint = point;
    if (CONFIG.DEBUG_CIRCLE) {
      this._circle?.remove();
      const targetDistance = CONFIG.SNAP_DISTANCE / (
        CONFIG.SCALABLE_DISTANCE ? this._config.renderParamsContextObserver.getContext().zoom : 1
      );
      this._circle = new paper.Path.Circle(point, targetDistance);
      this._circle.strokeColor = new paper.Color('red');
      this._circle.strokeWidth = 1;
    }
    await new Promise((resolve) => this._delayedExecutor.execute(() => this.request(point).then(resolve)));
  }

  @autobind
  private onUpdateWizzardSettings(state: WizzardToolsState): void {
    if (state.quality !== this._currentMode) {
      this._currentMode = state.quality;
      this._cachedMasks = [];
      this._bucketAiMasks?.destroy();
    }
  }
}
