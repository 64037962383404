import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DrawingsGeometryGroup } from 'common/components/drawings';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';

const getRootSelectedGroup = (
  groups: DrawingsGeometryGroup[],
  selectedIds: Set<string>,
  group: DrawingsGeometryGroup,
): string => {
  if (!group || !selectedIds.has(group.id)) {
    return null;
  }

  if (!group.parentId) {
    return group.parentId;
  }

  if (!selectedIds.has(group.parentId)) {
    return group.parentId;
  }
  const parent = groups.find(g => g.id === group.parentId);
  return getRootSelectedGroup(groups, selectedIds, parent);
};

export const useParentId = (): string => {
  const selectedMeasureIds = useSelector<State, string[]>(s => s.drawings.selectedInstances);
  const selectedGroupIds = useSelector<State, string[]>(s => s.drawings.selectGeometryGroup);
  const groups = useSelector<State, DrawingsGeometryGroup[]>(s => s.drawings.drawingGeometryGroups);
  const aiAnnotation = useSelector<State, DrawingsGeometryState>(s => s.drawings.aiAnnotation);
  return useMemo(() => {
    const selectedGroupIdsSet = new Set(selectedGroupIds);
    if (!selectedMeasureIds.length) {
      const group = groups.find(g => g.id === selectedGroupIds[0]);
      return getRootSelectedGroup(groups, selectedGroupIdsSet, group);
    }
    const firstSelectedMeasure = aiAnnotation.geometry[selectedMeasureIds[0]];
    if (!selectedGroupIds.length) {
      return firstSelectedMeasure.groupId;
    }
    const firstGroup = groups.find(g => g.id === firstSelectedMeasure.groupId);

    return getRootSelectedGroup(groups, selectedGroupIdsSet, firstGroup);
  }, [aiAnnotation, selectedGroupIds, selectedMeasureIds, groups]);
};
