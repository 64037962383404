import { Utils } from '@kreo/kreo-ui-components';

import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { StrokeParams } from 'common/components/drawings/interfaces/stroke-params';
import { SegmentUtilLineEntity } from './segment-util-line-entity';

const ColorUtils = Utils.ColorUtils;

export class SegmentHoverLineEntity extends SegmentUtilLineEntity {
  protected override applyColor(color: paper.Color): void {
    this._line.strokeColor = ColorUtils.needReverseTextColorByBackground(color, true)
      ? DrawingsCanvasColors.darkBlue
      : DrawingsCanvasColors.white;
  }

  protected override getStrokeWidth({ strokeWidth }: StrokeParams, zoom: number): number {
    return (strokeWidth > 2 ? 1 : .5) / zoom;
  }

}
