import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { DemoProjectType, ProjectType } from 'common/constants/project-type';
import { isProjectStatusGreaterThan, ProjectStatus } from 'common/enums/project-status';
import { RequestStatus } from 'common/enums/request-status';
import { ActionWith } from 'common/interfaces/action-with';
import { BidPricingData } from 'common/interfaces/bid-pricing-data';
import { Feed } from 'common/interfaces/feed';
import { State } from 'common/interfaces/state';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { TwoDDatabaseActions } from 'unit-2d-database/store-slice';
import { ProjectAccessReason } from 'unit-projects/enums';
import { KnownViewModel } from 'unit-projects/enums/known-view-model';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { ScenariosApi } from '../../../api/scenario';
import { CREATE_PROJECT } from '../../../constants/forms';
import { AppUrls } from '../../../routes/app-urls';
import { ProjectAccessFilterUtils } from '../../2d-projects-page/2d-projects-content/project-access-filter/utils';
import { AccountActions } from '../../account/actions/creators';
import { Company } from '../../account/interfaces/company';
import { ScenariosActions } from '../../scenarios/actions';
import { ActivityAssignmentActions } from '../actions/creators/activity-assignment';
import { ClassificationActions } from '../actions/creators/classification';
import { ProjectsActions } from '../actions/creators/common';
import { MeasurementsActions } from '../actions/creators/measurements';
import {
  CompanyProjectHeadersFeedRequestPayload,
  DumpProjectPayload,
  DuplicateProjectPayload,
  MoveProjectToFolderPayload,
  ProjectSaveEngineFiltersState,
  UpdateProjectNamePayload,
  UpdateProjectStatusPayload,
} from '../actions/payloads/common';
import { ProjectsActionTypes } from '../actions/types/common';
import { BidPricingApi } from '../api/bid-pricing-api';
import { ProjectsApi } from '../api/common';
import { EngineBasedPages } from '../enums/engine-based-pages';
import { CompanyProjectHeader } from '../interfaces/company-project-header';
import { CreateDocumentModel } from '../interfaces/create-document-model';
import { Project } from '../interfaces/project';
import { ScenarioState } from '../interfaces/scenario-state';
import { ShortProjectHeader } from '../interfaces/short-project-header';
import { ProjectSelectors } from '../selectors';

const DEFAULT_ACCESS_REASONS = [
  ProjectAccessReason.Owner,
  ProjectAccessReason.Shared,
  ProjectAccessReason.CompanyShared,
];

function* fetchCompanyProjectHeaders(
  { payload }: ActionWith<CompanyProjectHeadersFeedRequestPayload>,
): SagaIterator {
  try {
    const { take, companyId, type, accessReason, isGuest } = payload;
    const filteredAccessReason = isGuest ?
      (accessReason || DEFAULT_ACCESS_REASONS).filter(access => access !== ProjectAccessReason.CompanyShared)
      : (accessReason || DEFAULT_ACCESS_REASONS);
    const feed: Feed<CompanyProjectHeader> = yield call(
      ProjectsApi.getCompanyProjectHeaders,
      { ...payload, accessReason: filteredAccessReason },
    );
    yield put(ProjectsActions.fetchCompanyProjectHeadersSucceeded(feed, companyId, take, type));
  } catch (error) {
    console.error('projects: fetch company project headers failed', error, payload);
    yield put(ProjectsActions.fetchCompanyProjectHeadersFailed(payload.companyId));
  }
}

function* fetchDemoProjects({ payload }: ActionWith<[DemoProjectType, boolean]>): SagaIterator {
  try {
    const [type, imperial] = payload;
    const demoProjects = yield call(ProjectsApi.getDemoProjects, type, imperial);
    yield put(ProjectsActions.fetchDemoProjectsSucceeded(type, demoProjects));
  } catch (error) {
    console.error('projects: fetch demo project failed', error, payload);
  }
}

function* removeProjectFromCurrentUserList(
  projectId: number,
  removeMethod: (projectId: number) => Promise<void>,
): SagaIterator {
  const selectedCompany: Company = yield selectWrapper(s => s.account.selectedCompany);
  if (!selectedCompany) {
    return;
  }

  yield call(removeMethod, projectId);
  yield put(ProjectsActions.removeProjectSucceeded(selectedCompany.id, projectId));
}

function* leaveProject({ payload: projectId }: ActionWith<number>): SagaIterator {
  try {
    yield call(removeProjectFromCurrentUserList, projectId, ProjectsApi.leaveProject);
  } catch (error) {
    console.error('projects: leave project failed', error, { projectId });
  }
}

function* deleteProject({ payload: projectId }: ActionWith<number>): SagaIterator {
  try {
    const currentProject: Project = yield selectWrapper(s => s.projects.currentProject);
    yield call(removeProjectFromCurrentUserList, projectId, ProjectsApi.deleteProject);
    if (currentProject?.id === projectId) {
      const url = currentProject.type === ProjectType.Project3d
        ? AppUrls.qto3d.listing.path
        : AppUrls.qto2d.listing.path;
      yield put(push(url));
    }
  } catch (error) {
    console.error('projects: delete project failed', error, { projectId });
  }
}

function* runClassification(): SagaIterator {
  try {
    yield call(ProjectsApi.runClassification);
  } catch (error) {
    console.error('projects: run classification failed', error);
  }
}

function* sendUpdateProjectStatusRequest(
  { payload }: ActionWith<UpdateProjectStatusPayload>,
): SagaIterator {
  try {
    const { projectId: projectId, status } = payload;
    yield call(ProjectsApi.updateProjectStatus, projectId, status);
    yield put(ProjectsActions.updateProjectStatusSucceeded(projectId, status));
  } catch (error) {
    console.error('projects: send update project status request failed', error, payload);
  }
}

function* createProject(): SagaIterator {
  try {
    const { formValues, fileNames, folderId } = yield select((st: State) => {
      return {
        companyId: st.account.selectedCompany.id,
        formValues: st.form[CREATE_PROJECT].values,
        fileNames: st.common.files.map(x => x.uploadedFileName),
        folderId: st.projects.currentFolder && st.projects.currentFolder.id,
      };
    });
    const model: CreateDocumentModel = {
      name: formValues.name,
      fileNames,
      invitations: formValues.invitations && formValues.invitations.length || formValues.isShareWithCompany
        ? {
          emails: formValues.invitations || [],
          isCompanyShared: formValues.isShareWithCompany,
        }
        : null,
    };
    const params = {
      type: formValues.type,
      templateId: formValues.templateId,
      folderId,
    };
    const projectHeader: CompanyProjectHeader = yield call(ProjectsApi.createProject, params, model);
    yield call(handleCreateProjectAnswer, projectHeader);
    yield put(ProjectsActions.createProjectSucceeded());
  } catch (error) {
    yield call(resetLoadedProject);
    yield put(ProjectsActions.createProjectSucceeded());
    console.error('projects: create project failed', error);
  }
}

function* createProjectFromDemoProject({ payload }: ActionWith<number>): SagaIterator {
  try {
    const { folderId } = yield select((st: State) => {
      return {
        companyId: st.account.selectedCompany.id,
        folderId: st.projects.currentFolder && st.projects.currentFolder.id,
      };
    });
    const params = { folderId };
    const projectHeader: CompanyProjectHeader = yield call(ProjectsApi.createProjectFromDemoProject, payload, params);
    yield call(handleCreateProjectAnswer, projectHeader);
    yield put(ProjectsActions.createProjectSucceeded());
  } catch (error) {
    yield put(ProjectsActions.createProjectSucceeded());
    console.error('projects: create project from demo project failed', error);
  }
}

function* openProject(projectHeader: CompanyProjectHeader): SagaIterator {
  if (ProjectType.Project2d === projectHeader.type) {
    const { selectedCompanyId, companies } = yield select((st: State) => {
      return {
        selectedCompanyId: st.account.selectedCompany.id,
        companies: st.account.companies,
      };
    });

    if (projectHeader.companyId !== selectedCompanyId) {
      const company = companies.find(c => c.id === projectHeader.companyId);
      yield put(AccountActions.selectCompany(company));
    }
    yield put(push(AppUrls.qto2d.project.index.url({ projectId: projectHeader.id.toString() })));
  }
}

function* patchCompanyFromDemoProject({ payload }: ActionWith<number>): SagaIterator {
  try {
    yield call(ProjectsApi.patchCompanyFromDemoProject, payload);
    yield put(TwoDDatabaseActions.fetchDatabaseRequest());
  } catch (error) {
    yield put(ProjectsActions.setApplyDemoProjectStatus(RequestStatus.Failed));
    console.error('projects: patch from demo project failed', error);
  }
}

function* duplicate2dProject({ payload }: ActionWith<DuplicateProjectPayload>): SagaIterator {
  try {
    const response: CompanyProjectHeader =
      yield call(ProjectsApi.duplicate2dProject, payload.companyId, payload.projectId);
    yield call(handleCreateProjectAnswer, response);
    yield put(ProjectsActions.duplicate2dProjectSucceeded());
  } catch (error) {
    yield call(resetLoadedProject);
    yield put(ProjectsActions.duplicate2dProjectSucceeded());
    console.error('projects: duplicate project failed', error);
  }
}

function* handleCreateProjectAnswer(response: CompanyProjectHeader): SagaIterator {
  const projectsFilters = yield selectWrapper(s => (s.persistedStorage.projectAccessFilter || {}));
  const filter = (projectsFilters || {})[response.type];
  if (!ProjectAccessFilterUtils.isOnlyInvited(filter)) {
    yield put(ProjectsActions.addProject(response));
  }
  const { viewModelStatuses, companyId, type, id } = response;
  const isCalculating = viewModelStatuses.some(v => v.viewModelType === KnownViewModel.ProjectCreating
    && v.status === ViewModelStatus.Calculating);
  if (isCalculating) {
    yield delay(1000);
  }
  const status = isCalculating
    ? yield call(getProjectStatus, companyId, type, id)
    : response.viewModelStatuses.find(v => v.viewModelType === KnownViewModel.ProjectCreating)?.status;
  if (isReady(status)) {
    yield call(openProject, response);
  } else if (ProjectType.Project2d === response.type) {
    yield put(push(AppUrls.qto2d.listing.path));
  }
}

function* getProjectStatus(companyId: number, type: string, projectId: number): SagaIterator {
  const project = yield selectWrapper((s) => {
    if (s.projects.projectHeadersByCompanyId[companyId]) {
      return s.projects.projectHeadersByCompanyId[companyId][type]?.data[projectId];
    } else {
      return null;
    }
  });
  return project?.viewModelStatuses.find(v => v.viewModelType === KnownViewModel.ProjectCreating)?.status;
}

function isReady(status: ViewModelStatus): boolean {
  return status === ViewModelStatus.Ready;
}

function* dump2dProject({ payload }: ActionWith<DumpProjectPayload>): SagaIterator {
  try {
    yield call(ProjectsApi.dump2dProject, payload.projectId, payload.quality);
  } catch (error) {
    console.error('projects: dump project failed', error);
  }
}

function* setCurrentProject(action: ActionWith<number>): SagaIterator {
  try {
    yield put(ProjectsActions.setIsProjectNotFound(false));
    yield put(ClassificationActions.dropState());
    yield put(ActivityAssignmentActions.dropState());
    yield put(MeasurementsActions.dropState());

    const projectId = action.payload;
    const project: Project = yield call(ProjectsApi.getProjectInfo, projectId);

    if (project.scenarios) {
      yield put(ScenariosActions.getScenariosSucceeded(project.scenarios));
    } else {
      yield put(ScenariosActions.getActiveScenarioByProject(project.id));
    }

    yield put(ProjectsActions.storeProjectInfo(project));
    if (isProjectStatusGreaterThan(project.status, ProjectStatus.OnBidPricing, true)) {
      yield put(ProjectsActions.getProjectBidPricingInfo(project.id));
    }

    const projectCompany: Company = yield selectWrapper(state => {
      const selectedCompany = state.account.selectedCompany;
      if (
        (!selectedCompany || selectedCompany.id !== project.companyId) &&
        Array.isArray(state.account.companies)
      ) {
        return state.account.companies.find(c => c.id === project.companyId);
      }

      return null;
    });

    if (projectCompany) {
      yield put(AccountActions.selectCompany(projectCompany));
    }

    // [permissions-todo]: handle that case better
    if (project.notFinishedScenarioId) {
      const urlParams = {
        projectId: project.id.toString(),
        scenarioId: project.notFinishedScenarioId.toString(),
      };
      if (
        project.notFinishedScenarioId === project.activeScenarioId &&
        !new RegExp(AppUrls.plan.project.scenario.index.url(urlParams)).test(window.location.href)
      ) {
        yield put(push(AppUrls.plan.project.scenario.workpackages.url(urlParams)));
      }
    }
  } catch (error) {
    yield put(ProjectsActions.setIsProjectNotFound(true));
    console.error('projects: set current project failed', error, action.payload);
  }
}

// todo: https://kreosoftware.atlassian.net/browse/KREOP-13390
function* getProjectBidPricingInfo(action: ActionWith<number>): SagaIterator {
  try {
    const data: BidPricingData = yield call(BidPricingApi.getProjectBidPricingDetails);
    yield put(ProjectsActions.getProjectBidPricingInfoSucceeded(data));
  } catch (error) {
    console.error('projects: get project bid pricing info failed', error, action.payload);
  }
}

function* updateProjectName({ payload: newName }: ActionWith<string>): SagaIterator {
  try {
    const project: Project = yield selectWrapper(s => s.projects.currentProject);
    if (!project) {
      return;
    }

    yield call(ProjectsApi.changeProjectName, project.id, newName);
    yield put(ProjectsActions.updateProjectNameSucceeded(project.id, newName));
  } catch (error) {
    console.error('projects: update project name failed', error, { newName });
  }
}

function* updateProjectNameById({ payload }: ActionWith<UpdateProjectNamePayload>): SagaIterator {
  try {
    yield call(ProjectsApi.changeProjectName, payload.id, payload.name);
    yield put(ProjectsActions.updateProjectNameSucceeded(payload.id, payload.name));
  } catch (error) {
    console.error('projects: update project name by id failed', error, { payload });
  }
}

function* moveProjectToFolder({ payload }: ActionWith<MoveProjectToFolderPayload>): SagaIterator {
  try {
    const { projectId, folderId, isNeedRemove } = payload;
    yield call(ProjectsApi.moveProjectToFolder, projectId, folderId);

    const selectedCompany: Company = yield selectWrapper(s => s.account.selectedCompany);
    if (!selectedCompany) {
      return;
    }

    const projectHeader: CompanyProjectHeader =
      yield selectWrapper(s => ProjectSelectors.findProjectHeader(s.projects, projectId));
    if (projectHeader && isNeedRemove) {
      yield put(ProjectsActions.removeProjectSucceeded(selectedCompany.id, projectId));
    }
  } catch (error) {
    console.error('projects: move project to folder failed', error, payload);
  }
}

function* fetchScenarioStates({ payload: projectId }: ActionWith<number>): SagaIterator {
  try {
    const scenarioStates: ScenarioState[] = yield call(ScenariosApi.fetchScenarioStates);
    yield put(ProjectsActions.fetchScenarioStatesSucceeded(scenarioStates));
  } catch (error) {
    yield put(ScenariosActions.copyScenarioFailed(projectId));
    console.error('projects: fetch scenario states failed', error, { projectId });
  }
}

function* setEngineFilterState({ payload }: ActionWith<ProjectSaveEngineFiltersState>): SagaIterator {
  try {
    yield call(ProjectsApi.updateEngineFilterState, payload.projectId, payload.state, payload.engineBasedPage);
  } catch (error) {
    console.error('projects: set engine filter state failed', error, payload);
  }
}

function* loadEngineFilterState({ payload }: ActionWith<EngineBasedPages>): SagaIterator {
  try {
    const projectId = yield selectWrapper(x => x.projects.currentProject.id);
    const engineState = yield call(ProjectsApi.getEngineFilterState, projectId, payload);
    yield put(ProjectsActions.getEngineFilterStateSucceeded(
      projectId,
      engineState || { visibleIds: [], clipBox: { isActive: false } },
      payload,
    ),
    );
  } catch (error) {
    console.error('load projects: get engien filter state', error, payload);
  }
}

function* failureProjectIdSend({ payload: projectId }: ActionWith<number>): SagaIterator {
  try {
    const project = yield call(ProjectsApi.failureProjectIdSend, projectId);
    yield put(ProjectsActions.failureProjectIdSendSucceeded(project));
  } catch (error) {
    console.error('failure projects id send failed', error, { projectId });
  }
}

function* fetchAllTemplates(): SagaIterator {
  try {
    const projectHeaders: ShortProjectHeader[] =
      yield call(ProjectsApi.getAllProjectHeaders, ProjectType.Project2dTemplate);
    yield put(ProjectsActions.fetchAllTemplatesSucceeded(projectHeaders));
  } catch (error) {
    console.error('projects: fetch all templates failed', error);
  }
}

function* createTemplateFromProject({ payload }: ActionWith<number>): SagaIterator {
  try {
    const response = yield call(ProjectsApi.createTemplateFromProject, payload);
    yield call(handleCreateProjectAnswer, response);
  } catch (error) {
    yield put(ProjectsActions.createProjectSucceeded());
    console.error('projects: create template from project failed', error);
  }
}

function* resetLoadedProject(): SagaIterator {
  try {
    yield put(ProjectsActions.dropStore());
  } catch (error) {
    console.error('projects: reset loaded projects failed');
  }
}

export function* projectsSagas(): SagaIterator {
  yield takeEvery(ProjectsActionTypes.REMOVE_PROJECT, deleteProject);
  yield takeEvery(ProjectsActionTypes.LEAVE_PROJECT, leaveProject);
  yield takeLatest(ProjectsActionTypes.RUN_CLASSIFICATION, runClassification);
  yield takeEvery(
    ProjectsActionTypes.UPDATE_PROJECT_STATUS_REQUEST,
    sendUpdateProjectStatusRequest,
  );
  yield takeLatest(ProjectsActionTypes.CREATE_PROJECT, createProject);
  yield takeLatest(ProjectsActionTypes.CREATE_PROJECT_FROM_DEMO_PROJECT, createProjectFromDemoProject);
  yield takeLatest(ProjectsActionTypes.PATCH_COMPANY_FROM_DEMO_PROJECT, patchCompanyFromDemoProject);
  yield takeLatest(ProjectsActionTypes.DUPLICATE_2D_PROJECT, duplicate2dProject);
  yield takeLatest(ProjectsActionTypes.DUMP_2D_PROJECT, dump2dProject);
  yield takeLatest(ProjectsActionTypes.LOAD_CURRENT_PROJECT, setCurrentProject);
  yield takeEvery(ProjectsActionTypes.GET_BID_PRICING_INFO, getProjectBidPricingInfo);
  yield takeLatest(ProjectsActionTypes.UPDATE_PROJECT_NAME_BY_ID_REQUEST, updateProjectNameById);
  yield takeLatest(ProjectsActionTypes.UPDATE_PROJECT_NAME_REQUEST, updateProjectName);
  yield takeLatest(ProjectsActionTypes.MOVE_PROJECT_TO_FOLDER, moveProjectToFolder);
  yield takeLatest(ProjectsActionTypes.FETCH_SCENARIO_STATES, fetchScenarioStates);
  yield takeLatest(ProjectsActionTypes.FETCH_COMPANY_PROJECT_HEADERS_REQUEST, fetchCompanyProjectHeaders);
  yield takeEvery(ProjectsActionTypes.FETCH_DEMO_PROJECTS_REQUEST, fetchDemoProjects);
  yield takeLatest(ProjectsActionTypes.SET_ENGINE_FILTER_STATE, setEngineFilterState);
  yield takeEvery(ProjectsActionTypes.FAILURE_PROJECT_SEND, failureProjectIdSend);
  yield takeLatest(ProjectsActionTypes.GET_ENGINE_FILTER_STATE, loadEngineFilterState);
  yield takeLatest(ProjectsActionTypes.FETCH_ALL_TEMPLATES, fetchAllTemplates);
  yield takeLatest(ProjectsActionTypes.CREATE_TEMPLATE_FROM_PROJECT, createTemplateFromProject);
}
