import * as React from 'react';

import { SubCommentModel } from '../../interfaces';
import { CommentText } from './comment-text';

interface SubCommentProps {
  subComment: SubCommentModel;
  imageUri: string;
  state: any;
  toEngineState: (state: any) => void;
  timeStamp: Date | undefined;
}

export const SubComment: React.FC<SubCommentProps> = (props) => {
  const toEngineState = (): void => {
    if (props.subComment.engineState) {
      props.toEngineState(JSON.parse(props.subComment.engineState));
    }
  };

  return (
    <CommentText
      onScreenClick={toEngineState} // eslint-disable-line react/jsx-no-bind
      text={props.subComment.text}
      imageUri={props.imageUri}
      created={props.timeStamp}
    />);
};
