import classNames from 'classnames';
import React from 'react';

import './notification-status-icon.scss';

import { NotificationIcon } from 'common/enums/notification-icon';
import { NotificationRelatedArea } from 'common/enums/notification-related-area';
import { StringDictionary } from 'common/interfaces/dictionary';
import { KreoIconRowExpanded } from 'common/UIKit';
import { notificationIcons } from 'common/UIKit/icons/notifications';

const relatedAreaClass: StringDictionary<string> = {
  [NotificationRelatedArea.Management]: 'management',
  [NotificationRelatedArea.Scenarios]: 'scenarios',
  [NotificationRelatedArea.Database]: 'database',
  [NotificationRelatedArea.Comment]: 'comment',
  [NotificationRelatedArea.Validation]: 'validation',
  [NotificationRelatedArea.BidPricing]: 'bid-pricing',
};

interface Props {
  icon: NotificationIcon;
  relatedArea: NotificationRelatedArea;
  onClick?: () => void;
  isExpandable?: boolean;
  expanded?: boolean;
  collapseText?: React.ReactText;
  controlName?: string;
}

export const NotificationStatusIcon: React.FC<Props> = (props: Props): JSX.Element => {
  let icon = null;
  switch (props.icon) {
    case NotificationIcon.UserAdd:
      icon = <notificationIcons.IconUserInvite />;
      break;
    case NotificationIcon.UserDelete:
      icon = <notificationIcons.IconUserDelete />;
      break;
    case NotificationIcon.UserSettings:
      icon = <notificationIcons.IconUserSettings />;
      break;
    case NotificationIcon.Settings:
      icon = <notificationIcons.IconSettings />;
      break;

    case NotificationIcon.Scenario:
      icon = <notificationIcons.IconScenario />;
      break;
    case NotificationIcon.WorkPackage:
      icon = <notificationIcons.IconWorkPackage />;
      break;
    case NotificationIcon.Sequence:
      icon = <notificationIcons.IconSequence />;
      break;
    case NotificationIcon.Grouping:
      icon = <notificationIcons.IconGrouping />;
      break;
    case NotificationIcon.Resources:
      icon = <notificationIcons.IconResources />;
      break;
    case NotificationIcon.Cost:
      icon = <notificationIcons.IconCost />;
      break;

    case NotificationIcon.Database:
      icon = <notificationIcons.IconDatabase />;
      break;

    case NotificationIcon.Comment:
      icon = <notificationIcons.IconComment />;
      break;

    case NotificationIcon.Validation:
      icon = <notificationIcons.IconValidation />;
      break;

    case NotificationIcon.BidPricing:
      icon = <notificationIcons.IconBidPricing />;
      break;
    default:
  }

  return (
    <div
      className={classNames('notification-status-icon', relatedAreaClass[props.relatedArea], {
        expandable: props.isExpandable,
      })}
      onClick={props.onClick}
      data-control-name={props.controlName}
    >
      <div className='notification-status-icon__content'>
        {props.isExpandable ? props.expanded ? <KreoIconRowExpanded /> : props.collapseText : icon}
      </div>
    </div>
  );
};
