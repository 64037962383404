import autobind from 'autobind-decorator';
import * as React from 'react';

import { mathUtils } from 'common/utils/math-utils';


function getDeltaAngle(startAngle: number, endAngle: number): number {
  const sign = mathUtils.sign(endAngle - startAngle);
  const deltaAngle = Math.min(Math.abs(endAngle - startAngle), 360);

  return sign * (deltaAngle === 360 ? 0 : deltaAngle);
}

export const RADIAN = Math.PI / 180;

function polarToCartesian(center: number, radius: number, angle: number): [number, number] {
  return [center + Math.cos(-RADIAN * angle) * radius, center + Math.sin(-RADIAN * angle) * radius];
}

function getSectorPath(
  center: number,
  innerRadius: number,
  outerRadius: number,
  startAngle: number,
  endAngle: number,
): string {
  const angle = getDeltaAngle(startAngle, endAngle);

  const tempEndAngle = startAngle + angle;
  const outerStartPoint = polarToCartesian(center, outerRadius, startAngle);
  const outerEndPoint = polarToCartesian(center, outerRadius, tempEndAngle);

  let path = `M ${outerStartPoint[0]},${outerStartPoint[1]}
    A ${outerRadius},${outerRadius},0,
    ${Math.abs(angle) > 180 ? 1 : 0},${startAngle > tempEndAngle ? 1 : 0},
    ${outerEndPoint[0]},${outerEndPoint[1]}
  `;

  if (innerRadius > 0) {
    const innerStartPoint = polarToCartesian(center, innerRadius, startAngle);
    const innerEndPoint = polarToCartesian(center, innerRadius, tempEndAngle);
    path += `L ${innerEndPoint[0]},${innerEndPoint[1]}
            A ${innerRadius},${innerRadius},0,
            ${+(Math.abs(angle) > 180)},${+(startAngle <= tempEndAngle)},
            ${innerStartPoint[0]},${innerStartPoint[1]} Z`;
  } else {
    path += `L ${center},${center} Z`;
  }

  return path;
}

interface Props {
  color: string;
  center: number;
  width: number;
  angle: number;
  finishAngle: number;
  layer: number;
  strokeWidth: number;
  selected: boolean;
  onSelect: (color: string) => void;
}

export class Color extends React.PureComponent<Props> {


  public render(): React.ReactNode {
    const { center, color, layer, width, angle, finishAngle,  selected, strokeWidth } = this.props;
    const innerRadius =  center - width * (layer + 1) + strokeWidth / 2;
    const outerRadius = center - width * layer - strokeWidth / 2;
    return (
      <path
        fill={color}
        stroke={selected ? 'white' : color}
        strokeWidth={strokeWidth}
        onClick={this.onClick}
        d={getSectorPath(center, innerRadius, outerRadius, angle, finishAngle)}
      />
    );
  }

  @autobind
  private onClick(): void {
    this.props.onSelect(this.props.color);
  }
}
