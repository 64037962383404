import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  padding: 20px 30px 25px;
  background: ${p => p.theme.color.backgroundRush};
  border-radius: 40px;
`;

const Title = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  height: 20px;
`;

const Slider = styled.div`
  width: 100%;
  margin-top: 9px;
  > div {
    width: 100%;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 30px;
`;

export const Styled = {
  Container,
  Slider,
  Action,
  Title,
};
