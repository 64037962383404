import { ActivityGroupRestData } from '../../units/scenarios';
import { ProjectResourcesApi } from '../server/project-resources-api';

function scenariosUrl(
  scenarioId: number,
  url: string = '',
): string {
  return `/scenarios/${scenarioId}${url}`;
}

function getActivityGroups(scenarioId: number): Promise<ActivityGroupRestData.ActivityGroupingResponse> {
  return ProjectResourcesApi.get(
    scenariosUrl(scenarioId, '/activity-grouping'),
    'Error in getting Activity Grouping',
  );
}

function updateActivityGroups(
  scenarioId: number,
  changes: ActivityGroupRestData.ActivityGroupingChangesRequest,
): Promise<void> {
  return ProjectResourcesApi.patch(
    scenariosUrl(scenarioId, '/activity-grouping'),
    changes,
    'Error in updating Activity Groups',
  );
}

function getMacroSequence(scenarioId: number): Promise<ActivityGroupRestData.MacroSequenceResponse> {
  return ProjectResourcesApi.get(
    scenariosUrl(scenarioId, '/macro-sequence'),
    'Error in getting Macro Sequence data.',
  );
}

function updateMacroSequence(
  scenarioId: number,
  changes: ActivityGroupRestData.MacroSequenceChangesRequest,
): Promise<ActivityGroupRestData.MacroSequenceResponse> {
  return ProjectResourcesApi.patch(
    scenariosUrl(scenarioId, '/macro-sequence'),
    changes,
    'Error in updating Macro Sequence data.',
  );
}

export const ActivityGroupingApi = {
  getActivityGroups,
  updateActivityGroups,
  getMacroSequence,
  updateMacroSequence,
};
