import * as React from 'react';
import { KreoColors } from 'common/enums/kreo-colors';

export const KreoIconKeyW: React.FC = () => {
  return (
    <svg
      width='20px'
      height='22px'
      viewBox='0 0 20 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='hotkey_w' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='W'>
          <g id='background'>
            <g id='Group' transform='translate(0.000000, 2.000000)'>
              <g id='path-1-link' fill={KreoColors.f1}>
                <rect id='path-1' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
            <g id='Group'>
              <g id='path-2-link' fill={KreoColors.f1}>
                <rect id='path-2' x='0' y='0' width='20' height='20' rx='2' />
              </g>
              <rect
                id='Rectangle-path'
                stroke={KreoColors.f2}
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                rx='2'
              />
            </g>
          </g>
          <rect id='padding-left' x='0' y='6' width='6' height='10' />
          <rect id='padding-right' x='14' y='6' width='6' height='10' />
          <path
            // eslint-disable-next-line max-len
            d='M10.4385007,6.12949871 C10.6145008,6.12949871 10.7410008,6.17349872 10.8455008,6.27249873 C10.9390008,6.36599875 10.9940008,6.47599877 11.0490008,6.6684988 L12.4790011,11.7889996 L13.7330013,6.75099881 C13.7990013,6.48699877 13.8320013,6.38799875 13.9310013,6.27799873 C14.0190013,6.18449872 14.1510013,6.12399871 14.3105014,6.12399871 C14.5635014,6.12399871 14.8055014,6.28899874 14.8605015,6.50899877 C14.8935015,6.64099879 14.8770015,6.75649881 14.8110014,7.02049886 L13.2270012,13.3784999 C13.1720012,13.5929999 13.0950012,13.7745 12.9520011,13.8955 C12.8310011,13.9945 12.6880011,14.044 12.5285011,14.044 C12.369001,14.044 12.226001,13.9945 12.105001,13.8955 C11.962001,13.7745 11.890501,13.5929999 11.830001,13.3784999 L10.4385007,8.41749908 L9.0470005,13.3784999 C8.98650049,13.5929999 8.91500048,13.7745 8.77200045,13.8955 C8.65100043,13.9945 8.50800041,14.044 8.34850038,14.044 C8.18900036,14.044 8.04600034,13.9945 7.92500032,13.8955 C7.78200029,13.7745 7.70500028,13.5929999 7.65000027,13.3784999 L6.06600001,7.02049886 C6,6.75649881 5.9835,6.64099879 6.0165,6.50899877 C6.07150001,6.28899874 6.31350005,6.12399871 6.56650009,6.12399871 C6.72600012,6.12399871 6.85800014,6.18449872 6.94600016,6.27799873 C7.04500017,6.38799875 7.07800018,6.48699877 7.14400019,6.75099881 L8.39800039,11.7889996 L9.82800063,6.6684988 C9.88300064,6.47599877 9.93800065,6.36599875 10.0315007,6.27249873 C10.1360007,6.17349872 10.2625007,6.12949871 10.4385007,6.12949871 Z'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </svg>
  );
};
