import React, { useCallback } from 'react';

import { ConstantFunctions } from 'common/constants/functions';
import { DrawingsBounds } from '../../interfaces';
import { PdfGeometry } from '../../interfaces/api-responses/pdf-geometry-response';
import { PreviewItem } from '../search-preview';
import { useItemBounds } from './use-item-bounds';
import { useVisible } from './use-visible';


interface Props {
  item: PdfGeometry;
  zoom: number;
  browserScale: number;
  context: CanvasRenderingContext2D;
  focus: (bounds: DrawingsBounds) => void;
}

const WizzardPreviewItemComponent: React.FC<Props> = (
  {
    item,
    zoom,
    browserScale,
    context,
    focus,
  },
) => {
  const bounds = useItemBounds(item);

  const onFocus = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    ConstantFunctions.stopEvent(e);
    focus(bounds);
  }, [bounds]);

  const [ visible, toggleVisibility ] = useVisible(item.id);

  return (
    <PreviewItem
      onClick={toggleVisibility}
      similarity={item.similarity}
      centerX={bounds.center.x}
      centerY={bounds.center.y}
      zoom={zoom}
      browserScale={browserScale}
      context={context}
      showSimilarity={true}
      visible={visible}
      onFocus={onFocus}
    />
  );
};


export const WizzardPreviewItem = React.memo(WizzardPreviewItemComponent);
