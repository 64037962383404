import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { DrawingsGeometryInstance, ShortPointDescription } from '../../interfaces';

export function useInstances(): Record<string, DrawingsGeometryInstance> {
  return useSelector<State, Record<string, DrawingsGeometryInstance>>(s => s.drawings.aiAnnotation.geometry);
}

export function usePoints(): Record<string, ShortPointDescription> {
  return useSelector<State, Record<string, ShortPointDescription>>(s => s.drawings.aiAnnotation.points);
}
