import React from 'react';

import './database-activity-variant-select-inner.scss';

import { ElementTooltip, TooltipPosition } from 'common/components/element-tooltip';
import { ExtractorFunctionModelDropDown } from '../../interfaces/data';

interface Props {
  option: ExtractorFunctionModelDropDown;
  name: string;
}

export class DatabaseActivityVariantSelectInner extends React.Component<Props> {

  public render(): JSX.Element {
    const { option, name } = this.props;
    return (
      <div className='database-activity-variant-select-inner'>
        <span className='database-activity-variant-select-inner__name' dangerouslySetInnerHTML={{ __html: name }} />
        {option.defaultUnit ? (
          <span className='database-activity-variant-select-inner__unit'>{option.defaultUnit}</span>
        ) : null}
        {option.description ? (
          <ElementTooltip
            size='medium'
            className='database-activity-variant-select-inner__info'
            tooltipPosition={TooltipPosition.LeftTop}
          >
            <div className='database-activity-variant-select-inner__info-tooltip'>
              {option.withImage && <div className='database-activity-variant-select-inner__info-tooltip-img'>
                <img src={`/static/extractor-functions/${option.value}.png`} alt={`${option.value}`} />
              </div>
              }
              <div className='database-activity-variant-select-inner__info-tooltip-description'>
                {option.description}
              </div>
            </div>
          </ElementTooltip>
        ) : null}
      </div>
    );
  }
}
