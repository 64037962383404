import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import { DrawingsGeometryGroup, DrawingsGeometryGroupWithNesting, UpdateGroupInDrawingsPayload } from '..';
import {
  CreateDrawingGroupsTree,
  MoveDrawingGroupTree,
} from '../actions/payloads/drawings-annotation-legend';
import { DrawingsState } from '../interfaces/drawings-state';
import { DrawingAnnotationFiltrationUtils } from './drawing-annotation-filtration-utils';


function getNextOrderIndex(drawingGeometryGroups: DrawingsGeometryGroup[], parentGroupId: string): number {
  const maxOrderIndex = DrawingAnnotationFiltrationUtils.getMaxOrderIndex(
    drawingGeometryGroups as DrawingsGeometryGroupWithNesting[],
    parentGroupId,
  );
  return maxOrderIndex + 1;
}

function addInstancesToGroup(
  helper: MonoliteHelper<DrawingsState>,
  targetGroupId: string,
  measurementIds: string[],
): MonoliteHelper<DrawingsState> {
  if (targetGroupId) {
    helper.setFind(
      _ => _.drawingGeometryGroups,
      group => group.id === targetGroupId,
      group => ({ ...group, measurements: arrayUtils.uniq([...group.measurements, ...measurementIds]) }),
    );
  }
  return helper;
}

function removeInstancesFromGroups(
  helper: MonoliteHelper<DrawingsState>,
  payload: string[],
): MonoliteHelper<DrawingsState> {
  const removeIdsSet = new Set(payload);
  helper.setMap(
    _ => _.drawingGeometryGroups,
    group => ({
      ...group,
      measurements: group.measurements.filter(x => !removeIdsSet.has(x)),
    }),
  );
  return helper;
}

function addGroups(
  helper: MonoliteHelper<DrawingsState>,
  payload: CreateDrawingGroupsTree,
): MonoliteHelper<DrawingsState> {
  const state = helper.get();
  const colors = new Set(state.usedColors);
  const orderIndices: Record<string, number> = {};
  const newGroups = new Array<DrawingsGeometryGroup>();
  const drawingsGeometryOpenGroupsHelper = new MonoliteHelper(state.drawingGeometryOpenGroups);
  for (const group of payload.groups) {
    orderIndices[group.parentGroupId] = (orderIndices[group.parentGroupId]
    || getNextOrderIndex(state.drawingGeometryGroups, group.parentGroupId)) + 1;
    newGroups.push({
      id: group.id,
      name: group.name,
      measurements: [],
      orderIndex: orderIndices[group.parentGroupId],
      innerGroups: [],
      parentId: group.parentGroupId,
      color: group.color,
    });
    if (group.color) {
      colors.add(group.color);
    }
    drawingsGeometryOpenGroupsHelper.set(_ => _[group.id], true);
  }
  helper.setConcat(_ => _.drawingGeometryGroups, newGroups);
  helper.set(_ => _.drawingGeometryOpenGroups, drawingsGeometryOpenGroupsHelper.get());
  helper.set(_ => _.usedColors, Array.from(colors));
  return helper;
}

function processInstancesMovements(
  helper: MonoliteHelper<DrawingsState>,
  payload: MoveDrawingGroupTree,
): MonoliteHelper<DrawingsState> {
  const { groups, measurements, parentGroupId, orderIndex } = payload;
  const state = helper.get();
  const startOrderIndex = orderIndex || getNextOrderIndex(state.drawingGeometryGroups, parentGroupId);
  const groupIdsSet = new Set(groups);

  if (groups.length) {
    const affectedGroups = [];
    helper.setMap(
      _ => _.drawingGeometryGroups,
      group => {
        if (groupIdsSet.has(group.id)) {
          affectedGroups.push(group.parentId);
          return { ...group, parentId: parentGroupId, orderIndex: startOrderIndex + groups.indexOf(group.id) };
        }
        if (group.parentId === parentGroupId) {
          if (group.orderIndex >= orderIndex) {
            return { ...group, orderIndex: group.orderIndex + groups.length };
          }
        }
        return group;
      });

    arrayUtils
      .uniq(affectedGroups)
      .forEach(parentId => {
        recalcOrderIndex(helper, parentId);
      });
  }

  if (measurements.length) {
    removeInstancesFromGroups(helper, measurements);
    addInstancesToGroup(helper, parentGroupId, measurements);
  }
  return helper;
}

function recalcOrderIndex(
  helper: MonoliteHelper<DrawingsState>,
  groupId: string,
): MonoliteHelper<DrawingsState> {
  const state = helper.get();
  const groupChildren = state.drawingGeometryGroups.filter(group => group.parentId === groupId);
  const sortedChildren = arrayUtils.sortByField(groupChildren, 0, groupChildren.length - 1, 'orderIndex');
  sortedChildren.forEach((childGroup, index) => {
    if (childGroup.orderIndex !== index + 1) {
      helper.setFind(
        _ => _.drawingGeometryGroups,
        group => group.id === childGroup.id,
        group => ({ ...group, orderIndex: index + 1 }),
      );
    }
  });
  return helper;
}

function deleteGroups(
  helper: MonoliteHelper<DrawingsState>,
  payload: string[],
): MonoliteHelper<DrawingsState> {
  const idsToRemove = new Set(payload);
  const affectedGroups = [];

  helper
    .setFilter(_ => _.selectGeometryGroup, id => !idsToRemove.has(id))
    .setFilter(_ => _.pinnedGroupIds, id => !idsToRemove.has(id))
    .setFilter(
      _ => _.drawingGeometryGroups,
      group => {
        affectedGroups.push(group.parentId);
        return !idsToRemove.has(group.id);
      })
    .setFilter(_ => _.filteredItems, item => !idsToRemove.has(item.id));

  arrayUtils
    .uniq(affectedGroups)
    .forEach(parentId => {
      recalcOrderIndex(helper, parentId);
    });
  return helper;
}

function moveMeasurements(
  helper: MonoliteHelper<DrawingsState>,
  payload: UpdateGroupInDrawingsPayload[],
): MonoliteHelper<DrawingsState> {
  for (const { parentGroupId, id } of payload) {
    helper.set(_ => _.aiAnnotation.geometry[id].groupId, parentGroupId);
  }
  return helper;
}

export const DrawingAnnotationReducerUtils = {
  addInstancesToGroup,
  removeInstancesFromGroups,
  addGroups,
  processInstancesMovements,
  deleteGroups,
  moveMeasurements,
};
