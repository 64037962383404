
export enum InfoWindowPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface HoverState {
  hoverInstanceId: string;
  position: InfoWindowPosition;
}
