import { Action } from 'redux';

import { ActionWith } from 'common/interfaces/action-with';
import { UploadingFile } from 'common/interfaces/common-state';
import { ModelManagementFileData } from '../../interfaces/model-management/model-management-data';
import { ModelManagementActionTypes } from '../types/model-management';

function loadData(id: number): ActionWith<number> {
  return {
    type: ModelManagementActionTypes.LOAD_DATA,
    payload: id,
  };
}

function loadDataSuccess(
  models: ModelManagementFileData[],
): ActionWith<ModelManagementFileData[]> {
  return {
    type: ModelManagementActionTypes.LOAD_DATA_SUCCESS,
    payload: models,
  };
}

function deleteFile(id: number | string): ActionWith<number | string> {
  return {
    type: ModelManagementActionTypes.DELETE_FILE,
    payload: id,
  };
}

function addFiles(files: UploadingFile[]): ActionWith<UploadingFile[]> {
  return {
    type: ModelManagementActionTypes.ADD_FILES,
    payload: files,
  };
}

function apply(): Action {
  return {
    type: ModelManagementActionTypes.APPLY_CHANGES,
  };
}

function applyFailed(): Action {
  return {
    type: ModelManagementActionTypes.APPLY_CHANGES_FAILED,
  };
}

function dropPageState(): Action {
  return {
    type: ModelManagementActionTypes.DROP_PAGE_STATE,
  };
}

function selectFromEngine(ids: number[]): ActionWith<number[]> {
  return {
    type: ModelManagementActionTypes.SELECT_FROM_ENGINE,
    payload: ids,
  };
}

function selectFile(id: number): ActionWith<number> {
  return {
    type: ModelManagementActionTypes.SELECT_FILE,
    payload: id,
  };
}

export const ModelManagementActions = {
  loadData,
  loadDataSuccess,
  deleteFile,
  addFiles,
  apply,
  applyFailed,
  dropPageState,
  selectFromEngine,
  selectFile,
};
