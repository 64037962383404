import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { DrawingsActions } from 'common/components/drawings';
import { useCanEditMeasurements } from 'common/components/drawings/hooks';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';
import { useUndoRedoFullApi } from 'common/undo-redo';
import { DeferredExecutor } from 'common/utils/deferred-executer';

export function useChangeRotation(): [number, (value: number) => void, boolean] {
  const rotationExecutor = useMemo(() => new DeferredExecutor(100), []);
  const currentDrawing = useSelector<State, DrawingsShortInfo>(s => s.drawings.currentDrawingInfo);
  const { addUndoRedo } = useUndoRedoFullApi();

  const dispatch = useDispatch();

  const changeRotation = useCallback((newAngle: number) => {
    if (!currentDrawing) {
      return;
    }
    const changeInState =
      (value: number): Action => dispatch(
        DrawingsActions.pageMetaParameterUpdate(currentDrawing.drawingId, 'rotationAngle', value),
      );

    const oldAngle = currentDrawing.rotationAngle;
    rotationExecutor.execute(() => {
      addUndoRedo(
        () => rotationExecutor.execute(() => changeInState(oldAngle)),
        () => rotationExecutor.execute(() => changeInState(newAngle)),
      );
      changeInState(newAngle);
    });
  }, [addUndoRedo, currentDrawing, useDispatch]);

  const isRotationAccessible = useCanEditMeasurements() && !!currentDrawing;

  return [
    currentDrawing?.rotationAngle,
    changeRotation,
    isRotationAccessible,
  ];
}
