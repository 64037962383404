import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { TwoDActions } from '2d/actions/creators';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { PersistedStorageActions } from 'persisted-storage/actions/creators';
import { Assembly, Item, Property, VisibilityFilterType } from 'unit-2d-database/interfaces';
import { TwoDDatabaseActions } from 'unit-2d-database/store-slice';
import { BREAKDOWN_DIALOG_NAME } from '../breakdown-dialog';
import { FormulaDialogData, FORMULA_DIALOG_NAME } from '../formula-dialog';
import { MeasurePanelType } from './interfaces';

interface StateToProps {
  assembliesDatabase: Assembly[];
  itemsDatabase: Item[];
  propertiesDatabase: Property[];
  visibilityMode: VisibilityFilterType;
  collapsedGroups: Set<string>;
}

interface DispatchToProps {
  openBreakdownDialog: (data: any) => void;
  openFormulaDialog: (data: FormulaDialogData) => void;
  setCollapsedGroups: (ids: Set<string>) => void;
  openSidePanel: (type: MeasurePanelType) => void;
  setAssemblyForm: (assembly: Assembly) => void;
  setItemForm: (item: Item) => void;
  closePanel: () => void;
  setAssignPiaLoad: () => void;
}

export type ReduxProps = StateToProps & DispatchToProps;

const mapStateToProps = (state: State): StateToProps => {
  const { visibilityMode, collapsedGroups } = state.twoDDatabase.sidePanel;

  return {
    assembliesDatabase: state.twoDDatabase.assemblies,
    itemsDatabase: state.twoDDatabase.items,
    propertiesDatabase: state.twoDDatabase.properties,
    visibilityMode,
    collapsedGroups,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchToProps => {
  return {
    openBreakdownDialog: (data) => dispatch(KreoDialogActions.openDialog(BREAKDOWN_DIALOG_NAME, data)),
    openFormulaDialog: (data) => dispatch(KreoDialogActions.openDialog(FORMULA_DIALOG_NAME, data)),
    setCollapsedGroups: (ids) => dispatch(TwoDDatabaseActions.setCollapsedGroups(ids)),
    openSidePanel: (type) => dispatch(TwoDDatabaseActions.openSidePanel({ type, withSideBlock: true })),
    setAssemblyForm: assembly => dispatch(TwoDDatabaseActions.setAssemblyPanel(assembly)),
    setItemForm: item => dispatch(TwoDDatabaseActions.setItemPanel(item)),
    closePanel: () => dispatch(PersistedStorageActions.toggleEntitiesPanel()),
    setAssignPiaLoad: () => dispatch(TwoDActions.setAssignPiaLoad()),
  };
};

export const connectRedux = connect(
  mapStateToProps,
  mapDispatchToProps,
);
