import React, { useMemo } from 'react';
import { FormTextInputField } from 'common/components/form-fields';

interface Props {
  value: string;
  onChange: (updates: Record<string, string>) => void;
}

export const PropertyNameInput: React.FC<Props> = React.memo(({ value, onChange }) => {
  const handleChange = React.useCallback((newValue: string) => {
    onChange({ name: newValue.replace(/[\[\].,]+/g, '') });
  }, [onChange]);
  const input = useMemo(() => {
    return {
      value,
      onChange: handleChange,
    };
  }, [handleChange, value]);
  return (
    <FormTextInputField
      label={'Property name'}
      input={input} />
  );
});
