import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import { ConstantFunctions } from '@kreo/kreo-ui-components/utils';
import React, { useCallback } from 'react';
import { ModalRootWrapper } from 'common/UIKit/dialogs/modal-root-wrapper';
import { reloadWrapper } from 'common/utils/reload-wrapper';
import { Styled } from './styled';


export const NoInternetDialog: React.FC = () => {
  const onClick = useCallback(() => reloadWrapper('no internet'), []);
  return (
    <ModalRootWrapper>
      <Styled.Blanket
        onMouseMove={ConstantFunctions.stopEvent}
        onMouseDown={ConstantFunctions.stopEvent}
        onClick={ConstantFunctions.stopEvent}
      />
      <Styled.Panel>
        <Icons.Error/>
        Oops, you seem to be offline. Please go online to view this page
        <RectangleButton
          width={105}
          height={30}
          text={'Refresh'}
          onClick={onClick}
          textColor={'white'}
        />
      </Styled.Panel>
    </ModalRootWrapper>
  );
};

