import { State } from 'common/interfaces/state';
import { store } from '../../../store';


function getUngroupedActivitiesBimIdsFromState(state: State, workPackageId: number): number[] {
  return state.activityGrouping.workPackages[workPackageId].ungroupedIds
    .map(groupId => {
      const id = state.activityGrouping.ungroupedActivityGroupIdToId[groupId];
      return state.activityGrouping.ungroupedActivities[id].engineIds;
    })
    .reduce((a, b) => a.concat(b), []);
}

function getUngroupedActivitiesBimIds(workPackageId: number): number[] {
  return getUngroupedActivitiesBimIdsFromState(store.getState(), workPackageId);
}


function getWorkPackageBimIdsFromState(state: State, workPackageId: number): number[] {
  const groupsIds = state.activityGrouping.workPackages[workPackageId].activityGroupIds
    .map(x => getActivityGroupBimIdsFromState(state, x))
    .reduce((a, b) => a.concat(b), []);
  const ungroupedActivityIds = getUngroupedActivitiesBimIdsFromState(state, workPackageId);

  return groupsIds.concat(ungroupedActivityIds);
}

function getWorkPackageBimIds(workPackageId: number): number[] {
  return getWorkPackageBimIdsFromState(store.getState(), workPackageId);
}


function getWorkPackagesBimIdsFromState(state: State, workPackageIds: number[] | null): number[] | null {
  if (!workPackageIds) {
    return null;
  }

  return workPackageIds.map(x => getWorkPackageBimIdsFromState(state, x))
    .reduce((a, b) => a.concat(b), []);
}

function getActivityGroupBimIdsFromState(state: State, activityGroupId: number): number[] {
  if (state.activityGrouping.activityGroups[activityGroupId]) {
    return state.activityGrouping.activityGroups[activityGroupId].activityIds
      .map(id => state.activityGrouping.activities[id].engineIds)
      .reduce((a, b) => a.concat(b), []);
  } else {
    const activityId = state.activityGrouping.ungroupedActivityGroupIdToId[activityGroupId];
    return state.activityGrouping.ungroupedActivities[activityId].engineIds;
  }
}

function getActivityGroupBimIds(activityGroupId: number): number[] {
  return getActivityGroupBimIdsFromState(store.getState(), activityGroupId);
}

function getActivityGroupsBimIds(activityGroupIds: number[]): number[] {
  const state = store.getState();
  return activityGroupIds
    .map(x => getActivityGroupBimIdsFromState(state, x))
    .reduce((a, b) => a.concat(b), []);
}


function getAvaliableWorkPackegesActivityGroupsBimIdsFromState(
  state: State,
  filteredWorkPackageIds: number[] | null,
  filteredAvailableActivityGroupId: number[] | null,
): number[] | null {
  const {
    ungroupedActivityGroupIdToId,
    ungroupedActivities,
    activityGroups,
  } = state.activityGrouping;

  if (!filteredWorkPackageIds) {
    let result = [];
    for (const groupId of filteredAvailableActivityGroupId) {
      result = result.concat(getActivityGroupBimIdsFromState(state, groupId));
    }

    return result;
  } else {
    const availableWorkPackageMap = {};
    for (const workPackageId of filteredWorkPackageIds) {
      availableWorkPackageMap[workPackageId] = true;
    }

    let result = [];
    for (const groupId of filteredAvailableActivityGroupId) {
      const group = activityGroups[groupId] || ungroupedActivities[ungroupedActivityGroupIdToId[groupId]];
      if (availableWorkPackageMap[group.workPackageId]) {
        result = result.concat(getActivityGroupBimIdsFromState(state, groupId));
      }
    }

    return result;
  }
}

function getFilteredWorkPackagesAndActivityGroupsBimIds(
  filteredWorkPackageIds: number[] | null,
  filteredAvailableActivityGroupId: number[] | null,
): number[] | null {
  if (!filteredWorkPackageIds && !filteredAvailableActivityGroupId) {
    return null;
  }

  const state: State = store.getState();
  if (!filteredAvailableActivityGroupId) {
    return getWorkPackagesBimIdsFromState(state, filteredWorkPackageIds);
  }

  return getAvaliableWorkPackegesActivityGroupsBimIdsFromState(
    state, filteredWorkPackageIds, filteredAvailableActivityGroupId);
}

export const BimElementIdsHelper = {
  getUngroupedActivitiesBimIds,
  getWorkPackageBimIds,
  getActivityGroupBimIds,
  getActivityGroupsBimIds,
  getFilteredWorkPackagesAndActivityGroupsBimIds,
};
