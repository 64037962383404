import { IconButton, Text, Icons } from '@kreo/kreo-ui-components';
import { Colors } from '@kreo/kreo-ui-components/constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ConfirmationButtonsPanel } from 'common/components/button-sets';
import { DialogContainer } from 'common/components/dialog-container';
import { RenderIf } from 'common/components/render-if';
import { TextArea } from 'common/components/text-area';
import { DrawingsStickerUserInfo } from '../../drawings-sticker-user-info';
import { DialogPosition } from '../../hooks';
import { ContainerShakeName, ContentContainer } from '../content-container';
import { useEdit } from '../hooks';
import { Styled } from './styled';

interface Props {
  text: string;
  createdAt: string | Date;
  creator: string;
  canEdit: boolean;
  changeText: (text: string) => void;
  remove: () => void;
  position: DialogPosition;
  onClose: () => void;
}

const ContentComponent: React.FC<Props> = ({
  text,
  createdAt,
  creator,
  canEdit,
  position,
  onClose,
  changeText,
  remove,
}) => {
  const { onEdit, isValueValid, value, cancelEdit } = useEdit(text);

  const [isEdit, setEdit] = useState<boolean>(false);
  const [ shake, setShake ] = useState<boolean>(false);
  const textRef = useRef<HTMLInputElement>();

  const onStartEdit = useCallback(() => canEdit && setEdit(true), [canEdit]);
  const onStopEdit = useCallback(() => {
    cancelEdit();
    setEdit(false);
    setShake(false);
  }, [cancelEdit]);
  const saveText = useCallback(() => {
    if (isValueValid && value !== text) {
      changeText(value);
      setEdit(false);
    }
  }, [value, text]);

  const onCloseCallback = useCallback(() => {
    if (text !== value) {
      setShake(true);
    } else {
      onClose();
    }
  }, [value, text]);

  const onAnimationEnd = useCallback((e: React.AnimationEvent) => {
    if (e.animationName === ContainerShakeName) {
      setShake(false);
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      textRef.current.focus();
      textRef.current.selectionStart = textRef.current.value.length;
    }
  }, [textRef?.current, isEdit]);

  return (
    <DialogContainer
      x={position.x}
      y={position.y}
      bottom={position.pinBottom}
      right={position.pinRight}
      onClose={onCloseCallback}
      blockEvents={isEdit}
    >
      <ContentContainer
        shake={shake}
        onAnimationEnd={onAnimationEnd}
      >
        <Styled.Header>
          <DrawingsStickerUserInfo
            createdAt={createdAt}
            creatorId={creator}
          />
          <RenderIf condition={canEdit}>
            <Styled.Buttons>
              <IconButton
                Icon={Icons.Edit2D}
                onClick={onStartEdit}
              />
              <IconButton
                Icon={Icons.Delete}
                defaultColor={Colors.GENERAL_COLORS.red}
                hoverColor={Colors.GENERAL_COLORS.red}
                onClick={remove}
              />
            </Styled.Buttons>
          </RenderIf>
        </Styled.Header>
        <Styled.Container onDoubleClick={onStartEdit}>
          {isEdit ? (
            <>
              <TextArea
                value={value}
                onFinish={saveText}
                onChange={onEdit}
                autofocus={true}
                inputRef={textRef}
              />
              <ConfirmationButtonsPanel
                applyText="Confirm"
                disabled={!isValueValid || value === text}
                apply={saveText}
                cancel={onStopEdit}
              />
            </>
          ) : (
            <Text>{value}</Text>
          )}
        </Styled.Container>
      </ContentContainer>
    </DialogContainer>
  );
};

export const Content = React.memo(ContentComponent);
