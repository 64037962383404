import classNames from 'classnames';
import React from 'react';

import './toggleable-button.scss';

import { Fab } from 'common/UIKit';


interface Props {
  className?: string;
  controlName: string;
  active: boolean;
  activeClassName?: string;
  size?: number;
  hasShadow?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}


export class ToggleableButton extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    size: 40,
    activeClassName: 'toggleable-button--active',
  };

  public render(): React.ReactNode {
    const { active, className, size, controlName, activeClassName, hasShadow } = this.props;
    const toggleFabClassName = classNames(
      'toggleable-button',
      {
        [activeClassName]: active,
      },
      className,
    );
    return (
      <Fab
        className={toggleFabClassName}
        controlName={controlName}
        onClick={this.props.onClick}
        size={size}
        hasShadow={hasShadow}
      >
        {this.props.children}
      </Fab>
    );
  }
}
