import styled from 'styled-components';

const Container = styled.div`
  padding: 10px;
  button {
    border-radius: 5px;
    background-color: ${p => p.theme.color.turquoise};
  }
`;

export const Styled = {
  Container,
};
