import { UpdateCellData } from 'common/components/excel-table/interfaces';
import { ExcelFormulaHelper } from 'common/components/excel-table/utils/excel-formula-helper';
import { RowDataStore } from '../cell-data-controllers/report-cell-data-store';
import { getConfigId } from '../excel-table-cell-formatter/common';
import { getCellValue } from './utils';

export const setEmptyCell = (
  updatedCellsMap: Record<string, UpdateCellData>,
  rowData: RowDataStore,
  columnId: string,
): void  => {
  const rowId = rowData.id.toString();
  const cellId = ExcelFormulaHelper.getCellLink(null, columnId, rowId);

  updatedCellsMap[cellId] = {
    rowId,
    columnId,
    value: '',
    prevValue: getCellValue(rowData, columnId),
  };

  const configColumnId = getConfigId(columnId);
  if (configColumnId in rowData) {
    const configCellId = ExcelFormulaHelper.getCellLink(null, configColumnId, rowId);
    updatedCellsMap[configCellId] = {
      rowId,
      columnId: configColumnId,
      value: null,
      prevValue: getCellValue(rowData, configColumnId),
    };
  }
};
